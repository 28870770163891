/* tslint:disable */
/* eslint-disable */
/**
 * ClassCloud API
 * For better development
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: example@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdditionalPatronRequestCreate
 */
export interface AdditionalPatronRequestCreate {
    /**
     * 
     * @type {string}
     * @memberof AdditionalPatronRequestCreate
     */
    patronJoinCode: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalPatronRequestCreate
     */
    studentId: string;
}
/**
 * 
 * @export
 * @interface AiChatMessage
 */
export interface AiChatMessage {
    /**
     * 
     * @type {string}
     * @memberof AiChatMessage
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof AiChatMessage
     */
    fromAi: boolean;
}
/**
 * 連絡
 * @export
 * @interface AnnouncementCreate
 */
export interface AnnouncementCreate {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnouncementCreate
     */
    classroomIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreate
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreate
     */
    postTimestamp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementCreate
     */
    isForSchool: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementCreate
     */
    isScheduled: boolean;
}
/**
 * 
 * @export
 * @interface AnnouncementFileRead
 */
export interface AnnouncementFileRead {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementFileRead
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementFileRead
     */
    orderIndex: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementFileRead
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementFileRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof AnnouncementFileRead
     */
    fileType: FileTypeEnum;
}
/**
 * 連絡
 * @export
 * @interface AnnouncementRead
 */
export interface AnnouncementRead {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementRead
     */
    id: string;
    /**
     * 
     * @type {SchoolRead}
     * @memberof AnnouncementRead
     */
    school: SchoolRead;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof AnnouncementRead
     */
    classrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {UserRead}
     * @memberof AnnouncementRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementRead
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementRead
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementRead
     */
    postTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementRead
     */
    lastEditedAt: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementRead
     */
    isForSchool: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementRead
     */
    isScheduled: boolean;
    /**
     * 
     * @type {Array<AnnouncementFileRead>}
     * @memberof AnnouncementRead
     */
    files: Array<AnnouncementFileRead>;
}
/**
 * 連絡
 * @export
 * @interface AnnouncementSimpleRead
 */
export interface AnnouncementSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementSimpleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementSimpleRead
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementSimpleRead
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementSimpleRead
     */
    postTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementSimpleRead
     */
    lastEditedAt: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementSimpleRead
     */
    isForSchool: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementSimpleRead
     */
    isScheduled: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementSimpleRead
     */
    lastViewDatetime: string | null;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof AnnouncementSimpleRead
     */
    classrooms: Array<HomeClassroomSimpleRead>;
}
/**
 * 
 * @export
 * @interface ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders
 */
export interface ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders {
    /**
     * 
     * @type {string}
     * @memberof ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders
     */
    orderIndex: number;
}
/**
 * 
 * @export
 * @interface ApiV1BoardSetsBoardSetIdCategorizeStreamNotes
 */
export interface ApiV1BoardSetsBoardSetIdCategorizeStreamNotes {
    /**
     * 
     * @type {string}
     * @memberof ApiV1BoardSetsBoardSetIdCategorizeStreamNotes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BoardSetsBoardSetIdCategorizeStreamNotes
     */
    text: string;
}
/**
 * 
 * @export
 * @interface ApiV1BoardSetsBoardSetIdUniqueNotesNotes
 */
export interface ApiV1BoardSetsBoardSetIdUniqueNotesNotes {
    /**
     * 
     * @type {string}
     * @memberof ApiV1BoardSetsBoardSetIdUniqueNotesNotes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BoardSetsBoardSetIdUniqueNotesNotes
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BoardSetsBoardSetIdUniqueNotesNotes
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BoardSetsBoardSetIdUniqueNotesNotes
     */
    authorId: string;
}
/**
 * 
 * @export
 * @interface ApiV1CoursesCourseIdWordTrendWordList
 */
export interface ApiV1CoursesCourseIdWordTrendWordList {
    /**
     * 
     * @type {string}
     * @memberof ApiV1CoursesCourseIdWordTrendWordList
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1CoursesCourseIdWordTrendWordList
     */
    value: number;
}
/**
 * 
 * @export
 * @interface ApiV1CustomAiFeedbacksStreamExamples
 */
export interface ApiV1CustomAiFeedbacksStreamExamples {
    /**
     * 
     * @type {string}
     * @memberof ApiV1CustomAiFeedbacksStreamExamples
     */
    student: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1CustomAiFeedbacksStreamExamples
     */
    teacher: string;
}
/**
 * 
 * @export
 * @interface ApiV1ImproveTextStreamTextToChangePosition
 */
export interface ApiV1ImproveTextStreamTextToChangePosition {
    /**
     * 
     * @type {number}
     * @memberof ApiV1ImproveTextStreamTextToChangePosition
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof ApiV1ImproveTextStreamTextToChangePosition
     */
    end: number;
}
/**
 * 
 * @export
 * @interface AppVersion
 */
export interface AppVersion {
    /**
     * 
     * @type {string}
     * @memberof AppVersion
     */
    platform: AppVersionPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof AppVersion
     */
    version: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AppVersionPlatformEnum {
    IOS = 'ios',
    ANDROID = 'android'
}

/**
 * 
 * @export
 * @interface BoardSet
 */
export interface BoardSet {
    /**
     * 
     * @type {string}
     * @memberof BoardSet
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BoardSet
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BoardSet
     */
    schemaVersion: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof BoardSet
     */
    boardList: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof BoardSet
     */
    lastEditedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof BoardSet
     */
    studentCanEditBoard: boolean;
}
/**
 * 
 * @export
 * @interface CanvasCollabGroupWithUsersRead
 */
export interface CanvasCollabGroupWithUsersRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasCollabGroupWithUsersRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasCollabGroupWithUsersRead
     */
    canvasId: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasCollabGroupWithUsersRead
     */
    name: string;
    /**
     * 
     * @type {Array<UserRead>}
     * @memberof CanvasCollabGroupWithUsersRead
     */
    users: Array<UserRead>;
}
/**
 * 
 * @export
 * @interface CanvasCreate
 */
export interface CanvasCreate {
    /**
     * 
     * @type {string}
     * @memberof CanvasCreate
     */
    name: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof CanvasCreate
     */
    elementListJson: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CanvasCreate
     */
    elementSchemaVersion: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasCreate
     */
    originalSizeWidth: number;
    /**
     * 
     * @type {number}
     * @memberof CanvasCreate
     */
    originalSizeHeight: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanvasCreate
     */
    textList: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof CanvasCreate
     */
    thumbnail: any;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanvasCreate
     */
    inputNames: Array<string>;
}
/**
 * 
 * @export
 * @interface CanvasInputAnswerRead
 */
export interface CanvasInputAnswerRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasInputAnswerRead
     */
    inputId: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputAnswerRead
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputAnswerRead
     */
    answerId: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasInputAnswerRead
     */
    orderIndex: number;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputAnswerRead
     */
    inputName: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputAnswerRead
     */
    feedback: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanvasInputAnswerRead
     */
    selectedChoices: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputAnswerRead
     */
    inputType: string;
    /**
     * 
     * @type {boolean}
     * @memberof CanvasInputAnswerRead
     */
    isMultiSelect: boolean;
}
/**
 * 
 * @export
 * @interface CanvasInputFileRead
 */
export interface CanvasInputFileRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasInputFileRead
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputFileRead
     */
    inputId: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputFileRead
     */
    inputName: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputFileRead
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputFileRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof CanvasInputFileRead
     */
    fileType: FileTypeEnum;
}
/**
 * 
 * @export
 * @interface CanvasInputRead
 */
export interface CanvasInputRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasInputRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasInputRead
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasInputRead
     */
    orderIndex: number;
}
/**
 * 
 * @export
 * @interface CanvasLayerListUpdate
 */
export interface CanvasLayerListUpdate {
    /**
     * 
     * @type {any}
     * @memberof CanvasLayerListUpdate
     */
    layerList: any;
}
/**
 * 
 * @export
 * @interface CanvasMaterialRead
 */
export interface CanvasMaterialRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasMaterialRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasMaterialRead
     */
    fileUrl: string;
    /**
     * 
     * @type {CanvasMaterialTypeEnum}
     * @memberof CanvasMaterialRead
     */
    type: CanvasMaterialTypeEnum;
    /**
     * 
     * @type {CanvasMaterialShareTypeEnum}
     * @memberof CanvasMaterialRead
     */
    shareType: CanvasMaterialShareTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanvasMaterialRead
     */
    thumbnailUrl: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CanvasMaterialShareTypeEnum {
    USER_SELF = 'user_self',
    COMMON = 'common'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CanvasMaterialTypeEnum {
    IMAGE = 'image',
    VIDEO = 'video',
    BACKGROUND_IMAGE = 'background_image'
}

/**
 * 
 * @export
 * @interface CanvasPostFormRead
 */
export interface CanvasPostFormRead {
    /**
     * 
     * @type {CanvasWithUsingCourse}
     * @memberof CanvasPostFormRead
     */
    canvas: CanvasWithUsingCourse;
    /**
     * 
     * @type {Array<CanvasPostFormReadNonFileInputList>}
     * @memberof CanvasPostFormRead
     */
    nonFileInputList: Array<CanvasPostFormReadNonFileInputList>;
    /**
     * 
     * @type {Array<CanvasPostFormReadInputList>}
     * @memberof CanvasPostFormRead
     */
    inputList: Array<CanvasPostFormReadInputList>;
}
/**
 * 
 * @export
 * @interface CanvasPostFormReadInputList
 */
export interface CanvasPostFormReadInputList {
    /**
     * 
     * @type {string}
     * @memberof CanvasPostFormReadInputList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasPostFormReadInputList
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanvasPostFormReadInputList
     */
    choicesForSelect: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CanvasPostFormReadInputList
     */
    inputType: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasPostFormReadInputList
     */
    orderIndex: number;
}
/**
 * 
 * @export
 * @interface CanvasPostFormReadNonFileInputList
 */
export interface CanvasPostFormReadNonFileInputList {
    /**
     * 
     * @type {string}
     * @memberof CanvasPostFormReadNonFileInputList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasPostFormReadNonFileInputList
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanvasPostFormReadNonFileInputList
     */
    choicesForSelect: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CanvasPostFormReadNonFileInputList
     */
    inputType: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasPostFormReadNonFileInputList
     */
    orderIndex: number;
    /**
     * 
     * @type {boolean}
     * @memberof CanvasPostFormReadNonFileInputList
     */
    isMultiSelect: boolean;
}
/**
 * 
 * @export
 * @interface CanvasRead
 */
export interface CanvasRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasRead
     */
    elementSchemaVersion: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasRead
     */
    originalSizeWidth: number;
    /**
     * 
     * @type {number}
     * @memberof CanvasRead
     */
    originalSizeHeight: number;
    /**
     * 
     * @type {CanvasShareTypeEnum}
     * @memberof CanvasRead
     */
    shareType: CanvasShareTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanvasRead
     */
    originalCanvasId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanvasRead
     */
    editingCourseId: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CanvasShareTypeEnum {
    USER_SELF = 'user_self',
    SCHOOL = 'school',
    PUBLIC = 'public',
    TEMPLATE = 'template'
}

/**
 * 
 * @export
 * @interface CanvasTemplateRead
 */
export interface CanvasTemplateRead {
    /**
     * 
     * @type {CanvasWithUsingCourse}
     * @memberof CanvasTemplateRead
     */
    canvas: CanvasWithUsingCourse;
    /**
     * 
     * @type {boolean}
     * @memberof CanvasTemplateRead
     */
    allowsCollabEdit: boolean;
}
/**
 * 
 * @export
 * @interface CanvasUpdate
 */
export interface CanvasUpdate {
    /**
     * 
     * @type {string}
     * @memberof CanvasUpdate
     */
    name?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof CanvasUpdate
     */
    elementListJson?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CanvasUpdate
     */
    elementSchemaVersion?: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasUpdate
     */
    originalSizeWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof CanvasUpdate
     */
    originalSizeHeight?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CanvasUpdate
     */
    textList?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof CanvasUpdate
     */
    thumbnail?: any;
    /**
     * 
     * @type {CanvasShareTypeEnum}
     * @memberof CanvasUpdate
     */
    shareType?: CanvasShareTypeEnum;
}
/**
 * 
 * @export
 * @interface CanvasWithAuthorRead
 */
export interface CanvasWithAuthorRead {
    /**
     * 
     * @type {string}
     * @memberof CanvasWithAuthorRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithAuthorRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithAuthorRead
     */
    elementSchemaVersion: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasWithAuthorRead
     */
    originalSizeWidth: number;
    /**
     * 
     * @type {number}
     * @memberof CanvasWithAuthorRead
     */
    originalSizeHeight: number;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithAuthorRead
     */
    lastEditedAt: string;
    /**
     * 
     * @type {CanvasShareTypeEnum}
     * @memberof CanvasWithAuthorRead
     */
    shareType: CanvasShareTypeEnum;
    /**
     * 
     * @type {UserRead}
     * @memberof CanvasWithAuthorRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithAuthorRead
     */
    originalCanvasId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithAuthorRead
     */
    editingCourseId: string | null;
}
/**
 * 
 * @export
 * @interface CanvasWithUsingCourse
 */
export interface CanvasWithUsingCourse {
    /**
     * 
     * @type {string}
     * @memberof CanvasWithUsingCourse
     */
    id: string;
    /**
     * 
     * @type {UserRead}
     * @memberof CanvasWithUsingCourse
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithUsingCourse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithUsingCourse
     */
    elementSchemaVersion: string;
    /**
     * 
     * @type {number}
     * @memberof CanvasWithUsingCourse
     */
    originalSizeWidth: number;
    /**
     * 
     * @type {number}
     * @memberof CanvasWithUsingCourse
     */
    originalSizeHeight: number;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithUsingCourse
     */
    lastEditedAt: string;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof CanvasWithUsingCourse
     */
    courseUsingAsPostForm: CourseSimpleRead | null;
    /**
     * 
     * @type {CanvasShareTypeEnum}
     * @memberof CanvasWithUsingCourse
     */
    shareType: CanvasShareTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithUsingCourse
     */
    originalCanvasId: string | null;
    /**
     * 
     * @type {string}
     * @memberof CanvasWithUsingCourse
     */
    editingCourseId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CanvasWithUsingCourse
     */
    allowsCollabEdit: boolean;
}
/**
 * クラスルーム作成用のモデル
 * @export
 * @interface ClassroomCreate
 */
export interface ClassroomCreate {
    /**
     * 
     * @type {Array<number>}
     * @memberof ClassroomCreate
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomCreate
     */
    kumi: string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomCreate
     */
    nendo: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomCreate
     */
    mainTeacherIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomCreate
     */
    subTeacherIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomCreate
     */
    studentActionAllowedStartTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassroomCreate
     */
    studentActionAllowedEndTime: string | null;
}
/**
 * 
 * @export
 * @interface ClassroomJoinCode
 */
export interface ClassroomJoinCode {
    /**
     * 
     * @type {string}
     * @memberof ClassroomJoinCode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomJoinCode
     */
    joinCode: string;
}
/**
 * 主に旧クラスに関する児童の情報
 * @export
 * @interface ClassroomStudentRead
 */
export interface ClassroomStudentRead {
    /**
     * 
     * @type {UserRead}
     * @memberof ClassroomStudentRead
     */
    user: UserRead;
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof ClassroomStudentRead
     */
    classroom: HomeClassroomSimpleRead;
    /**
     * 
     * @type {number}
     * @memberof ClassroomStudentRead
     */
    numInClass: number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomStudentRead
     */
    studentId: string;
}
/**
 * クラスルーム更新用のモデル
 * @export
 * @interface ClassroomUpdate
 */
export interface ClassroomUpdate {
    /**
     * 
     * @type {Array<number>}
     * @memberof ClassroomUpdate
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    kumi: string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomUpdate
     */
    nendo: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomUpdate
     */
    mainTeacherIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomUpdate
     */
    subTeacherIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    studentActionAllowedStartTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    studentActionAllowedEndTime: string | null;
}
/**
 * 
 * @export
 * @interface ClassroomsCourseJoinRequestCreate
 */
export interface ClassroomsCourseJoinRequestCreate {
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestCreate
     */
    message: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomsCourseJoinRequestCreate
     */
    classroomIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomsCourseJoinRequestCreate
     */
    teacherIds: Array<string>;
}
/**
 * クラスの授業への参加リクエスト
 * @export
 * @interface ClassroomsCourseJoinRequestListRead
 */
export interface ClassroomsCourseJoinRequestListRead {
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    id: string;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    message: string;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    classrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    teachers: Array<TeacherUserRead>;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    requestTeacher: TeacherUserRead;
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    acceptedAt: string | null;
    /**
     * 
     * @type {SchoolRead}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    requestTeacherSchool: SchoolRead;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    isRequestedToMe: boolean;
    /**
     * 
     * @type {Array<SchoolRead>}
     * @memberof ClassroomsCourseJoinRequestListRead
     */
    courseSchools: Array<SchoolRead>;
}
/**
 * クラスの授業への参加リクエスト
 * @export
 * @interface ClassroomsCourseJoinRequestRead
 */
export interface ClassroomsCourseJoinRequestRead {
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    id: string;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    message: string;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    classrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    teachers: Array<TeacherUserRead>;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    requestTeacher: TeacherUserRead;
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    acceptedAt: string | null;
    /**
     * 
     * @type {SchoolRead}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    requestTeacherSchool: SchoolRead;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    isRequestedToMe: boolean;
    /**
     * 
     * @type {Array<SchoolAndClassrooms>}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    courseSchoolsClassrooms: Array<SchoolAndClassrooms>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestRead
     */
    courseOpenSettingId: string;
}
/**
 * クラスの授業への参加リクエスト
 * @export
 * @interface ClassroomsCourseJoinRequestSimpleRead
 */
export interface ClassroomsCourseJoinRequestSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestSimpleRead
     */
    id: string;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof ClassroomsCourseJoinRequestSimpleRead
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof ClassroomsCourseJoinRequestSimpleRead
     */
    message: string;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof ClassroomsCourseJoinRequestSimpleRead
     */
    requestTeacher: TeacherUserRead;
    /**
     * 
     * @type {SchoolRead}
     * @memberof ClassroomsCourseJoinRequestSimpleRead
     */
    requestTeacherSchool: SchoolRead;
}
/**
 * 
 * @export
 * @interface CollabBoardMaterialRead
 */
export interface CollabBoardMaterialRead {
    /**
     * 
     * @type {string}
     * @memberof CollabBoardMaterialRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CollabBoardMaterialRead
     */
    fileUrl: string;
    /**
     * 
     * @type {CanvasMaterialTypeEnum}
     * @memberof CollabBoardMaterialRead
     */
    type: CanvasMaterialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CollabBoardMaterialRead
     */
    thumbnailUrl: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CollabBoardMaterialTypeEnum {
    IMAGE = 'image',
    VIDEO = 'video'
}

/**
 * 
 * @export
 * @interface CommentCreate
 */
export interface CommentCreate {
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    text?: string;
    /**
     * どのコメントに対してのコメントか
     * @type {string}
     * @memberof CommentCreate
     */
    toComment?: string;
    /**
     * 
     * @type {any}
     * @memberof CommentCreate
     */
    file?: any;
}
/**
 * コメントの編集履歴(差分ではない)
 * @export
 * @interface CommentEditHistoryRead
 */
export interface CommentEditHistoryRead {
    /**
     * 
     * @type {string}
     * @memberof CommentEditHistoryRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommentEditHistoryRead
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentEditHistoryRead
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentEditHistoryRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof CommentEditHistoryRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentEditHistoryRead
     */
    editedAt: string;
}
/**
 * 
 * @export
 * @interface CommentListPaginated
 */
export interface CommentListPaginated {
    /**
     * 
     * @type {number}
     * @memberof CommentListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof CommentListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof CommentListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<CommentSimpleRead>}
     * @memberof CommentListPaginated
     */
    results?: Array<CommentSimpleRead>;
}
/**
 * 
 * @export
 * @interface CommentListPaginatedAllOf
 */
export interface CommentListPaginatedAllOf {
    /**
     * 
     * @type {Array<CommentSimpleRead>}
     * @memberof CommentListPaginatedAllOf
     */
    results?: Array<CommentSimpleRead>;
}
/**
 * 
 * @export
 * @interface CommentRead
 */
export interface CommentRead {
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    postId: string;
    /**
     * 
     * @type {UserRead}
     * @memberof CommentRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    createdAt: string;
    /**
     * 
     * @type {Array<ReactionListItemRead>}
     * @memberof CommentRead
     */
    reactions: Array<ReactionListItemRead>;
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof CommentRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {Array<CommentRead>}
     * @memberof CommentRead
     */
    threadComments: Array<CommentRead> | null;
    /**
     * 
     * @type {string}
     * @memberof CommentRead
     */
    threadRootCommentId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommentRead
     */
    isDeleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentRead
     */
    isFirstCommentInCommentsInSpecificDay: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentRead
     */
    shouldHideAuthorInfoDueToContinuity: boolean;
    /**
     * 
     * @type {CommentSpaceTypeEnum}
     * @memberof CommentRead
     */
    commentSpaceType: CommentSpaceTypeEnum;
}
/**
 * 
 * @export
 * @interface CommentSimpleRead
 */
export interface CommentSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof CommentSimpleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommentSimpleRead
     */
    postId: string;
    /**
     * 
     * @type {UserRead}
     * @memberof CommentSimpleRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof CommentSimpleRead
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentSimpleRead
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentSimpleRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof CommentSimpleRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentSimpleRead
     */
    createdAt: string;
    /**
     * 
     * @type {CommentSpaceTypeEnum}
     * @memberof CommentSimpleRead
     */
    commentSpaceType: CommentSpaceTypeEnum;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CommentSpaceTypeEnum {
    COURSE_PUBLIC = 'course_public',
    PATRON_PRIVATE = 'patron_private'
}

/**
 * 
 * @export
 * @interface CommentStats
 */
export interface CommentStats {
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    passiveCount: number;
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    averageTextLength: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    activePassiveCommentRatio: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    uniqueMemberCountCommentTo: number;
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    uniqueMemberCountPassiveCommentFrom: number;
    /**
     * 
     * @type {number}
     * @memberof CommentStats
     */
    studentNumInClass: number;
}
/**
 * Comment更新用のモデル
 * @export
 * @interface CommentUpdate
 */
export interface CommentUpdate {
    /**
     * 
     * @type {string}
     * @memberof CommentUpdate
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentUpdate
     */
    toComment?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CommentUpdate
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface CongratsObject
 */
export interface CongratsObject {
    /**
     * 
     * @type {string}
     * @memberof CongratsObject
     */
    congratsText: string;
    /**
     * 
     * @type {string}
     * @memberof CongratsObject
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CongratsObject
     */
    name: string;
}
/**
 * 授業内通知
 * @export
 * @interface CourseCommonNotification
 */
export interface CourseCommonNotification {
    /**
     * 
     * @type {string}
     * @memberof CourseCommonNotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCommonNotification
     */
    type: CourseCommonNotificationTypeEnum;
    /**
     * 
     * @type {CourseCommonNotificationContent}
     * @memberof CourseCommonNotification
     */
    content: CourseCommonNotificationContent;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof CourseCommonNotification
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof CourseCommonNotification
     */
    timestamp: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCommonNotification
     */
    isRead: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CourseCommonNotificationTypeEnum {
    POST_FIXATION_TO_COURSE = 'post_fixation_to_course',
    COURSE_NEW_POSTS = 'course_new_posts',
    COURSE_NEW_COMMENTS = 'course_new_comments'
}

/**
 * @type CourseCommonNotificationContent
 * @export
 */
export type CourseCommonNotificationContent = NotificationOfCourseNewComments | NotificationOfCourseNewPosts | NotificationOfPostFixationToCourse;

/**
 * 
 * @export
 * @interface CourseCommonNotificationListPaginated
 */
export interface CourseCommonNotificationListPaginated {
    /**
     * 
     * @type {number}
     * @memberof CourseCommonNotificationListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof CourseCommonNotificationListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof CourseCommonNotificationListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<CourseCommonNotification>}
     * @memberof CourseCommonNotificationListPaginated
     */
    results?: Array<CourseCommonNotification>;
}
/**
 * 
 * @export
 * @interface CourseCommonNotificationListPaginatedAllOf
 */
export interface CourseCommonNotificationListPaginatedAllOf {
    /**
     * 
     * @type {Array<CourseCommonNotification>}
     * @memberof CourseCommonNotificationListPaginatedAllOf
     */
    results?: Array<CourseCommonNotification>;
}
/**
 * 
 * @export
 * @interface CourseCreate
 */
export interface CourseCreate {
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseCreate
     */
    classroomIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseCreate
     */
    teacherIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseCreate
     */
    courseTagIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    term: string;
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    subject: string;
    /**
     * 
     * @type {Array<FileTypeEnum>}
     * @memberof CourseCreate
     */
    allowedFileTypes?: Array<FileTypeEnum> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    maxPostCountPerStudent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    postSettingCopyOriginCourseId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreate
     */
    studentsPostsAreCoursePublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreate
     */
    studentCanUseFukaboriBot: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreate
     */
    studentCanUseRealtimeEditingPostsView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreate
     */
    isVisibleToPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreate
     */
    studentCanComment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreate
     */
    studentCanReaction: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    tangenId: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseCreate
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    navigatorBotId: string | null;
}
/**
 * 
 * @export
 * @interface CourseOpenSettingCreate
 */
export interface CourseOpenSettingCreate {
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingCreate
     */
    beforeJoinMessage: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingCreate
     */
    afterJoinMessage: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOpenSettingCreate
     */
    isActive?: boolean | null;
}
/**
 * 授業の合同授業掲示
 * @export
 * @interface CourseOpenSettingListRead
 */
export interface CourseOpenSettingListRead {
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingListRead
     */
    id: string;
    /**
     * 
     * @type {CourseRead}
     * @memberof CourseOpenSettingListRead
     */
    course: CourseRead;
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingListRead
     */
    beforeJoinMessage: string | null;
    /**
     * 
     * @type {Array<SchoolAndClassrooms>}
     * @memberof CourseOpenSettingListRead
     */
    schools: Array<SchoolAndClassrooms>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOpenSettingListRead
     */
    alreadyRequested: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOpenSettingListRead
     */
    hasJoined: boolean;
}
/**
 * 授業の合同授業掲示
 * @export
 * @interface CourseOpenSettingRead
 */
export interface CourseOpenSettingRead {
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingRead
     */
    id: string;
    /**
     * 
     * @type {CourseRead}
     * @memberof CourseOpenSettingRead
     */
    course: CourseRead;
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingRead
     */
    beforeJoinMessage: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingRead
     */
    afterJoinMessage: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOpenSettingRead
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOpenSettingRead
     */
    userCanEdit: boolean;
    /**
     * 
     * @type {Array<SchoolAndClassrooms>}
     * @memberof CourseOpenSettingRead
     */
    schools: Array<SchoolAndClassrooms>;
    /**
     * 
     * @type {CourseOpenSettingReadOwnRequest}
     * @memberof CourseOpenSettingRead
     */
    ownRequest: CourseOpenSettingReadOwnRequest | null;
}
/**
 * 
 * @export
 * @interface CourseOpenSettingReadOwnRequest
 */
export interface CourseOpenSettingReadOwnRequest {
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingReadOwnRequest
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CourseOpenSettingUpdate
 */
export interface CourseOpenSettingUpdate {
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingUpdate
     */
    beforeJoinMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CourseOpenSettingUpdate
     */
    afterJoinMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOpenSettingUpdate
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface CourseOwnEditingCanvas
 */
export interface CourseOwnEditingCanvas {
    /**
     * 
     * @type {CanvasRead}
     * @memberof CourseOwnEditingCanvas
     */
    canvas: CanvasRead;
    /**
     * 
     * @type {string}
     * @memberof CourseOwnEditingCanvas
     */
    groupName: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseOwnEditingCanvas
     */
    hasPosted: boolean;
}
/**
 * 
 * @export
 * @interface CoursePostAndCommentCount
 */
export interface CoursePostAndCommentCount {
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof CoursePostAndCommentCount
     */
    classroom: HomeClassroomSimpleRead;
    /**
     * 
     * @type {Array<CoursePostAndCommentCountStudents>}
     * @memberof CoursePostAndCommentCount
     */
    students: Array<CoursePostAndCommentCountStudents>;
}
/**
 * 
 * @export
 * @interface CoursePostAndCommentCountStudents
 */
export interface CoursePostAndCommentCountStudents {
    /**
     * 
     * @type {number}
     * @memberof CoursePostAndCommentCountStudents
     */
    postCount: number;
    /**
     * 
     * @type {number}
     * @memberof CoursePostAndCommentCountStudents
     */
    commentCount: number;
    /**
     * 
     * @type {ClassroomStudentRead}
     * @memberof CoursePostAndCommentCountStudents
     */
    student: ClassroomStudentRead;
}
/**
 * 
 * @export
 * @interface CoursePostFixation
 */
export interface CoursePostFixation {
    /**
     * 
     * @type {string}
     * @memberof CoursePostFixation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePostFixation
     */
    postId: string;
    /**
     * 
     * @type {UserRead}
     * @memberof CoursePostFixation
     */
    fixatedBy: UserRead;
}
/**
 * 
 * @export
 * @interface CourseRead
 */
export interface CourseRead {
    /**
     * 
     * @type {string}
     * @memberof CourseRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseRead
     */
    nameKana: string;
    /**
     * 
     * @type {Array<HomeOrCustomClassroomSimpleRead>}
     * @memberof CourseRead
     */
    classrooms: Array<HomeOrCustomClassroomSimpleRead>;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof CourseRead
     */
    teachers: Array<TeacherUserRead>;
    /**
     * 
     * @type {Array<CourseTagRead>}
     * @memberof CourseRead
     */
    courseTags: Array<CourseTagRead>;
    /**
     * 
     * @type {number}
     * @memberof CourseRead
     */
    nendo: number;
    /**
     * 
     * @type {string}
     * @memberof CourseRead
     */
    term: string;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof CourseRead
     */
    createdByTeacher: TeacherUserRead;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    userCanEdit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseRead
     */
    openSettingId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    openSettingIsActive: boolean;
    /**
     * 
     * @type {Subject}
     * @memberof CourseRead
     */
    subjectObject: Subject;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    studentsPostsAreCoursePublic: boolean;
    /**
     * 
     * @type {Array<FileTypeEnum>}
     * @memberof CourseRead
     */
    allowedFileTypes: Array<FileTypeEnum> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseRead
     */
    maxPostCountPerStudent: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    studentCanUseFukaboriBot: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    studentCanUseRealtimeEditingPostsView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    isVisibleToPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    studentCanComment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseRead
     */
    studentCanReaction: boolean;
    /**
     * 
     * @type {TangenRead}
     * @memberof CourseRead
     */
    tangen: TangenRead | null;
    /**
     * 
     * @type {NavigatorBotRead}
     * @memberof CourseRead
     */
    navigatorBot: NavigatorBotRead | null;
}
/**
 * 
 * @export
 * @interface CourseSimpleRead
 */
export interface CourseSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof CourseSimpleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSimpleRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSimpleRead
     */
    nameKana: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSimpleRead
     */
    nendo: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSimpleRead
     */
    term: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSimpleRead
     */
    isActive: boolean;
    /**
     * 
     * @type {Array<FileTypeEnum>}
     * @memberof CourseSimpleRead
     */
    allowedFileTypes: Array<FileTypeEnum> | null;
    /**
     * 
     * @type {number}
     * @memberof CourseSimpleRead
     */
    maxPostCountPerStudent: number | null;
    /**
     * 
     * @type {Subject}
     * @memberof CourseSimpleRead
     */
    subjectObject: Subject;
    /**
     * 
     * @type {string}
     * @memberof CourseSimpleRead
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSimpleRead
     */
    studentsPostsAreCoursePublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSimpleRead
     */
    studentCanUseFukaboriBot: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSimpleRead
     */
    studentCanUseRealtimeEditingPostsView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSimpleRead
     */
    studentCanComment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseSimpleRead
     */
    studentCanReaction: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseSimpleRead
     */
    tangenId: string | null;
}
/**
 * 
 * @export
 * @interface CourseSimpleWithTeachersListPaginated
 */
export interface CourseSimpleWithTeachersListPaginated {
    /**
     * 
     * @type {number}
     * @memberof CourseSimpleWithTeachersListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof CourseSimpleWithTeachersListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof CourseSimpleWithTeachersListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<CourseSimpleRead & object>}
     * @memberof CourseSimpleWithTeachersListPaginated
     */
    results?: Array<CourseSimpleRead & object>;
}
/**
 * 
 * @export
 * @interface CourseSimpleWithTeachersListPaginatedAllOf
 */
export interface CourseSimpleWithTeachersListPaginatedAllOf {
    /**
     * 
     * @type {Array<CourseSimpleRead & object>}
     * @memberof CourseSimpleWithTeachersListPaginatedAllOf
     */
    results?: Array<CourseSimpleRead & object>;
}
/**
 * 
 * @export
 * @interface CourseTagCreate
 */
export interface CourseTagCreate {
    /**
     * 
     * @type {string}
     * @memberof CourseTagCreate
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CourseTagRead
 */
export interface CourseTagRead {
    /**
     * 
     * @type {string}
     * @memberof CourseTagRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseTagRead
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CourseUpdate
 */
export interface CourseUpdate {
    /**
     * 
     * @type {string}
     * @memberof CourseUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdate
     */
    nameKana: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseUpdate
     */
    classroomIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseUpdate
     */
    teacherIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseUpdate
     */
    courseTagIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdate
     */
    term: string;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdate
     */
    subject: string;
    /**
     * 
     * @type {Array<FileTypeEnum>}
     * @memberof CourseUpdate
     */
    allowedFileTypes: Array<FileTypeEnum> | null;
    /**
     * 
     * @type {any}
     * @memberof CourseUpdate
     */
    maxPostCountPerStudent: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdate
     */
    studentsPostsAreCoursePublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdate
     */
    studentCanUseFukaboriBot: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdate
     */
    studentCanUseRealtimeEditingPostsView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdate
     */
    isVisibleToPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdate
     */
    studentCanComment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdate
     */
    studentCanReaction: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdate
     */
    tangenId: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseUpdate
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdate
     */
    navigatorBotId: string | null;
}
/**
 * 
 * @export
 * @interface CourseWithClassroomsAndTangen
 */
export interface CourseWithClassroomsAndTangen {
    /**
     * 
     * @type {string}
     * @memberof CourseWithClassroomsAndTangen
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CourseWithClassroomsAndTangen
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseWithClassroomsAndTangen
     */
    nameKana: string;
    /**
     * 
     * @type {Subject}
     * @memberof CourseWithClassroomsAndTangen
     */
    subjectObject: Subject;
    /**
     * 
     * @type {number}
     * @memberof CourseWithClassroomsAndTangen
     */
    nendo: number;
    /**
     * 
     * @type {string}
     * @memberof CourseWithClassroomsAndTangen
     */
    term: string;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof CourseWithClassroomsAndTangen
     */
    classrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {string}
     * @memberof CourseWithClassroomsAndTangen
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof CourseWithClassroomsAndTangen
     */
    postCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseWithClassroomsAndTangen
     */
    commentCount?: number;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof CourseWithClassroomsAndTangen
     */
    teachers?: Array<TeacherUserRead>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseWithClassroomsAndTangen
     */
    isActive: boolean;
}
/**
 * 
 * @export
 * @interface CourseWithClassroomsRead
 */
export interface CourseWithClassroomsRead {
    /**
     * 
     * @type {string}
     * @memberof CourseWithClassroomsRead
     */
    id?: string;
}
/**
 * 不快報告作成用
 * @export
 * @interface DiscomfortReportCreate
 */
export interface DiscomfortReportCreate {
    /**
     * 
     * @type {string}
     * @memberof DiscomfortReportCreate
     */
    reasonText: string;
}
/**
 * 
 * @export
 * @interface DiscomfortReportListPaginated
 */
export interface DiscomfortReportListPaginated {
    /**
     * 
     * @type {number}
     * @memberof DiscomfortReportListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof DiscomfortReportListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof DiscomfortReportListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<DiscomfortReportRead>}
     * @memberof DiscomfortReportListPaginated
     */
    results?: Array<DiscomfortReportRead>;
}
/**
 * 
 * @export
 * @interface DiscomfortReportListPaginatedAllOf
 */
export interface DiscomfortReportListPaginatedAllOf {
    /**
     * 
     * @type {Array<DiscomfortReportRead>}
     * @memberof DiscomfortReportListPaginatedAllOf
     */
    results?: Array<DiscomfortReportRead>;
}
/**
 * 
 * @export
 * @interface DiscomfortReportRead
 */
export interface DiscomfortReportRead {
    /**
     * 
     * @type {string}
     * @memberof DiscomfortReportRead
     */
    id: string;
    /**
     * 
     * @type {UserRead}
     * @memberof DiscomfortReportRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof DiscomfortReportRead
     */
    reasonText: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiscomfortReportRead
     */
    reportedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DiscomfortReportRead
     */
    targetModelName: DiscomfortReportReadTargetModelNameEnum;
    /**
     * 
     * @type {PostSimpleRead | CommentSimpleRead}
     * @memberof DiscomfortReportRead
     */
    originalReportedObject: PostSimpleRead | CommentSimpleRead;
}

/**
    * @export
    * @enum {string}
    */
export enum DiscomfortReportReadTargetModelNameEnum {
    POST = 'Post',
    COMMENT = 'Comment'
}

/**
 * 
 * @export
 * @interface Edge
 */
export interface Edge {
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof Edge
     */
    width: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FileTypeEnum {
    VIDEO = 'video',
    IMAGE = 'image',
    NONE = 'none',
    MISC = 'misc'
}

/**
 * 
 * @export
 * @interface GradeRead
 */
export interface GradeRead {
    /**
     * MoodのUUID
     * @type {string}
     * @memberof GradeRead
     */
    id: string;
    /**
     * 
     * @type {UserRead}
     * @memberof GradeRead
     */
    evaluatorTeacher: UserRead;
    /**
     * 
     * @type {Perspective}
     * @memberof GradeRead
     */
    perspective: Perspective;
    /**
     * 
     * @type {number}
     * @memberof GradeRead
     */
    score: number;
}
/**
 * 
 * @export
 * @interface GroupCreateOrUpdate
 */
export interface GroupCreateOrUpdate {
    /**
     * 
     * @type {string}
     * @memberof GroupCreateOrUpdate
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GroupCreateOrUpdate
     */
    nendo: number;
    /**
     * 
     * @type {boolean}
     * @memberof GroupCreateOrUpdate
     */
    requestUserShouldJoin?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupCreateOrUpdate
     */
    grades: Array<number>;
}
/**
 * 
 * @export
 * @interface GroupRead
 */
export interface GroupRead {
    /**
     * 
     * @type {string}
     * @memberof GroupRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupRead
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GroupRead
     */
    nendo: number;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof GroupRead
     */
    teachers: Array<TeacherUserRead>;
    /**
     * 
     * @type {Array<StudentUserRead>}
     * @memberof GroupRead
     */
    students: Array<StudentUserRead>;
    /**
     * 
     * @type {string}
     * @memberof GroupRead
     */
    joinCode: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupRead
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GroupRead
     */
    gradeStr: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupRead
     */
    isSchoolTeachersAutoJoinGroup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupRead
     */
    isOrgSchoolsTeachersAutoJoinGroup: boolean;
}
/**
 * 
 * @export
 * @interface GroupSimpleRead
 */
export interface GroupSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleRead
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof GroupSimpleRead
     */
    nendo: number;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleRead
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupSimpleRead
     */
    isHomeClass: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupSimpleRead
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleRead
     */
    gradeStr: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupSimpleRead
     */
    isSchoolTeachersAutoJoinGroup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupSimpleRead
     */
    isOrgSchoolsTeachersAutoJoinGroup: boolean;
}
/**
 * 
 * @export
 * @interface GroupSimpleWithUserAffiliation
 */
export interface GroupSimpleWithUserAffiliation {
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleWithUserAffiliation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleWithUserAffiliation
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GroupSimpleWithUserAffiliation
     */
    nendo: number;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleWithUserAffiliation
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupSimpleWithUserAffiliation
     */
    requestTeacherUserIsBelongingTo: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupSimpleWithUserAffiliation
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GroupSimpleWithUserAffiliation
     */
    gradeStr: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupSimpleWithUserAffiliation
     */
    isSchoolTeachersAutoJoinGroup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupSimpleWithUserAffiliation
     */
    isOrgSchoolsTeachersAutoJoinGroup: boolean;
}
/**
 * 
 * @export
 * @interface GuestTeacherMeta
 */
export interface GuestTeacherMeta {
    /**
     * 
     * @type {string}
     * @memberof GuestTeacherMeta
     */
    guestTeacherMetaId: string;
    /**
     * 
     * @type {string}
     * @memberof GuestTeacherMeta
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof GuestTeacherMeta
     */
    issuedAt: string;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof GuestTeacherMeta
     */
    issuedByTeacher: TeacherUserRead;
    /**
     * 
     * @type {string}
     * @memberof GuestTeacherMeta
     */
    idForExternalSchool: string;
    /**
     * 
     * @type {boolean}
     * @memberof GuestTeacherMeta
     */
    isRegistrationCompleted: boolean;
}
/**
 * 
 * @export
 * @interface HomeClassroomRead
 */
export interface HomeClassroomRead {
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof HomeClassroomRead
     */
    grade: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof HomeClassroomRead
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    kumi: string;
    /**
     * 
     * @type {number}
     * @memberof HomeClassroomRead
     */
    nendo: number;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof HomeClassroomRead
     */
    mainTeachers: Array<TeacherUserRead>;
    /**
     * 
     * @type {Array<TeacherUserRead>}
     * @memberof HomeClassroomRead
     */
    subTeachers: Array<TeacherUserRead>;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    idForExternalSchool: string;
    /**
     * 
     * @type {boolean}
     * @memberof HomeClassroomRead
     */
    userCanEdit?: boolean;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof HomeClassroomRead
     */
    createdByTeacher: TeacherUserRead;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    studentActionAllowedStartTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomRead
     */
    studentActionAllowedEndTime: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HomeClassroomRead
     */
    isHomeClass: boolean;
}
/**
 * labelは、自分の学校なら、6年1組とか 他学校ならAA学校 6年1組とか
 * @export
 * @interface HomeClassroomSimpleRead
 */
export interface HomeClassroomSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomSimpleRead
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof HomeClassroomSimpleRead
     */
    grade: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof HomeClassroomSimpleRead
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomSimpleRead
     */
    kumi: string;
    /**
     * 
     * @type {number}
     * @memberof HomeClassroomSimpleRead
     */
    nendo: number;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomSimpleRead
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof HomeClassroomSimpleRead
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof HomeClassroomSimpleRead
     */
    isHomeClass: boolean;
}
/**
 * ホームクラスとカスタムクラスどちらも統一的に扱う
 * @export
 * @interface HomeOrCustomClassroomSimpleRead
 */
export interface HomeOrCustomClassroomSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    grade: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    grades: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    isHomeClass: boolean;
    /**
     * 
     * @type {string}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    kumi: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeOrCustomClassroomSimpleRead
     */
    schoolId: string;
}
/**
 * 
 * @export
 * @interface IdTokenObject
 */
export interface IdTokenObject {
    /**
     * 
     * @type {string}
     * @memberof IdTokenObject
     */
    idToken: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof InlineObject
     */
    classroom?: HomeClassroomSimpleRead;
    /**
     * 
     * @type {Array<ClassroomStudentRead>}
     * @memberof InlineObject
     */
    students?: Array<ClassroomStudentRead>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    postTemplateId: string;
}
/**
 * 
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    templateText: string;
}
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    postTemplateId?: string;
}
/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject13
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject14
 */
export interface InlineObject14 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    patronJoinCode: string;
}
/**
 * 
 * @export
 * @interface InlineObject15
 */
export interface InlineObject15 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject15
     */
    lastViewDatetime: string;
}
/**
 * 
 * @export
 * @interface InlineObject16
 */
export interface InlineObject16 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject16
     */
    orderIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject16
     */
    file?: string;
}
/**
 * 
 * @export
 * @interface InlineObject17
 */
export interface InlineObject17 {
    /**
     * 
     * @type {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>}
     * @memberof InlineObject17
     */
    newFileOrders: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>;
}
/**
 * 
 * @export
 * @interface InlineObject18
 */
export interface InlineObject18 {
    /**
     * 
     * @type {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>}
     * @memberof InlineObject18
     */
    newFileOrders: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>;
}
/**
 * 
 * @export
 * @interface InlineObject19
 */
export interface InlineObject19 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    authorId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    noteId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    boardId: string;
}
/**
 * 
 * @export
 * @interface InlineObject20
 */
export interface InlineObject20 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject21
 */
export interface InlineObject21 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    groupName: string;
}
/**
 * 
 * @export
 * @interface InlineObject22
 */
export interface InlineObject22 {
    /**
     * 
     * @type {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>}
     * @memberof InlineObject22
     */
    newFileOrders: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>;
}
/**
 * 
 * @export
 * @interface InlineObject23
 */
export interface InlineObject23 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    base64UserId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    token: string;
}
/**
 * 
 * @export
 * @interface InlineObject24
 */
export interface InlineObject24 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject24
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InlineObject25
 */
export interface InlineObject25 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject25
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject25
     */
    slackUrl: string;
}
/**
 * 
 * @export
 * @interface InlineObject26
 */
export interface InlineObject26 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject26
     */
    joinCode: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject26
     */
    numInClass: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject26
     */
    forNendoUpdate: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject27
 */
export interface InlineObject27 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject27
     */
    switchToClassroomId: string;
}
/**
 * 
 * @export
 * @interface InlineObject28
 */
export interface InlineObject28 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject28
     */
    joinCode: string;
}
/**
 * 
 * @export
 * @interface InlineObject29
 */
export interface InlineObject29 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject29
     */
    idToken: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    patronCode: string;
}
/**
 * 
 * @export
 * @interface InlineObject30
 */
export interface InlineObject30 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject30
     */
    message: string;
}
/**
 * 
 * @export
 * @interface InlineObject31
 */
export interface InlineObject31 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject31
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject31
     */
    instruction?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject31
     */
    inappropriateDirectionExamples?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject32
 */
export interface InlineObject32 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject32
     */
    assumedInput: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject32
     */
    directionText: string;
    /**
     * 
     * @type {Array<ApiV1CustomAiFeedbacksStreamExamples>}
     * @memberof InlineObject32
     */
    examples: Array<ApiV1CustomAiFeedbacksStreamExamples>;
}
/**
 * 
 * @export
 * @interface InlineObject33
 */
export interface InlineObject33 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject33
     */
    subjectValues: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject33
     */
    term: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject33
     */
    useDummyText: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject34
 */
export interface InlineObject34 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject34
     */
    term: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject34
     */
    useDummyText: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject35
 */
export interface InlineObject35 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject35
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject35
     */
    additionalStudentInfoByTeacher: string;
}
/**
 * 
 * @export
 * @interface InlineObject36
 */
export interface InlineObject36 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject36
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject36
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject36
     */
    noteByTeacher?: string;
}
/**
 * 
 * @export
 * @interface InlineObject37
 */
export interface InlineObject37 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject37
     */
    directionText?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject37
     */
    priority?: InlineObject37PriorityEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject37PriorityEnum {
    SPEED = 'speed',
    ACCURACY = 'accuracy'
}

/**
 * 
 * @export
 * @interface InlineObject38
 */
export interface InlineObject38 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject38
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InlineObject39
 */
export interface InlineObject39 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject39
     */
    text: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    patronCode: string;
}
/**
 * 
 * @export
 * @interface InlineObject40
 */
export interface InlineObject40 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject40
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject40
     */
    grade: number | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject40
     */
    subjectValue: string;
}
/**
 * 
 * @export
 * @interface InlineObject41
 */
export interface InlineObject41 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject41
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject41
     */
    grade?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject41
     */
    subjectValue?: string;
}
/**
 * 
 * @export
 * @interface InlineObject42
 */
export interface InlineObject42 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject42
     */
    joinCode: string;
}
/**
 * 
 * @export
 * @interface InlineObject43
 */
export interface InlineObject43 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    allText: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    textToChange: string;
    /**
     * 
     * @type {ApiV1ImproveTextStreamTextToChangePosition}
     * @memberof InlineObject43
     */
    textToChangePosition: ApiV1ImproveTextStreamTextToChangePosition;
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    instructionType: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    customInstruction?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    targetReader: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    textToGenerate: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject43
     */
    context: string;
}
/**
 * 
 * @export
 * @interface InlineObject44
 */
export interface InlineObject44 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject44
     */
    targetReader: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject44
     */
    textToGenerate: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject44
     */
    characterCount?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject44
     */
    context: string;
}
/**
 * 
 * @export
 * @interface InlineObject45
 */
export interface InlineObject45 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject45
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface InlineObject46
 */
export interface InlineObject46 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject46
     */
    userIdToAdd: string;
}
/**
 * 
 * @export
 * @interface InlineObject47
 */
export interface InlineObject47 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject47
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface InlineObject48
 */
export interface InlineObject48 {
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject48
     */
    boardList: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject49
 */
export interface InlineObject49 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject49
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject49
     */
    studentCanEditBoard?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    uId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    token: string;
}
/**
 * 
 * @export
 * @interface InlineObject50
 */
export interface InlineObject50 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject50
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject50
     */
    schemaVersion: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject50
     */
    initialBoardList: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof InlineObject50
     */
    boardWidth: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject50
     */
    boardHeight: number;
}
/**
 * 
 * @export
 * @interface InlineObject51
 */
export interface InlineObject51 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject51
     */
    boardWidth: number | null;
    /**
     * 
     * @type {number}
     * @memberof InlineObject51
     */
    boardHeight: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject51
     */
    studentCanEditBoard: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject51
     */
    studentCanRemoveOthersObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject51
     */
    studentCanAddOrRemoveOrRenameBoard: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject51
     */
    authorNamesAreHidden: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject51
     */
    othersNotesAreHidden: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject52
 */
export interface InlineObject52 {
    /**
     * 
     * @type {Array<ApiV1BoardSetsBoardSetIdUniqueNotesNotes>}
     * @memberof InlineObject52
     */
    notes: Array<ApiV1BoardSetsBoardSetIdUniqueNotesNotes>;
}
/**
 * 
 * @export
 * @interface InlineObject53
 */
export interface InlineObject53 {
    /**
     * 
     * @type {Array<ApiV1BoardSetsBoardSetIdCategorizeStreamNotes>}
     * @memberof InlineObject53
     */
    notes: Array<ApiV1BoardSetsBoardSetIdCategorizeStreamNotes>;
}
/**
 * 
 * @export
 * @interface InlineObject54
 */
export interface InlineObject54 {
    /**
     * 
     * @type {Array<ApiV1BoardSetsBoardSetIdCategorizeStreamNotes>}
     * @memberof InlineObject54
     */
    notes: Array<ApiV1BoardSetsBoardSetIdCategorizeStreamNotes>;
}
/**
 * 
 * @export
 * @interface InlineObject55
 */
export interface InlineObject55 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject55
     */
    name: string;
}
/**
 * 
 * @export
 * @interface InlineObject56
 */
export interface InlineObject56 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject56
     */
    moodChoiceId: string;
}
/**
 * 
 * @export
 * @interface InlineObject57
 */
export interface InlineObject57 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject57
     */
    moodChoiceId: string;
}
/**
 * 
 * @export
 * @interface InlineObject58
 */
export interface InlineObject58 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject58
     */
    passCode?: string;
}
/**
 * 
 * @export
 * @interface InlineObject59
 */
export interface InlineObject59 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject59
     */
    perspectiveId: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject59
     */
    score: number;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject6
     */
    numInClass: number;
}
/**
 * 
 * @export
 * @interface InlineObject60
 */
export interface InlineObject60 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject60
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject60
     */
    studentActivity: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject60
     */
    task: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject60
     */
    restriction: string | null;
    /**
     * 
     * @type {NavigatorBotTypeEnum}
     * @memberof InlineObject60
     */
    botType: NavigatorBotTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject60
     */
    description: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject61
 */
export interface InlineObject61 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject61
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject61
     */
    studentActivity?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject61
     */
    task?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject61
     */
    restriction?: string | null;
    /**
     * 
     * @type {NavigatorBotTypeEnum}
     * @memberof InlineObject61
     */
    botType?: NavigatorBotTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject61
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject62
 */
export interface InlineObject62 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject62
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject62
     */
    instruction: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject62
     */
    inappropriateDirectionExamples: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject7
     */
    correctClassroomId: string;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {Array<NodeForStudent>}
     * @memberof InlineObject8
     */
    nodes: Array<NodeForStudent>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof InlineObject8
     */
    edges: Array<Edge>;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    templateText: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    canvasId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    templateType: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    patronJoinCode: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {SchoolRead}
     * @memberof InlineResponse2001
     */
    school: SchoolRead;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    propertiesString: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {SchoolRead}
     * @memberof InlineResponse20011
     */
    school: SchoolRead;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof InlineResponse20011
     */
    teacher: TeacherUserRead;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {Array<PostEditHistoryRead>}
     * @memberof InlineResponse20012
     */
    histories: Array<PostEditHistoryRead>;
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof InlineResponse20012
     */
    currentPost: PostSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    postDeletedAt: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    postLastEditedAt: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    commentDeletedAt: string | null;
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof InlineResponse20013
     */
    currentComment: CommentSimpleRead;
    /**
     * 
     * @type {Array<CommentEditHistoryRead>}
     * @memberof InlineResponse20013
     */
    histories: Array<CommentEditHistoryRead>;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    commentLastEditedAt?: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof InlineResponse20014
     */
    classroom: HomeClassroomSimpleRead;
    /**
     * 
     * @type {Array<PatronRequestRead>}
     * @memberof InlineResponse20014
     */
    requests: Array<PatronRequestRead>;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {Array<AnnouncementRead>}
     * @memberof InlineResponse20015
     */
    results: Array<AnnouncementRead>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof InlineResponse20016
     */
    classroom: HomeClassroomSimpleRead;
    /**
     * 
     * @type {PatronRoleRead & object}
     * @memberof InlineResponse20016
     */
    patrons: PatronRoleRead & object;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20017
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    endDate: string;
    /**
     * 
     * @type {Array<ApiV1CoursesCourseIdWordTrendWordList>}
     * @memberof InlineResponse20018
     */
    wordList: Array<ApiV1CoursesCourseIdWordTrendWordList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    loginUserId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    expiredAt: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    previous?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string | null;
    /**
     * 
     * @type {Array<PostDetailRead>}
     * @memberof InlineResponse2002
     */
    results?: Array<PostDetailRead>;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20020
     */
    password: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20021
     */
    text: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20022
     */
    token: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20023
     */
    lastClassroomJoinDate?: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20024
     */
    properKanaText: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20025
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20026
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    uniqueness: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    authorId: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20028
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20028
     */
    noteId: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20029
     */
    affiliationName: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    appVersion: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    timestamp: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20030
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    unreadCount?: number;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof InlineResponse2004
     */
    course?: CourseSimpleRead;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof InlineResponse2005
     */
    classroom: HomeClassroomSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    schoolAuthProviderId: string | null;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {StudentRoleRead}
     * @memberof InlineResponse2006
     */
    student: StudentRoleRead;
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof InlineResponse2006
     */
    classroom: HomeClassroomSimpleRead;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    numInClass: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {UserAuthRead}
     * @memberof InlineResponse2007
     */
    userAuth: UserAuthRead;
    /**
     * 
     * @type {StudentUserRead}
     * @memberof InlineResponse2007
     */
    student: StudentUserRead;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {StudentUserRead}
     * @memberof InlineResponse2008
     */
    student: StudentUserRead;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009
     */
    postUnreadCount: number;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof InlineResponse2009
     */
    course: CourseSimpleRead;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse201
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2011
 */
export interface InlineResponse2011 {
    /**
     * 作成された投稿タグのID
     * @type {string}
     * @memberof InlineResponse2011
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2012
 */
export interface InlineResponse2012 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2012
     */
    id: string;
    /**
     * 
     * @type {Array<CongratsObject>}
     * @memberof InlineResponse2012
     */
    congratsList: Array<CongratsObject>;
}
/**
 * 
 * @export
 * @interface InlineResponse2013
 */
export interface InlineResponse2013 {
    /**
     * 
     * @type {UserAuthRead}
     * @memberof InlineResponse2013
     */
    userAuth: UserAuthRead;
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof InlineResponse2013
     */
    classroom: HomeClassroomSimpleRead;
}
/**
 * 
 * @export
 * @interface InlineResponse2014
 */
export interface InlineResponse2014 {
    /**
     * 
     * @type {UserAuthRead}
     * @memberof InlineResponse2014
     */
    userAuth: UserAuthRead;
    /**
     * 
     * @type {SchoolRead}
     * @memberof InlineResponse2014
     */
    school: SchoolRead;
}
/**
 * 
 * @export
 * @interface InlineResponse2015
 */
export interface InlineResponse2015 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2015
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2016
 */
export interface InlineResponse2016 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2016
     */
    id: string;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse400
     */
    password?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse400
     */
    uid?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse400
     */
    token?: Array<string>;
}
/**
 * 
 * @export
 * @interface JoinCodeCheck
 */
export interface JoinCodeCheck {
    /**
     * 
     * @type {string}
     * @memberof JoinCodeCheck
     */
    joinCode: string;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    email: string;
}
/**
 * 
 * @export
 * @interface Medal
 */
export interface Medal {
    /**
     * 
     * @type {string}
     * @memberof Medal
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Medal
     */
    name: string;
    /**
     * 
     * @type {MedalStep}
     * @memberof Medal
     */
    currentStep: MedalStep;
    /**
     * 
     * @type {Array<MedalStep>}
     * @memberof Medal
     */
    allSteps: Array<MedalStep> | null;
    /**
     * 
     * @type {number}
     * @memberof Medal
     */
    currentCount: number | null;
    /**
     * 
     * @type {string}
     * @memberof Medal
     */
    requiredCountForNext: string | null;
    /**
     * 
     * @type {number}
     * @memberof Medal
     */
    nextStepCount: number | null;
    /**
     * 
     * @type {string}
     * @memberof Medal
     */
    textToNextStep: string | null;
    /**
     * 
     * @type {number}
     * @memberof Medal
     */
    percentageToNext: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Medal
     */
    isRead: boolean;
    /**
     * 
     * @type {string}
     * @memberof Medal
     */
    textForNonMedal: string;
}
/**
 * 
 * @export
 * @interface MedalStep
 */
export interface MedalStep {
    /**
     * 
     * @type {number}
     * @memberof MedalStep
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof MedalStep
     */
    color: MedalStepColorEnum;
    /**
     * 
     * @type {string}
     * @memberof MedalStep
     */
    text: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MedalStepColorEnum {
    GOLD = 'gold',
    SILVER = 'silver',
    BRONZE = 'bronze'
}

/**
 * 
 * @export
 * @interface MoodChoice
 */
export interface MoodChoice {
    /**
     * MoodChoiceのUUID
     * @type {string}
     * @memberof MoodChoice
     */
    id: string;
    /**
     * 心の天気の名前
     * @type {string}
     * @memberof MoodChoice
     */
    label: MoodChoiceLabelEnum;
    /**
     * 心の天気の名前
     * @type {string}
     * @memberof MoodChoice
     */
    labelKana: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MoodChoiceLabelEnum {
    SUNNY = 'sunny',
    CLOUDY = 'cloudy',
    RAINY = 'rainy',
    STORMY = 'stormy'
}

/**
 * 
 * @export
 * @interface NavigatorBotRead
 */
export interface NavigatorBotRead {
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    studentActivity: string;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    task: string;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    restriction: string | null;
    /**
     * 
     * @type {NavigatorBotTypeEnum}
     * @memberof NavigatorBotRead
     */
    botType: NavigatorBotTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    botTypeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    description: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NavigatorBotRead
     */
    isSourcePublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigatorBotRead
     */
    lastUsedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum NavigatorBotTypeEnum {
    AD_HOC = 'ad_hoc',
    SCHOOL_TEMPLATE = 'school_template',
    PUBLIC_TEMPLATE = 'public_template',
    OFFICIAL = 'official'
}

/**
 * 
 * @export
 * @interface NewArrivalState
 */
export interface NewArrivalState {
    /**
     * 
     * @type {NewArrivalStatePosts}
     * @memberof NewArrivalState
     */
    posts: NewArrivalStatePosts;
    /**
     * 
     * @type {InlineResponse20017}
     * @memberof NewArrivalState
     */
    notifications: InlineResponse20017;
    /**
     * 
     * @type {NewArrivalStateSchool}
     * @memberof NewArrivalState
     */
    school: NewArrivalStateSchool;
    /**
     * 
     * @type {InlineResponse20017}
     * @memberof NewArrivalState
     */
    mypage: InlineResponse20017;
}
/**
 * 
 * @export
 * @interface NewArrivalStatePosts
 */
export interface NewArrivalStatePosts {
    /**
     * 
     * @type {boolean}
     * @memberof NewArrivalStatePosts
     */
    exists: boolean;
}
/**
 * 
 * @export
 * @interface NewArrivalStateSchool
 */
export interface NewArrivalStateSchool {
    /**
     * 
     * @type {number}
     * @memberof NewArrivalStateSchool
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof NewArrivalStateSchool
     */
    unreadAnnouncementsCounts: number;
    /**
     * 
     * @type {number}
     * @memberof NewArrivalStateSchool
     */
    pendingPatronRequestsCount: number;
}
/**
 * 
 * @export
 * @interface NoTangenCourse
 */
export interface NoTangenCourse {
    /**
     * 
     * @type {string}
     * @memberof NoTangenCourse
     */
    type: string;
    /**
     * 
     * @type {CourseWithClassroomsAndTangen}
     * @memberof NoTangenCourse
     */
    course: CourseWithClassroomsAndTangen;
}
/**
 * ネットワークグラフ描画のための児童のNode
 * @export
 * @interface NodeForStudent
 */
export interface NodeForStudent {
    /**
     * 
     * @type {string}
     * @memberof NodeForStudent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NodeForStudent
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof NodeForStudent
     */
    profileImage: string | null;
    /**
     * 
     * @type {number}
     * @memberof NodeForStudent
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof NodeForStudent
     */
    sex?: string;
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof NodeForStudent
     */
    classroom: HomeClassroomSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationCourseJoinRequestAccepted
 */
export interface NotificationCourseJoinRequestAccepted {
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof NotificationCourseJoinRequestAccepted
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {SchoolRead}
     * @memberof NotificationCourseJoinRequestAccepted
     */
    courseCreatedByTeacherSchool: SchoolRead;
    /**
     * 
     * @type {string}
     * @memberof NotificationCourseJoinRequestAccepted
     */
    afterJoinMessage: string;
}
/**
 * 
 * @export
 * @interface NotificationCourseJoinRequested
 */
export interface NotificationCourseJoinRequested {
    /**
     * 
     * @type {ClassroomsCourseJoinRequestSimpleRead}
     * @memberof NotificationCourseJoinRequested
     */
    courseJoinRequest: ClassroomsCourseJoinRequestSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationOfBadWordInComment
 */
export interface NotificationOfBadWordInComment {
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfBadWordInComment
     */
    comment: CommentSimpleRead;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationOfBadWordInComment
     */
    badWords: Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationOfBadWordInPost
 */
export interface NotificationOfBadWordInPost {
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof NotificationOfBadWordInPost
     */
    post: PostSimpleRead;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationOfBadWordInPost
     */
    badWords: Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationOfCommentToPost
 */
export interface NotificationOfCommentToPost {
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfCommentToPost
     */
    comment: CommentSimpleRead;
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof NotificationOfCommentToPost
     */
    post: PostSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationOfCommentToThread
 */
export interface NotificationOfCommentToThread {
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfCommentToThread
     */
    comment: CommentSimpleRead;
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfCommentToThread
     */
    ownCommentInSameThread: CommentSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationOfCourseNewComments
 */
export interface NotificationOfCourseNewComments {
    /**
     * 
     * @type {Array<CommentSimpleRead>}
     * @memberof NotificationOfCourseNewComments
     */
    comments: Array<CommentSimpleRead>;
}
/**
 * 
 * @export
 * @interface NotificationOfCourseNewPosts
 */
export interface NotificationOfCourseNewPosts {
    /**
     * 
     * @type {Array<PostSimpleRead>}
     * @memberof NotificationOfCourseNewPosts
     */
    posts: Array<PostSimpleRead>;
}
/**
 * 
 * @export
 * @interface NotificationOfDiscomfortReportToComment
 */
export interface NotificationOfDiscomfortReportToComment {
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfDiscomfortReportToComment
     */
    comment: CommentSimpleRead;
    /**
     * 
     * @type {UserRead}
     * @memberof NotificationOfDiscomfortReportToComment
     */
    reportedBy: UserRead;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfDiscomfortReportToComment
     */
    reasonText: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfDiscomfortReportToComment
     */
    reportedAt: string;
}
/**
 * 
 * @export
 * @interface NotificationOfDiscomfortReportToPost
 */
export interface NotificationOfDiscomfortReportToPost {
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof NotificationOfDiscomfortReportToPost
     */
    post: PostSimpleRead;
    /**
     * 
     * @type {UserRead}
     * @memberof NotificationOfDiscomfortReportToPost
     */
    reportedBy: UserRead;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfDiscomfortReportToPost
     */
    reasonText: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfDiscomfortReportToPost
     */
    reportedAt: string;
}
/**
 * 
 * @export
 * @interface NotificationOfMentionInComment
 */
export interface NotificationOfMentionInComment {
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfMentionInComment
     */
    comment: CommentSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationOfMentionInPost
 */
export interface NotificationOfMentionInPost {
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof NotificationOfMentionInPost
     */
    post: PostSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationOfPostFixationToCourse
 */
export interface NotificationOfPostFixationToCourse {
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof NotificationOfPostFixationToCourse
     */
    post: PostSimpleRead;
    /**
     * 
     * @type {UserRead}
     * @memberof NotificationOfPostFixationToCourse
     */
    fixatedBy: UserRead;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfPostFixationToCourse
     */
    fixatedAt: string;
}
/**
 * 
 * @export
 * @interface NotificationOfReactionsToComment
 */
export interface NotificationOfReactionsToComment {
    /**
     * 新しい順
     * @type {Array<ReactionRead>}
     * @memberof NotificationOfReactionsToComment
     */
    reactions: Array<ReactionRead>;
    /**
     * 
     * @type {Array<UserRead>}
     * @memberof NotificationOfReactionsToComment
     */
    reactionUsers: Array<UserRead>;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfReactionsToComment
     */
    lastReactionedAt: string;
    /**
     * 
     * @type {CommentSimpleRead}
     * @memberof NotificationOfReactionsToComment
     */
    comment: CommentSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationOfReactionsToPost
 */
export interface NotificationOfReactionsToPost {
    /**
     * 新しい順
     * @type {Array<ReactionRead>}
     * @memberof NotificationOfReactionsToPost
     */
    reactions: Array<ReactionRead>;
    /**
     * 
     * @type {Array<UserRead>}
     * @memberof NotificationOfReactionsToPost
     */
    reactionUsers: Array<UserRead>;
    /**
     * 
     * @type {string}
     * @memberof NotificationOfReactionsToPost
     */
    lastReactionedAt: string;
    /**
     * 
     * @type {PostSimpleRead}
     * @memberof NotificationOfReactionsToPost
     */
    post: PostSimpleRead;
}
/**
 * 
 * @export
 * @interface NotificationUnreadCountOfCourse
 */
export interface NotificationUnreadCountOfCourse {
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof NotificationUnreadCountOfCourse
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {number}
     * @memberof NotificationUnreadCountOfCourse
     */
    courseCommonUnreadCount: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationUnreadCountOfCourse
     */
    userSpecificUnreadCount: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationUnreadCountOfCourse
     */
    courseCommonLastViewDatetime: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationUnreadCountOfCourse
     */
    userSpecificLastViewDatetime: string | null;
}
/**
 * 
 * @export
 * @interface PaginationBase
 */
export interface PaginationBase {
    /**
     * 
     * @type {number}
     * @memberof PaginationBase
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof PaginationBase
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof PaginationBase
     */
    next: string | null;
}
/**
 * 
 * @export
 * @interface PatronCreate
 */
export interface PatronCreate {
    /**
     * 
     * @type {string}
     * @memberof PatronCreate
     */
    patronJoinCode: string;
    /**
     * 
     * @type {string}
     * @memberof PatronCreate
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof PatronCreate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronCreate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronCreate
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof PatronCreate
     */
    firstNameKana: string;
}
/**
 * 保護者リクエストのRead
 * @export
 * @interface PatronRequestRead
 */
export interface PatronRequestRead {
    /**
     * 
     * @type {string}
     * @memberof PatronRequestRead
     */
    id: string;
    /**
     * 
     * @type {StudentUserRead}
     * @memberof PatronRequestRead
     */
    student: StudentUserRead;
    /**
     * 
     * @type {PatronUserRead}
     * @memberof PatronRequestRead
     */
    patron: PatronUserRead;
    /**
     * 
     * @type {string}
     * @memberof PatronRequestRead
     */
    requestedAt: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRequestRead
     */
    approvedAt: string | null;
}
/**
 * 
 * @export
 * @interface PatronRoleDetailRead
 */
export interface PatronRoleDetailRead {
    /**
     * 
     * @type {string}
     * @memberof PatronRoleDetailRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRoleDetailRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRoleDetailRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {Array<StudentUserRead>}
     * @memberof PatronRoleDetailRead
     */
    students: Array<StudentUserRead>;
}
/**
 * 
 * @export
 * @interface PatronRolePrivateRead
 */
export interface PatronRolePrivateRead {
    /**
     * 
     * @type {string}
     * @memberof PatronRolePrivateRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRolePrivateRead
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRolePrivateRead
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRolePrivateRead
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRolePrivateRead
     */
    firstNameKana: string;
    /**
     * 
     * @type {Array<StudentUserRead>}
     * @memberof PatronRolePrivateRead
     */
    students: Array<StudentUserRead>;
    /**
     * 
     * @type {Array<StudentUserRead>}
     * @memberof PatronRolePrivateRead
     */
    patronRequestPendingStudents: Array<StudentUserRead>;
}
/**
 * 
 * @export
 * @interface PatronRoleRead
 */
export interface PatronRoleRead {
    /**
     * 
     * @type {string}
     * @memberof PatronRoleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRoleRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronRoleRead
     */
    fullNameKana: string;
}
/**
 * 
 * @export
 * @interface PatronRoleUpdate
 */
export interface PatronRoleUpdate {
    /**
     * 名前（漢字）
     * @type {string}
     * @memberof PatronRoleUpdate
     */
    firstName: string;
    /**
     * なまえ（かな）
     * @type {string}
     * @memberof PatronRoleUpdate
     */
    firstNameKana: string;
    /**
     * 苗字（漢字）
     * @type {string}
     * @memberof PatronRoleUpdate
     */
    lastName: string;
    /**
     * みょうじ（なまえ）
     * @type {string}
     * @memberof PatronRoleUpdate
     */
    lastNameKana: string;
    /**
     * プロフィール画像
     * @type {string}
     * @memberof PatronRoleUpdate
     */
    profileImage?: string;
}
/**
 * 
 * @export
 * @interface PatronUserRead
 */
export interface PatronUserRead {
    /**
     * 
     * @type {string}
     * @memberof PatronUserRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof PatronUserRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatronUserRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof PatronUserRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof PatronUserRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof PatronUserRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatronUserRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatronUserRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatronUserRead
     */
    isDemoUser: boolean;
    /**
     * 
     * @type {PatronRoleRead}
     * @memberof PatronUserRead
     */
    role: PatronRoleRead;
}
/**
 * 
 * @export
 * @interface PatronUserReadAllOf
 */
export interface PatronUserReadAllOf {
    /**
     * 
     * @type {PatronRoleRead}
     * @memberof PatronUserReadAllOf
     */
    role: PatronRoleRead;
}
/**
 * 
 * @export
 * @interface Perspective
 */
export interface Perspective {
    /**
     * MoodのUUID
     * @type {string}
     * @memberof Perspective
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Perspective
     */
    name: string;
}
/**
 * 
 * @export
 * @interface PollChoiceRead
 */
export interface PollChoiceRead {
    /**
     * 
     * @type {string}
     * @memberof PollChoiceRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PollChoiceRead
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof PollChoiceRead
     */
    votersCount: number | null;
    /**
     * 
     * @type {number}
     * @memberof PollChoiceRead
     */
    votersPercentage: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PollChoiceRead
     */
    isOwnVoteChoice: boolean;
    /**
     * 
     * @type {number}
     * @memberof PollChoiceRead
     */
    orderIndex: number;
}
/**
 * 
 * @export
 * @interface PollCreate
 */
export interface PollCreate {
    /**
     * 
     * @type {Array<InlineResponse20021>}
     * @memberof PollCreate
     */
    choiceDataList: Array<InlineResponse20021>;
}
/**
 * 
 * @export
 * @interface PollListPaginated
 */
export interface PollListPaginated {
    /**
     * 
     * @type {Array<PollSimpleRead>}
     * @memberof PollListPaginated
     */
    results?: Array<PollSimpleRead>;
}
/**
 * 
 * @export
 * @interface PollRead
 */
export interface PollRead {
    /**
     * 
     * @type {string}
     * @memberof PollRead
     */
    id: string;
    /**
     * 
     * @type {Array<PollChoiceRead>}
     * @memberof PollRead
     */
    choices: Array<PollChoiceRead>;
    /**
     * 
     * @type {UserRead}
     * @memberof PollRead
     */
    createdBy: UserRead;
    /**
     * 
     * @type {boolean}
     * @memberof PollRead
     */
    hasAlreadyVoted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PollRead
     */
    isClosed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PollRead
     */
    isActive: boolean;
}
/**
 * 
 * @export
 * @interface PollSimpleRead
 */
export interface PollSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof PollSimpleRead
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PollSimpleRead
     */
    choices: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PollSimpleRead
     */
    isClosed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PollSimpleRead
     */
    isActive: boolean;
}
/**
 * 
 * @export
 * @interface PostDetailCreate
 */
export interface PostDetailCreate {
    /**
     * 
     * @type {string}
     * @memberof PostDetailCreate
     */
    text: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostDetailCreate
     */
    postTagIds: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof PostDetailCreate
     */
    file: any;
}
/**
 * 
 * @export
 * @interface PostDetailListPaginated
 */
export interface PostDetailListPaginated {
    /**
     * 
     * @type {number}
     * @memberof PostDetailListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof PostDetailListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof PostDetailListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<PostDetailRead>}
     * @memberof PostDetailListPaginated
     */
    results: Array<PostDetailRead>;
}
/**
 * 
 * @export
 * @interface PostDetailListPaginatedAllOf
 */
export interface PostDetailListPaginatedAllOf {
    /**
     * 
     * @type {Array<PostDetailRead>}
     * @memberof PostDetailListPaginatedAllOf
     */
    results: Array<PostDetailRead>;
}
/**
 * 
 * @export
 * @interface PostDetailRead
 */
export interface PostDetailRead {
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    text: string | null;
    /**
     * 
     * @type {PostShareRangeEnum}
     * @memberof PostDetailRead
     */
    shareRange: PostShareRangeEnum;
    /**
     * 
     * @type {Array<CommentRead>}
     * @memberof PostDetailRead
     */
    coursePublicComments: Array<CommentRead>;
    /**
     * 
     * @type {Array<CommentRead>}
     * @memberof PostDetailRead
     */
    patronPrivateComments: Array<CommentRead>;
    /**
     * 
     * @type {UserRead}
     * @memberof PostDetailRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    courseId: string;
    /**
     * 
     * @type {Array<PostTagRead>}
     * @memberof PostDetailRead
     */
    postTags: Array<PostTagRead>;
    /**
     * 
     * @type {Array<ReactionListItemRead>}
     * @memberof PostDetailRead
     */
    reactions: Array<ReactionListItemRead>;
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    pollId: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostDetailRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostDetailRead
     */
    thumbnailUrl: string | null;
    /**
     * 前回この投稿詳細を閲覧した日時　初めて閲覧する場合にはnullが入る
     * @type {string}
     * @memberof PostDetailRead
     */
    lastViewDatetime: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostDetailRead
     */
    isEdited: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDetailRead
     */
    isFixatedToCourse: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDetailRead
     */
    isOwnStudentsPost: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostDetailRead
     */
    isMemberOfPatronPrivateComments: boolean;
    /**
     * 
     * @type {CanvasWithUsingCourse}
     * @memberof PostDetailRead
     */
    canvas: CanvasWithUsingCourse;
    /**
     * 
     * @type {CanvasWithUsingCourse}
     * @memberof PostDetailRead
     */
    formCanvas: CanvasWithUsingCourse;
    /**
     * 
     * @type {Array<CanvasInputAnswerRead>}
     * @memberof PostDetailRead
     */
    canvasInputValueList: Array<CanvasInputAnswerRead>;
    /**
     * 
     * @type {Array<CanvasInputFileRead>}
     * @memberof PostDetailRead
     */
    canvasInputFileList: Array<CanvasInputFileRead>;
    /**
     * 
     * @type {Array<PostFileRead>}
     * @memberof PostDetailRead
     */
    files: Array<PostFileRead>;
}
/**
 * PostDetail更新用のモデル
 * @export
 * @interface PostDetailUpdate
 */
export interface PostDetailUpdate {
    /**
     * 
     * @type {string}
     * @memberof PostDetailUpdate
     */
    text?: string | null;
    /**
     * 
     * @type {Array<PostTagRead>}
     * @memberof PostDetailUpdate
     */
    postTagIds?: Array<PostTagRead>;
    /**
     * 
     * @type {any}
     * @memberof PostDetailUpdate
     */
    file?: any;
}
/**
 * 投稿の編集履歴(差分ではない)
 * @export
 * @interface PostEditHistoryRead
 */
export interface PostEditHistoryRead {
    /**
     * 
     * @type {string}
     * @memberof PostEditHistoryRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostEditHistoryRead
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostEditHistoryRead
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostEditHistoryRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostEditHistoryRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {PostShareRangeEnum}
     * @memberof PostEditHistoryRead
     */
    shareRange: PostShareRangeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostEditHistoryRead
     */
    editedAt: string;
}
/**
 * 
 * @export
 * @interface PostFileRead
 */
export interface PostFileRead {
    /**
     * 
     * @type {string}
     * @memberof PostFileRead
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PostFileRead
     */
    orderIndex: number;
    /**
     * 
     * @type {string}
     * @memberof PostFileRead
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PostFileRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostFileRead
     */
    fileType: FileTypeEnum;
}
/**
 * 
 * @export
 * @interface PostForCollabBoardNote
 */
export interface PostForCollabBoardNote {
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    fileType: string;
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    thumbnailUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    authorId: string;
    /**
     * 
     * @type {string}
     * @memberof PostForCollabBoardNote
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface PostSettigFileRead
 */
export interface PostSettigFileRead {
    /**
     * 
     * @type {string}
     * @memberof PostSettigFileRead
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PostSettigFileRead
     */
    orderIndex: number;
    /**
     * 
     * @type {string}
     * @memberof PostSettigFileRead
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PostSettigFileRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostSettigFileRead
     */
    fileType: FileTypeEnum;
}
/**
 * 
 * @export
 * @interface PostSettingRead
 */
export interface PostSettingRead {
    /**
     * 
     * @type {string}
     * @memberof PostSettingRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostSettingRead
     */
    description: string | null;
    /**
     * 
     * @type {CanvasPostFormRead}
     * @memberof PostSettingRead
     */
    canvasPostForm: CanvasPostFormRead;
    /**
     * 
     * @type {CanvasTemplateRead}
     * @memberof PostSettingRead
     */
    canvasTemplate: CanvasTemplateRead;
    /**
     * 
     * @type {string}
     * @memberof PostSettingRead
     */
    templateText: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSettingRead
     */
    formType: PostSettingReadFormTypeEnum;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof PostSettingRead
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof PostSettingRead
     */
    classrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {boolean}
     * @memberof PostSettingRead
     */
    isCourseDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostSettingRead
     */
    isForOwnClasses: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostSettingRead
     */
    maxPostCountPerStudent: number | null;
    /**
     * 
     * @type {Array<FileTypeEnum>}
     * @memberof PostSettingRead
     */
    allowedFileTypes: Array<FileTypeEnum>;
    /**
     * 
     * @type {Array<PostSettigFileRead>}
     * @memberof PostSettingRead
     */
    files: Array<PostSettigFileRead>;
    /**
     * 
     * @type {string}
     * @memberof PostSettingRead
     */
    dueDatetime: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum PostSettingReadFormTypeEnum {
    TEXT = 'text',
    CANVAS = 'canvas',
    CANVAS_TEMPLATE = 'canvas_template'
}

/**
 * 
 * @export
 * @interface PostSettingUpdate
 */
export interface PostSettingUpdate {
    /**
     * 
     * @type {string}
     * @memberof PostSettingUpdate
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSettingUpdate
     */
    canvasId: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSettingUpdate
     */
    templateText: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSettingUpdate
     */
    formType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSettingUpdate
     */
    classroomIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PostSettingUpdate
     */
    isCourseDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostSettingUpdate
     */
    dueDatetime: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PostShareRangeEnum {
    MYOWN = 'myown',
    TEACHER = 'teacher',
    COURSE = 'course',
    PUBLIC = 'public'
}

/**
 * 
 * @export
 * @interface PostSimpleRead
 */
export interface PostSimpleRead {
    /**
     * 
     * @type {string}
     * @memberof PostSimpleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleRead
     */
    text: string | null;
    /**
     * 
     * @type {UserRead}
     * @memberof PostSimpleRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleRead
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleRead
     */
    fileUrl?: string;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostSimpleRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleRead
     */
    createdAt: string;
    /**
     * 
     * @type {CanvasRead}
     * @memberof PostSimpleRead
     */
    canvas: CanvasRead;
    /**
     * 
     * @type {CanvasRead}
     * @memberof PostSimpleRead
     */
    formCanvas: CanvasRead;
    /**
     * 
     * @type {Array<CanvasInputAnswerRead>}
     * @memberof PostSimpleRead
     */
    canvasInputValueList: Array<CanvasInputAnswerRead>;
}
/**
 * 
 * @export
 * @interface PostSimpleWithComments
 */
export interface PostSimpleWithComments {
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    text: string | null;
    /**
     * 
     * @type {PostShareRangeEnum}
     * @memberof PostSimpleWithComments
     */
    shareRange: PostShareRangeEnum;
    /**
     * 
     * @type {Array<CommentRead>}
     * @memberof PostSimpleWithComments
     */
    coursePublicComments: Array<CommentRead>;
    /**
     * 
     * @type {Array<CommentRead>}
     * @memberof PostSimpleWithComments
     */
    patronPrivateComments: Array<CommentRead>;
    /**
     * 
     * @type {UserRead}
     * @memberof PostSimpleWithComments
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    createdAt: string;
    /**
     * 
     * @type {CourseSimpleRead}
     * @memberof PostSimpleWithComments
     */
    course: CourseSimpleRead;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    pollId: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostSimpleWithComments
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostSimpleWithComments
     */
    isOwnStudentsPost: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostSimpleWithComments
     */
    isMemberOfPatronPrivateComments: boolean;
    /**
     * 
     * @type {CanvasRead}
     * @memberof PostSimpleWithComments
     */
    canvas: CanvasRead;
    /**
     * 
     * @type {CanvasRead}
     * @memberof PostSimpleWithComments
     */
    formCanvas?: CanvasRead;
    /**
     * 
     * @type {Array<CanvasInputAnswerRead>}
     * @memberof PostSimpleWithComments
     */
    canvasInputValueList: Array<CanvasInputAnswerRead>;
    /**
     * 
     * @type {Array<CanvasInputFileRead>}
     * @memberof PostSimpleWithComments
     */
    canvasInputFileList: Array<CanvasInputFileRead>;
    /**
     * 
     * @type {Array<PostFileRead>}
     * @memberof PostSimpleWithComments
     */
    files: Array<PostFileRead>;
    /**
     * 
     * @type {number}
     * @memberof PostSimpleWithComments
     */
    authorStudentNumInClass: number | null;
    /**
     * 
     * @type {Array<ReactionListItemRead>}
     * @memberof PostSimpleWithComments
     */
    reactions: Array<ReactionListItemRead>;
    /**
     * 
     * @type {string}
     * @memberof PostSimpleWithComments
     */
    fixedAt: string | null;
}
/**
 * 
 * @export
 * @interface PostStats
 */
export interface PostStats {
    /**
     * 
     * @type {number}
     * @memberof PostStats
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PostStats
     */
    averageTextLength: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostStats
     */
    studentNumInClass?: number;
}
/**
 * 投稿タグ作成用のモデル
 * @export
 * @interface PostTagCreate
 */
export interface PostTagCreate {
    /**
     * 
     * @type {string}
     * @memberof PostTagCreate
     */
    name: string;
    /**
     * 
     * @type {PostTagShareRangeEnum}
     * @memberof PostTagCreate
     */
    shareRange: PostTagShareRangeEnum;
}
/**
 * 投稿タグ取得用のモデル
 * @export
 * @interface PostTagRead
 */
export interface PostTagRead {
    /**
     * 
     * @type {string}
     * @memberof PostTagRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostTagRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PostTagRead
     */
    courseId: string;
    /**
     * 
     * @type {PostTagShareRangeEnum}
     * @memberof PostTagRead
     */
    shareRange: PostTagShareRangeEnum;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PostTagShareRangeEnum {
    MYOWN = 'myown',
    COURSE = 'course'
}

/**
 * 投稿タグ更新用のモデル
 * @export
 * @interface PostTagUpdate
 */
export interface PostTagUpdate {
    /**
     * 
     * @type {string}
     * @memberof PostTagUpdate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTagUpdate
     */
    courseId?: string;
    /**
     * 
     * @type {PostTagShareRangeEnum}
     * @memberof PostTagUpdate
     */
    shareRange?: PostTagShareRangeEnum;
}
/**
 * 投稿タグと、それの紐づいた投稿の数
 * @export
 * @interface PostTagWithPostCount
 */
export interface PostTagWithPostCount {
    /**
     * 
     * @type {string}
     * @memberof PostTagWithPostCount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostTagWithPostCount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PostTagWithPostCount
     */
    courseId: string;
    /**
     * 
     * @type {number}
     * @memberof PostTagWithPostCount
     */
    postCount: number;
}
/**
 * 投稿のテンプレート
 * @export
 * @interface PostTemplateRead
 */
export interface PostTemplateRead {
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRead
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRead
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRead
     */
    templateText: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRead
     */
    timestamp: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostTemplateRead
     */
    isSpecified: boolean;
}
/**
 * 
 * @export
 * @interface PostTimelineRead
 */
export interface PostTimelineRead {
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    text: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostTimelineRead
     */
    commentCount: number;
    /**
     * 
     * @type {UserRead}
     * @memberof PostTimelineRead
     */
    author: UserRead;
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    courseId: string;
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    createdAt: string;
    /**
     * 
     * @type {PostShareRangeEnum}
     * @memberof PostTimelineRead
     */
    shareRange: PostShareRangeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    fileUrl?: string;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof PostTimelineRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostTimelineRead
     */
    lastViewDatetime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostTimelineRead
     */
    commentTimestamps: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PostTimelineRead
     */
    authorStudentNumInClass: number | null;
    /**
     * 
     * @type {CanvasWithUsingCourse}
     * @memberof PostTimelineRead
     */
    canvas: CanvasWithUsingCourse;
    /**
     * 
     * @type {CanvasWithUsingCourse}
     * @memberof PostTimelineRead
     */
    formCanvas: CanvasWithUsingCourse;
    /**
     * 
     * @type {Array<CanvasInputAnswerRead>}
     * @memberof PostTimelineRead
     */
    canvasInputValueList: Array<CanvasInputAnswerRead>;
    /**
     * 
     * @type {Array<CanvasInputFileRead>}
     * @memberof PostTimelineRead
     */
    canvasInputFileList: Array<CanvasInputFileRead>;
    /**
     * 
     * @type {boolean}
     * @memberof PostTimelineRead
     */
    isCurrentCanvasPostFormPost: boolean;
}
/**
 * 
 * @export
 * @interface ReactionAuthorRead
 */
export interface ReactionAuthorRead {
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorRead
     */
    reactionId: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof ReactionAuthorRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorRead
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {ReactionAuthorReadProfileImageUrl}
     * @memberof ReactionAuthorRead
     */
    profileImageUrl: ReactionAuthorReadProfileImageUrl;
}
/**
 * 
 * @export
 * @interface ReactionAuthorReadProfileImageUrl
 */
export interface ReactionAuthorReadProfileImageUrl {
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorReadProfileImageUrl
     */
    size100: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReactionAuthorReadProfileImageUrl
     */
    size400: string | null;
}
/**
 * 
 * @export
 * @interface ReactionCreate
 */
export interface ReactionCreate {
    /**
     * 
     * @type {string}
     * @memberof ReactionCreate
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionCreate
     */
    emojiUnicode: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionCreate
     */
    emojiName: string;
    /**
     * 
     * @type {TargetModelNameEnum}
     * @memberof ReactionCreate
     */
    targetModelName: TargetModelNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ReactionCreate
     */
    targetObjectId: string;
}
/**
 * 
 * @export
 * @interface ReactionListItemRead
 */
export interface ReactionListItemRead {
    /**
     * 
     * @type {ReactionListItemReadEmoji}
     * @memberof ReactionListItemRead
     */
    emoji: ReactionListItemReadEmoji;
    /**
     * 
     * @type {Array<ReactionAuthorRead>}
     * @memberof ReactionListItemRead
     */
    authors: Array<ReactionAuthorRead>;
}
/**
 * 
 * @export
 * @interface ReactionListItemReadEmoji
 */
export interface ReactionListItemReadEmoji {
    /**
     * 
     * @type {string}
     * @memberof ReactionListItemReadEmoji
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionListItemReadEmoji
     */
    emojiUnicode: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionListItemReadEmoji
     */
    emojiName: string;
}
/**
 * 
 * @export
 * @interface ReactionRead
 */
export interface ReactionRead {
    /**
     * 
     * @type {string}
     * @memberof ReactionRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionRead
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionRead
     */
    emojiUnicode: string;
    /**
     * 
     * @type {string}
     * @memberof ReactionRead
     */
    emojiName: string;
}
/**
 * 
 * @export
 * @interface SchoolAndClassrooms
 */
export interface SchoolAndClassrooms {
    /**
     * 
     * @type {SchoolRead}
     * @memberof SchoolAndClassrooms
     */
    school: SchoolRead;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof SchoolAndClassrooms
     */
    classrooms: Array<HomeClassroomSimpleRead>;
}
/**
 * 
 * @export
 * @interface SchoolJoinCode
 */
export interface SchoolJoinCode {
    /**
     * 
     * @type {string}
     * @memberof SchoolJoinCode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolJoinCode
     */
    joinCode: string;
}
/**
 * 
 * @export
 * @interface SchoolRead
 */
export interface SchoolRead {
    /**
     * 
     * @type {string}
     * @memberof SchoolRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolRead
     */
    linkUrl: string | null;
    /**
     * 
     * @type {number}
     * @memberof SchoolRead
     */
    lat: number | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolRead
     */
    profileText: string | null;
    /**
     * 
     * @type {number}
     * @memberof SchoolRead
     */
    lon: number | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolRead
     */
    authProviderId: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SchoolTypeEnum {
    ELEMENTARY_SCHOOL = 'elementary_school',
    JUNIOR_HIGH_SCHOOL = 'junior_high_school',
    HIGH_SCHOOL = 'high_school',
    GIMUKYOIKU_SCHOOL = 'gimukyoiku_school',
    KYOIKU_IINKAI = 'kyoiku_iinkai',
    MISC = 'misc'
}

/**
 * 
 * @export
 * @interface SchoolUpdate
 */
export interface SchoolUpdate {
    /**
     * 
     * @type {string}
     * @memberof SchoolUpdate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolUpdate
     */
    profileText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SchoolUpdate
     */
    linkUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SchoolUpdate
     */
    lat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SchoolUpdate
     */
    lon?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SchoolUpdate
     */
    totalTerms?: number;
}
/**
 * 
 * @export
 * @interface SchoolWithStatsRead
 */
export interface SchoolWithStatsRead {
    /**
     * 
     * @type {string}
     * @memberof SchoolWithStatsRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolWithStatsRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolWithStatsRead
     */
    schoolTypeLabel: string;
    /**
     * 
     * @type {number}
     * @memberof SchoolWithStatsRead
     */
    teacherCount: number;
    /**
     * 
     * @type {number}
     * @memberof SchoolWithStatsRead
     */
    classroomCount: number;
    /**
     * 
     * @type {number}
     * @memberof SchoolWithStatsRead
     */
    studentCount: number;
}
/**
 * 
 * @export
 * @interface Shoken
 */
export interface Shoken {
    /**
     * 
     * @type {string}
     * @memberof Shoken
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Shoken
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof Shoken
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shoken
     */
    textByAi: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shoken
     */
    generatedByAiAt: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shoken
     */
    additionalStudentInfoByTeacher: string | null;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof Shoken
     */
    lastEditTeacher: TeacherUserRead;
}
/**
 * 
 * @export
 * @interface SogoShoken
 */
export interface SogoShoken {
    /**
     * 
     * @type {string}
     * @memberof SogoShoken
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SogoShoken
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof SogoShoken
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof SogoShoken
     */
    textByAi: string | null;
    /**
     * 
     * @type {string}
     * @memberof SogoShoken
     */
    generatedByAiAt: string | null;
    /**
     * 
     * @type {TeacherUserRead}
     * @memberof SogoShoken
     */
    lastEditTeacher: TeacherUserRead;
    /**
     * 
     * @type {Subject}
     * @memberof SogoShoken
     */
    subjectObject: Subject;
    /**
     * 
     * @type {string}
     * @memberof SogoShoken
     */
    noteByTeacher: string | null;
}
/**
 * 
 * @export
 * @interface StudentCreate
 */
export interface StudentCreate {
    /**
     * 
     * @type {string}
     * @memberof StudentCreate
     */
    joinCode: string;
    /**
     * 
     * @type {number}
     * @memberof StudentCreate
     */
    numInClass: number;
    /**
     * 
     * @type {string}
     * @memberof StudentCreate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCreate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCreate
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCreate
     */
    firstNameKana: string;
}
/**
 * 
 * @export
 * @interface StudentRoleDetailRead
 */
export interface StudentRoleDetailRead {
    /**
     * 
     * @type {string}
     * @memberof StudentRoleDetailRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRoleDetailRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRoleDetailRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {HomeClassroomRead}
     * @memberof StudentRoleDetailRead
     */
    classroom: HomeClassroomRead | null;
    /**
     * 
     * @type {number}
     * @memberof StudentRoleDetailRead
     */
    numInClass: number;
    /**
     * 
     * @type {Array<CourseRead>}
     * @memberof StudentRoleDetailRead
     */
    courses: Array<CourseRead>;
}
/**
 * 
 * @export
 * @interface StudentRolePrivateRead
 */
export interface StudentRolePrivateRead {
    /**
     * 
     * @type {string}
     * @memberof StudentRolePrivateRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRolePrivateRead
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRolePrivateRead
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRolePrivateRead
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRolePrivateRead
     */
    firstNameKana: string;
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof StudentRolePrivateRead
     */
    classroom: HomeClassroomSimpleRead | null;
    /**
     * 
     * @type {number}
     * @memberof StudentRolePrivateRead
     */
    numInClass: number;
    /**
     * 
     * @type {Array<CourseSimpleRead>}
     * @memberof StudentRolePrivateRead
     */
    courses: Array<CourseSimpleRead>;
    /**
     * 
     * @type {Array<PatronUserRead>}
     * @memberof StudentRolePrivateRead
     */
    patrons: Array<PatronUserRead>;
    /**
     * 
     * @type {Array<PatronUserRead>}
     * @memberof StudentRolePrivateRead
     */
    pendingPatrons: Array<PatronUserRead>;
}
/**
 * 
 * @export
 * @interface StudentRoleRead
 */
export interface StudentRoleRead {
    /**
     * 
     * @type {string}
     * @memberof StudentRoleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRoleRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRoleRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {HomeClassroomSimpleRead}
     * @memberof StudentRoleRead
     */
    classroom: HomeClassroomSimpleRead | null;
    /**
     * 
     * @type {number}
     * @memberof StudentRoleRead
     */
    numInClass: number;
}
/**
 * 
 * @export
 * @interface StudentRoleUpdate
 */
export interface StudentRoleUpdate {
    /**
     * 名前（漢字）
     * @type {string}
     * @memberof StudentRoleUpdate
     */
    firstName?: string;
    /**
     * なまえ（かな）
     * @type {string}
     * @memberof StudentRoleUpdate
     */
    firstNameKana?: string;
    /**
     * 苗字（漢字）
     * @type {string}
     * @memberof StudentRoleUpdate
     */
    lastName?: string;
    /**
     * みょうじ（なまえ）
     * @type {string}
     * @memberof StudentRoleUpdate
     */
    lastNameKana?: string;
    /**
     * 出席番号
     * @type {number}
     * @memberof StudentRoleUpdate
     */
    numInClass?: number;
    /**
     * 
     * @type {any}
     * @memberof StudentRoleUpdate
     */
    profileImage?: any;
}
/**
 * 
 * @export
 * @interface StudentUserRead
 */
export interface StudentUserRead {
    /**
     * 
     * @type {StudentRoleRead}
     * @memberof StudentUserRead
     */
    role: StudentRoleRead;
    /**
     * 
     * @type {string}
     * @memberof StudentUserRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof StudentUserRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentUserRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof StudentUserRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserRead
     */
    isDemoUser: boolean;
}
/**
 * 
 * @export
 * @interface StudentUserReadAllOf
 */
export interface StudentUserReadAllOf {
    /**
     * 
     * @type {StudentRoleRead}
     * @memberof StudentUserReadAllOf
     */
    role: StudentRoleRead;
}
/**
 * 
 * @export
 * @interface StudentUserReadForPrivileged
 */
export interface StudentUserReadForPrivileged {
    /**
     * 
     * @type {StudentRoleRead}
     * @memberof StudentUserReadForPrivileged
     */
    role: StudentRoleRead;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof StudentUserReadForPrivileged
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof StudentUserReadForPrivileged
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserReadForPrivileged
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserReadForPrivileged
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserReadForPrivileged
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudentUserReadForPrivileged
     */
    isDemoUser: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    authProviderId: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    firstNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    realFullName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivileged
     */
    realFullNameKana: string;
}
/**
 * 
 * @export
 * @interface StudentUserReadForPrivilegedAllOf
 */
export interface StudentUserReadForPrivilegedAllOf {
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    authProviderId: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    firstNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    realFullName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentUserReadForPrivilegedAllOf
     */
    realFullNameKana: string;
}
/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    labelKana: string;
}
/**
 * 
 * @export
 * @interface SupportChatMessageListPaginated
 */
export interface SupportChatMessageListPaginated {
    /**
     * 
     * @type {number}
     * @memberof SupportChatMessageListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof SupportChatMessageListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof SupportChatMessageListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<SupportChatMessageRead>}
     * @memberof SupportChatMessageListPaginated
     */
    results?: Array<SupportChatMessageRead>;
}
/**
 * 
 * @export
 * @interface SupportChatMessageListPaginatedAllOf
 */
export interface SupportChatMessageListPaginatedAllOf {
    /**
     * 
     * @type {Array<SupportChatMessageRead>}
     * @memberof SupportChatMessageListPaginatedAllOf
     */
    results?: Array<SupportChatMessageRead>;
}
/**
 * 
 * @export
 * @interface SupportChatMessageRead
 */
export interface SupportChatMessageRead {
    /**
     * 
     * @type {string}
     * @memberof SupportChatMessageRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupportChatMessageRead
     */
    text: string | null;
    /**
     * 
     * @type {UserRead}
     * @memberof SupportChatMessageRead
     */
    customer: UserRead;
    /**
     * 
     * @type {boolean}
     * @memberof SupportChatMessageRead
     */
    isMine: boolean;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof SupportChatMessageRead
     */
    fileType: FileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SupportChatMessageRead
     */
    fileUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupportChatMessageRead
     */
    thumbnailUrl: string | null;
}
/**
 * 
 * @export
 * @interface TangenRead
 */
export interface TangenRead {
    /**
     * 
     * @type {string}
     * @memberof TangenRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TangenRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TangenRead
     */
    schoolId: string;
    /**
     * 
     * @type {Subject}
     * @memberof TangenRead
     */
    subjectObject: Subject;
    /**
     * 
     * @type {number}
     * @memberof TangenRead
     */
    grade: number;
    /**
     * 
     * @type {string}
     * @memberof TangenRead
     */
    lastUsedAt: string;
    /**
     * 
     * @type {string}
     * @memberof TangenRead
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof TangenRead
     */
    belongsToRequestUserSchool: boolean;
}
/**
 * 
 * @export
 * @interface TangenWithCourses
 */
export interface TangenWithCourses {
    /**
     * 
     * @type {string}
     * @memberof TangenWithCourses
     */
    type: string;
    /**
     * 
     * @type {TangenRead}
     * @memberof TangenWithCourses
     */
    tangen: TangenRead;
    /**
     * 
     * @type {Array<CourseWithClassroomsAndTangen>}
     * @memberof TangenWithCourses
     */
    courses: Array<CourseWithClassroomsAndTangen>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TargetModelNameEnum {
    POST = 'Post',
    COMMENT = 'Comment'
}

/**
 * 
 * @export
 * @interface TeacherCreate
 */
export interface TeacherCreate {
    /**
     * 
     * @type {string}
     * @memberof TeacherCreate
     */
    joinCode: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreate
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherCreate
     */
    firstNameKana: string;
}
/**
 * 
 * @export
 * @interface TeacherRoleDetailRead
 */
export interface TeacherRoleDetailRead {
    /**
     * 
     * @type {string}
     * @memberof TeacherRoleDetailRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRoleDetailRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRoleDetailRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof TeacherRoleDetailRead
     */
    mainAssignedClassrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof TeacherRoleDetailRead
     */
    subAssignedClassrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {Array<GroupSimpleRead>}
     * @memberof TeacherRoleDetailRead
     */
    groups: Array<GroupSimpleRead>;
    /**
     * 
     * @type {Array<CourseSimpleRead>}
     * @memberof TeacherRoleDetailRead
     */
    courses: Array<CourseSimpleRead>;
}
/**
 * 
 * @export
 * @interface TeacherRolePrivateRead
 */
export interface TeacherRolePrivateRead {
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    lastNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    firstNameKana: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    schoolId: string;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof TeacherRolePrivateRead
     */
    mainAssignedClassrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {Array<HomeClassroomSimpleRead>}
     * @memberof TeacherRolePrivateRead
     */
    subAssignedClassrooms: Array<HomeClassroomSimpleRead>;
    /**
     * 
     * @type {Array<GroupSimpleRead>}
     * @memberof TeacherRolePrivateRead
     */
    groups: Array<GroupSimpleRead>;
    /**
     * 
     * @type {Array<CourseSimpleRead>}
     * @memberof TeacherRolePrivateRead
     */
    courses: Array<CourseSimpleRead>;
    /**
     * 
     * @type {string}
     * @memberof TeacherRolePrivateRead
     */
    idForExternalSchool: string;
}
/**
 * 
 * @export
 * @interface TeacherRoleRead
 */
export interface TeacherRoleRead {
    /**
     * 
     * @type {string}
     * @memberof TeacherRoleRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRoleRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherRoleRead
     */
    fullNameKana: string;
}
/**
 * 
 * @export
 * @interface TeacherRoleUpdate
 */
export interface TeacherRoleUpdate {
    /**
     * 名前（漢字）
     * @type {string}
     * @memberof TeacherRoleUpdate
     */
    firstName?: string;
    /**
     * なまえ（かな）
     * @type {string}
     * @memberof TeacherRoleUpdate
     */
    firstNameKana?: string;
    /**
     * 苗字（漢字）
     * @type {string}
     * @memberof TeacherRoleUpdate
     */
    lastName?: string;
    /**
     * みょうじ（なまえ）
     * @type {string}
     * @memberof TeacherRoleUpdate
     */
    lastNameKana?: string;
    /**
     * プロフィール画像
     * @type {any}
     * @memberof TeacherRoleUpdate
     */
    profileImage?: any;
}
/**
 * 
 * @export
 * @interface TeacherUserRead
 */
export interface TeacherUserRead {
    /**
     * 
     * @type {string}
     * @memberof TeacherUserRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof TeacherUserRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TeacherUserRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherUserRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof TeacherUserRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherUserRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherUserRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherUserRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeacherUserRead
     */
    isDemoUser: boolean;
    /**
     * 
     * @type {TeacherRoleRead}
     * @memberof TeacherUserRead
     */
    role: TeacherRoleRead;
}
/**
 * 
 * @export
 * @interface TeacherUserReadAllOf
 */
export interface TeacherUserReadAllOf {
    /**
     * 
     * @type {TeacherRoleRead}
     * @memberof TeacherUserReadAllOf
     */
    role: TeacherRoleRead;
}
/**
 * 
 * @export
 * @interface TemporaryCanvasInputFileRead
 */
export interface TemporaryCanvasInputFileRead {
    /**
     * 
     * @type {string}
     * @memberof TemporaryCanvasInputFileRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryCanvasInputFileRead
     */
    inputId: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryCanvasInputFileRead
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryCanvasInputFileRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof TemporaryCanvasInputFileRead
     */
    fileType: FileTypeEnum;
}
/**
 * 
 * @export
 * @interface TemporaryPostFileOrderList
 */
export interface TemporaryPostFileOrderList extends Array<any> {
}
/**
 * 
 * @export
 * @interface TemporaryPostFileRead
 */
export interface TemporaryPostFileRead {
    /**
     * 
     * @type {string}
     * @memberof TemporaryPostFileRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryPostFileRead
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof TemporaryPostFileRead
     */
    thumbnailUrl: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof TemporaryPostFileRead
     */
    fileType: FileTypeEnum;
}
/**
 * 
 * @export
 * @interface Tokens
 */
export interface Tokens {
    /**
     * 
     * @type {string}
     * @memberof Tokens
     */
    refresh: string;
    /**
     * 
     * @type {string}
     * @memberof Tokens
     */
    access: string;
}
/**
 * 
 * @export
 * @interface UserAuthRead
 */
export interface UserAuthRead {
    /**
     * 
     * @type {UserWithRoleIdRead}
     * @memberof UserAuthRead
     */
    user: UserWithRoleIdRead;
    /**
     * 
     * @type {Tokens}
     * @memberof UserAuthRead
     */
    tokens: Tokens;
    /**
     * 
     * @type {UserAuthReadStudent}
     * @memberof UserAuthRead
     */
    student?: UserAuthReadStudent;
    /**
     * 
     * @type {UserAuthReadPatron}
     * @memberof UserAuthRead
     */
    patron?: UserAuthReadPatron;
    /**
     * 
     * @type {string}
     * @memberof UserAuthRead
     */
    appUpdateMethod?: UserAuthReadAppUpdateMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAuthReadAppUpdateMethodEnum {
    UNKNOWN = 'unknown',
    VIA_SELF_SERVICE_APP = 'via_self_service_app'
}

/**
 * 
 * @export
 * @interface UserAuthReadPatron
 */
export interface UserAuthReadPatron {
    /**
     * 
     * @type {boolean}
     * @memberof UserAuthReadPatron
     */
    isEmailVerified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAuthReadPatron
     */
    hasAtLeastOneStudent: boolean;
}
/**
 * 
 * @export
 * @interface UserAuthReadStudent
 */
export interface UserAuthReadStudent {
    /**
     * 
     * @type {boolean}
     * @memberof UserAuthReadStudent
     */
    isPendingJoinClassroom: boolean;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    joinCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserCreate
     */
    roleType: UserRoleTypeEnum;
}
/**
 * 
 * @export
 * @interface UserCreateByIdToken
 */
export interface UserCreateByIdToken {
    /**
     * 
     * @type {string}
     * @memberof UserCreateByIdToken
     */
    joinCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateByIdToken
     */
    idToken: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserCreateByIdToken
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserCreateByIdToken
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateByIdToken
     */
    firstName: string;
}
/**
 * プロフィール画面用のデータ
 * @export
 * @interface UserDetailRead
 */
export interface UserDetailRead {
    /**
     * 
     * @type {string}
     * @memberof UserDetailRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserDetailRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDetailRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof UserDetailRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailRead
     */
    isDemoUser: boolean;
    /**
     * 
     * @type {TeacherRoleDetailRead | StudentRoleDetailRead | PatronRoleDetailRead}
     * @memberof UserDetailRead
     */
    role: TeacherRoleDetailRead | StudentRoleDetailRead | PatronRoleDetailRead;
}
/**
 * 
 * @export
 * @interface UserDetailReadAllOf
 */
export interface UserDetailReadAllOf {
    /**
     * 
     * @type {TeacherRoleDetailRead | StudentRoleDetailRead | PatronRoleDetailRead}
     * @memberof UserDetailReadAllOf
     */
    role?: TeacherRoleDetailRead | StudentRoleDetailRead | PatronRoleDetailRead;
}
/**
 * 
 * @export
 * @interface UserMood
 */
export interface UserMood {
    /**
     * MoodのUUID
     * @type {string}
     * @memberof UserMood
     */
    id: string;
    /**
     * 
     * @type {UserRead}
     * @memberof UserMood
     */
    user: UserRead;
    /**
     * 
     * @type {MoodChoice}
     * @memberof UserMood
     */
    moodChoice: MoodChoice;
    /**
     * 心の天気を記録した日付
     * @type {string}
     * @memberof UserMood
     */
    date: string;
    /**
     * 心の天気を記録した時間
     * @type {string}
     * @memberof UserMood
     */
    time: string;
}
/**
 * 
 * @export
 * @interface UserPrivateRead
 */
export interface UserPrivateRead {
    /**
     * 
     * @type {string}
     * @memberof UserPrivateRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserPrivateRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof UserPrivateRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof UserPrivateRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPrivateRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPrivateRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPrivateRead
     */
    isDemoUser: boolean;
    /**
     * 
     * @type {StudentRolePrivateRead | TeacherRolePrivateRead | PatronRolePrivateRead}
     * @memberof UserPrivateRead
     */
    role: StudentRolePrivateRead | TeacherRolePrivateRead | PatronRolePrivateRead;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateRead
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateRead
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateRead
     */
    authProviderId: string | null;
}
/**
 * 
 * @export
 * @interface UserPrivateReadAllOf
 */
export interface UserPrivateReadAllOf {
    /**
     * 
     * @type {StudentRolePrivateRead | TeacherRolePrivateRead | PatronRolePrivateRead}
     * @memberof UserPrivateReadAllOf
     */
    role: StudentRolePrivateRead | TeacherRolePrivateRead | PatronRolePrivateRead;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateReadAllOf
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateReadAllOf
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateReadAllOf
     */
    authProviderId: string | null;
}
/**
 * 
 * @export
 * @interface UserPrivateUpdate
 */
export interface UserPrivateUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserPrivateUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrivateUpdate
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface UserRead
 */
export interface UserRead {
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof UserRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof UserRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRead
     */
    isDemoUser: boolean;
}
/**
 * 
 * @export
 * @interface UserReadProfileImageUrl
 */
export interface UserReadProfileImageUrl {
    /**
     * 
     * @type {string}
     * @memberof UserReadProfileImageUrl
     */
    size1200: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadProfileImageUrl
     */
    size400: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadProfileImageUrl
     */
    size100: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserRoleTypeEnum {
    TEACHER = 'teacher',
    STUDENT = 'student',
    PATRON = 'patron'
}

/**
 * 自分への通知
 * @export
 * @interface UserSpecificNotification
 */
export interface UserSpecificNotification {
    /**
     * 
     * @type {string}
     * @memberof UserSpecificNotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserSpecificNotification
     */
    type: UserSpecificNotificationTypeEnum;
    /**
     * 
     * @type {UserSpecificNotificationContent}
     * @memberof UserSpecificNotification
     */
    content: UserSpecificNotificationContent;
    /**
     * 
     * @type {string}
     * @memberof UserSpecificNotification
     */
    timestamp: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserSpecificNotification
     */
    isRead: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSpecificNotificationTypeEnum {
    COMMENT_TO_THREAD = 'comment_to_thread',
    COMMENT_TO_POST = 'comment_to_post',
    REACTIONS_TO_COMMENT = 'reactions_to_comment',
    REACTIONS_TO_POST = 'reactions_to_post',
    MENTION_IN_POST = 'mention_in_post',
    MENTION_IN_COMMENT = 'mention_in_comment',
    DISCOMFORT_REPORT_ABOUT_COMMENT = 'discomfort_report_about_comment',
    DISCOMFORT_REPORT_ABOUT_POST = 'discomfort_report_about_post',
    COURSE_JOIN_REQUESTED = 'course_join_requested',
    COURSE_JOIN_REQUEST_ACCEPTED = 'course_join_request_accepted',
    BAD_WORD_IN_POST = 'bad_word_in_post',
    BAD_WORD_IN_COMMENT = 'bad_word_in_comment'
}

/**
 * @type UserSpecificNotificationContent
 * @export
 */
export type UserSpecificNotificationContent = NotificationCourseJoinRequestAccepted | NotificationCourseJoinRequested | NotificationOfBadWordInComment | NotificationOfBadWordInPost | NotificationOfCommentToPost | NotificationOfCommentToThread | NotificationOfDiscomfortReportToComment | NotificationOfDiscomfortReportToPost | NotificationOfMentionInComment | NotificationOfMentionInPost | NotificationOfReactionsToComment | NotificationOfReactionsToPost;

/**
 * 
 * @export
 * @interface UserSpecificNotificationListPaginated
 */
export interface UserSpecificNotificationListPaginated {
    /**
     * 
     * @type {number}
     * @memberof UserSpecificNotificationListPaginated
     */
    count: number;
    /**
     * 前のURLが無い=先頭 ページの場合はnull
     * @type {string}
     * @memberof UserSpecificNotificationListPaginated
     */
    prev: string | null;
    /**
     * 次のURLが無い=最後のページ の場合はnull
     * @type {string}
     * @memberof UserSpecificNotificationListPaginated
     */
    next: string | null;
    /**
     * 
     * @type {Array<UserSpecificNotification>}
     * @memberof UserSpecificNotificationListPaginated
     */
    results?: Array<UserSpecificNotification>;
}
/**
 * 
 * @export
 * @interface UserSpecificNotificationListPaginatedAllOf
 */
export interface UserSpecificNotificationListPaginatedAllOf {
    /**
     * 
     * @type {Array<UserSpecificNotification>}
     * @memberof UserSpecificNotificationListPaginatedAllOf
     */
    results?: Array<UserSpecificNotification>;
}
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface UserWithRoleIdRead
 */
export interface UserWithRoleIdRead {
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserWithRoleIdRead
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof UserWithRoleIdRead
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    canTestBetaFeatures: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    authProviderId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    isTeacherAtOrg: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    isElementarySchoolStudent: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserWithRoleIdRead
     */
    studentGrade: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    studentClassroomId: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWithRoleIdRead
     */
    studentClassroomIdsInThisNendo: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserWithRoleIdRead
     */
    studentNumInClass: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    moodTrackerEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdRead
     */
    teacherSchoolId: string | null;
    /**
     * 
     * @type {SchoolTypeEnum}
     * @memberof UserWithRoleIdRead
     */
    teacherSchoolType: SchoolTypeEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    canJoinToMultiSchoolsCourse: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserWithRoleIdRead
     */
    schoolTotalTerms: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    shouldShowFurigana: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    isTeacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    isStudent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    isPatron: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdRead
     */
    isDemoUser: boolean;
}
/**
 * 
 * @export
 * @interface UserWithRoleIdReadAllOf
 */
export interface UserWithRoleIdReadAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    id: string;
    /**
     * 
     * @type {UserRoleTypeEnum}
     * @memberof UserWithRoleIdReadAllOf
     */
    roleType: UserRoleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    fullNameKana: string;
    /**
     * 
     * @type {UserReadProfileImageUrl}
     * @memberof UserWithRoleIdReadAllOf
     */
    profileImageUrl: UserReadProfileImageUrl;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdReadAllOf
     */
    canTestBetaFeatures: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    authProviderId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdReadAllOf
     */
    isTeacherAtOrg: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdReadAllOf
     */
    isElementarySchoolStudent: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserWithRoleIdReadAllOf
     */
    studentGrade: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    studentClassroomId: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWithRoleIdReadAllOf
     */
    studentClassroomIdsInThisNendo: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserWithRoleIdReadAllOf
     */
    studentNumInClass: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdReadAllOf
     */
    moodTrackerEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithRoleIdReadAllOf
     */
    teacherSchoolId: string | null;
    /**
     * 
     * @type {SchoolTypeEnum}
     * @memberof UserWithRoleIdReadAllOf
     */
    teacherSchoolType: SchoolTypeEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdReadAllOf
     */
    canJoinToMultiSchoolsCourse: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserWithRoleIdReadAllOf
     */
    schoolTotalTerms: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRoleIdReadAllOf
     */
    shouldShowFurigana: boolean;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザの認証確認
         * @summary CheckAuth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth_check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードを忘れたときの再設定のPOST
         * @summary Password Reset Confirm
         * @param {InlineObject23} [inlineObject23] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmResetPassword: async (inlineObject23?: InlineObject23, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/password/reset/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject23, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 児童用のワンタイムログインコードを発行
         * @summary Create Student Onetime Login Code
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentOnetimeLoginCode: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('createStudentOnetimeLoginCode', 'studentId', studentId)
            const localVarPath = `/api/v1/students/{student_id}/onetime_login_codes`
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create a User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreate: UserCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('createUser', 'userCreate', userCreate)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create User By Id Token
         * @param {UserCreateByIdToken} userCreateByIdToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByIdToken: async (userCreateByIdToken: UserCreateByIdToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateByIdToken' is not null or undefined
            assertParamExists('createUserByIdToken', 'userCreateByIdToken', userCreateByIdToken)
            const localVarPath = `/api/v1/users/by_id_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateByIdToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身を削除する。登録作業を途中で止める時専用。すでに投稿かコメントをしたことがあると、403が返る。
         * @summary Delete Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * firebase userの取得
         * @summary Get Firebase User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnFirebaseUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/firebase_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 最後にクラスルームに参加した(リクエストの)日付を取得
         * @summary Get Last Classroom Join Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentLastClassroomJoinDate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/students/me/last_classroom_join_date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * (private)自分自身の情報を返すエンドポイント
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPrivateData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * WebSocket用の一時トークンの取得
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsocketToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/websocket_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログイン
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/api/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ノーマルユーザーとしてログイン
         * @summary LoginAsNormalUser
         * @param {string} userId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsNormalUser: async (userId: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loginAsNormalUser', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('loginAsNormalUser', 'body', body)
            const localVarPath = `/api/v1/users/{user_id}/login_as`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDトークンによるログイン
         * @summary Login By ID Token
         * @param {IdTokenObject} idTokenObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByIdToken: async (idTokenObject: IdTokenObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTokenObject' is not null or undefined
            assertParamExists('loginByIdToken', 'idTokenObject', idTokenObject)
            const localVarPath = `/api/v1/login/by_id_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idTokenObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDトークンによるログイン
         * @summary Login By ID Token
         * @param {IdTokenObject} idTokenObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByMicrosoftIdToken: async (idTokenObject: IdTokenObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTokenObject' is not null or undefined
            assertParamExists('loginByMicrosoftIdToken', 'idTokenObject', idTokenObject)
            const localVarPath = `/api/v1/login/by_microsoft_id_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idTokenObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワンタイムコードでログイン
         * @summary Login By Onetime Code
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByOnetimeCode: async (inlineObject19?: InlineObject19, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/login_by_onetime_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ゲスト用ログイン
         * @summary Login For Guest
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginForGuest: async (login: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginForGuest', 'login', login)
            const localVarPath = `/api/v1/login_for_guest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログアウト(HttpOnlyのCookieの削除なのでWebだけ)
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @summary RefreshToken
         * @param {Tokens} tokens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (tokens: Tokens, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokens' is not null or undefined
            assertParamExists('refreshToken', 'tokens', tokens)
            const localVarPath = `/api/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教員を学校から除く
         * @summary Remove Teacher From School
         * @param {InlineObject47} [inlineObject47] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeacherFromSchool: async (inlineObject47?: InlineObject47, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/remove_teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject47, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードを忘れたときの再設定メールをリクエストする
         * @summary Request Password Reset Email
         * @param {InlineObject24} [inlineObject24] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetEmail: async (inlineObject24?: InlineObject24, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject24, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update App Screen View Datetime
         * @param {string} [screenName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppScreenViewLastDatetime: async (screenName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/app_screen_view_datetime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (screenName !== undefined) {
                localVarQueryParameter['screen_name'] = screenName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * firebase_userをid_tokenで更新する
         * @summary Update Firebase User
         * @param {InlineObject29} [inlineObject29] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnFirebaseUser: async (inlineObject29?: InlineObject29, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/firebase_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject29, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * (private)自分自身の情報を更新
         * @param {UserPrivateUpdate} [userPrivateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPrivateData: async (userPrivateUpdate?: UserPrivateUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPrivateUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザの認証確認
         * @summary CheckAuth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAuth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワードを忘れたときの再設定のPOST
         * @summary Password Reset Confirm
         * @param {InlineObject23} [inlineObject23] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmResetPassword(inlineObject23?: InlineObject23, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmResetPassword(inlineObject23, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 児童用のワンタイムログインコードを発行
         * @summary Create Student Onetime Login Code
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudentOnetimeLoginCode(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStudentOnetimeLoginCode(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create a User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreate: UserCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create User By Id Token
         * @param {UserCreateByIdToken} userCreateByIdToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserByIdToken(userCreateByIdToken: UserCreateByIdToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserByIdToken(userCreateByIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身を削除する。登録作業を途中で止める時専用。すでに投稿かコメントをしたことがあると、403が返る。
         * @summary Delete Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * firebase userの取得
         * @summary Get Firebase User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnFirebaseUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnFirebaseUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 最後にクラスルームに参加した(リクエストの)日付を取得
         * @summary Get Last Classroom Join Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentLastClassroomJoinDate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentLastClassroomJoinDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * (private)自分自身の情報を返すエンドポイント
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPrivateData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrivateRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPrivateData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * WebSocket用の一時トークンの取得
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebsocketToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebsocketToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログイン
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ノーマルユーザーとしてログイン
         * @summary LoginAsNormalUser
         * @param {string} userId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAsNormalUser(userId: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAsNormalUser(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDトークンによるログイン
         * @summary Login By ID Token
         * @param {IdTokenObject} idTokenObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginByIdToken(idTokenObject: IdTokenObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginByIdToken(idTokenObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDトークンによるログイン
         * @summary Login By ID Token
         * @param {IdTokenObject} idTokenObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginByMicrosoftIdToken(idTokenObject: IdTokenObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginByMicrosoftIdToken(idTokenObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワンタイムコードでログイン
         * @summary Login By Onetime Code
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginByOnetimeCode(inlineObject19?: InlineObject19, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginByOnetimeCode(inlineObject19, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ゲスト用ログイン
         * @summary Login For Guest
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginForGuest(login: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginForGuest(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログアウト(HttpOnlyのCookieの削除なのでWebだけ)
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @summary RefreshToken
         * @param {Tokens} tokens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(tokens: Tokens, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(tokens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教員を学校から除く
         * @summary Remove Teacher From School
         * @param {InlineObject47} [inlineObject47] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTeacherFromSchool(inlineObject47?: InlineObject47, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTeacherFromSchool(inlineObject47, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワードを忘れたときの再設定メールをリクエストする
         * @summary Request Password Reset Email
         * @param {InlineObject24} [inlineObject24] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPasswordResetEmail(inlineObject24?: InlineObject24, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPasswordResetEmail(inlineObject24, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update App Screen View Datetime
         * @param {string} [screenName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppScreenViewLastDatetime(screenName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppScreenViewLastDatetime(screenName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * firebase_userをid_tokenで更新する
         * @summary Update Firebase User
         * @param {InlineObject29} [inlineObject29] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnFirebaseUser(inlineObject29?: InlineObject29, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnFirebaseUser(inlineObject29, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * (private)自分自身の情報を更新
         * @param {UserPrivateUpdate} [userPrivateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPrivateData(userPrivateUpdate?: UserPrivateUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrivateRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPrivateData(userPrivateUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * ユーザの認証確認
         * @summary CheckAuth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuth(options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.checkAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードを忘れたときの再設定のPOST
         * @summary Password Reset Confirm
         * @param {InlineObject23} [inlineObject23] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmResetPassword(inlineObject23?: InlineObject23, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.confirmResetPassword(inlineObject23, options).then((request) => request(axios, basePath));
        },
        /**
         * 児童用のワンタイムログインコードを発行
         * @summary Create Student Onetime Login Code
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentOnetimeLoginCode(studentId: string, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.createStudentOnetimeLoginCode(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create a User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreate: UserCreate, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.createUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create User By Id Token
         * @param {UserCreateByIdToken} userCreateByIdToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByIdToken(userCreateByIdToken: UserCreateByIdToken, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.createUserByIdToken(userCreateByIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身を削除する。登録作業を途中で止める時専用。すでに投稿かコメントをしたことがあると、403が返る。
         * @summary Delete Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMe(options?: any): AxiosPromise<void> {
            return localVarFp.deleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * firebase userの取得
         * @summary Get Firebase User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnFirebaseUser(options?: any): AxiosPromise<void> {
            return localVarFp.getOwnFirebaseUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 最後にクラスルームに参加した(リクエストの)日付を取得
         * @summary Get Last Classroom Join Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentLastClassroomJoinDate(options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.getStudentLastClassroomJoinDate(options).then((request) => request(axios, basePath));
        },
        /**
         * (private)自分自身の情報を返すエンドポイント
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPrivateData(options?: any): AxiosPromise<UserPrivateRead> {
            return localVarFp.getUserPrivateData(options).then((request) => request(axios, basePath));
        },
        /**
         * WebSocket用の一時トークンの取得
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsocketToken(options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.getWebsocketToken(options).then((request) => request(axios, basePath));
        },
        /**
         * ログイン
         * @summary Login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * ノーマルユーザーとしてログイン
         * @summary LoginAsNormalUser
         * @param {string} userId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsNormalUser(userId: string, body: object, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.loginAsNormalUser(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * IDトークンによるログイン
         * @summary Login By ID Token
         * @param {IdTokenObject} idTokenObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByIdToken(idTokenObject: IdTokenObject, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.loginByIdToken(idTokenObject, options).then((request) => request(axios, basePath));
        },
        /**
         * IDトークンによるログイン
         * @summary Login By ID Token
         * @param {IdTokenObject} idTokenObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByMicrosoftIdToken(idTokenObject: IdTokenObject, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.loginByMicrosoftIdToken(idTokenObject, options).then((request) => request(axios, basePath));
        },
        /**
         * ワンタイムコードでログイン
         * @summary Login By Onetime Code
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByOnetimeCode(inlineObject19?: InlineObject19, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.loginByOnetimeCode(inlineObject19, options).then((request) => request(axios, basePath));
        },
        /**
         * ゲスト用ログイン
         * @summary Login For Guest
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginForGuest(login: Login, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.loginForGuest(login, options).then((request) => request(axios, basePath));
        },
        /**
         * ログアウト(HttpOnlyのCookieの削除なのでWebだけ)
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @summary RefreshToken
         * @param {Tokens} tokens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(tokens: Tokens, options?: any): AxiosPromise<Tokens> {
            return localVarFp.refreshToken(tokens, options).then((request) => request(axios, basePath));
        },
        /**
         * 教員を学校から除く
         * @summary Remove Teacher From School
         * @param {InlineObject47} [inlineObject47] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeacherFromSchool(inlineObject47?: InlineObject47, options?: any): AxiosPromise<void> {
            return localVarFp.removeTeacherFromSchool(inlineObject47, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードを忘れたときの再設定メールをリクエストする
         * @summary Request Password Reset Email
         * @param {InlineObject24} [inlineObject24] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetEmail(inlineObject24?: InlineObject24, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.requestPasswordResetEmail(inlineObject24, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update App Screen View Datetime
         * @param {string} [screenName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppScreenViewLastDatetime(screenName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateAppScreenViewLastDatetime(screenName, options).then((request) => request(axios, basePath));
        },
        /**
         * firebase_userをid_tokenで更新する
         * @summary Update Firebase User
         * @param {InlineObject29} [inlineObject29] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnFirebaseUser(inlineObject29?: InlineObject29, options?: any): AxiosPromise<void> {
            return localVarFp.updateOwnFirebaseUser(inlineObject29, options).then((request) => request(axios, basePath));
        },
        /**
         * (private)自分自身の情報を更新
         * @param {UserPrivateUpdate} [userPrivateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPrivateData(userPrivateUpdate?: UserPrivateUpdate, options?: any): AxiosPromise<UserPrivateRead> {
            return localVarFp.updateUserPrivateData(userPrivateUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * ユーザの認証確認
     * @summary CheckAuth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public checkAuth(options?: any) {
        return AccountsApiFp(this.configuration).checkAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードを忘れたときの再設定のPOST
     * @summary Password Reset Confirm
     * @param {InlineObject23} [inlineObject23] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public confirmResetPassword(inlineObject23?: InlineObject23, options?: any) {
        return AccountsApiFp(this.configuration).confirmResetPassword(inlineObject23, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 児童用のワンタイムログインコードを発行
     * @summary Create Student Onetime Login Code
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createStudentOnetimeLoginCode(studentId: string, options?: any) {
        return AccountsApiFp(this.configuration).createStudentOnetimeLoginCode(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
     * @summary Create a User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createUser(userCreate: UserCreate, options?: any) {
        return AccountsApiFp(this.configuration).createUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
     * @summary Create User By Id Token
     * @param {UserCreateByIdToken} userCreateByIdToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createUserByIdToken(userCreateByIdToken: UserCreateByIdToken, options?: any) {
        return AccountsApiFp(this.configuration).createUserByIdToken(userCreateByIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分自身を削除する。登録作業を途中で止める時専用。すでに投稿かコメントをしたことがあると、403が返る。
     * @summary Delete Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteMe(options?: any) {
        return AccountsApiFp(this.configuration).deleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * firebase userの取得
     * @summary Get Firebase User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getOwnFirebaseUser(options?: any) {
        return AccountsApiFp(this.configuration).getOwnFirebaseUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 最後にクラスルームに参加した(リクエストの)日付を取得
     * @summary Get Last Classroom Join Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getStudentLastClassroomJoinDate(options?: any) {
        return AccountsApiFp(this.configuration).getStudentLastClassroomJoinDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * (private)自分自身の情報を返すエンドポイント
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getUserPrivateData(options?: any) {
        return AccountsApiFp(this.configuration).getUserPrivateData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * WebSocket用の一時トークンの取得
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getWebsocketToken(options?: any) {
        return AccountsApiFp(this.configuration).getWebsocketToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログイン
     * @summary Login
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public login(login: Login, options?: any) {
        return AccountsApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ノーマルユーザーとしてログイン
     * @summary LoginAsNormalUser
     * @param {string} userId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public loginAsNormalUser(userId: string, body: object, options?: any) {
        return AccountsApiFp(this.configuration).loginAsNormalUser(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDトークンによるログイン
     * @summary Login By ID Token
     * @param {IdTokenObject} idTokenObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public loginByIdToken(idTokenObject: IdTokenObject, options?: any) {
        return AccountsApiFp(this.configuration).loginByIdToken(idTokenObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDトークンによるログイン
     * @summary Login By ID Token
     * @param {IdTokenObject} idTokenObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public loginByMicrosoftIdToken(idTokenObject: IdTokenObject, options?: any) {
        return AccountsApiFp(this.configuration).loginByMicrosoftIdToken(idTokenObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワンタイムコードでログイン
     * @summary Login By Onetime Code
     * @param {InlineObject19} [inlineObject19] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public loginByOnetimeCode(inlineObject19?: InlineObject19, options?: any) {
        return AccountsApiFp(this.configuration).loginByOnetimeCode(inlineObject19, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ゲスト用ログイン
     * @summary Login For Guest
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public loginForGuest(login: Login, options?: any) {
        return AccountsApiFp(this.configuration).loginForGuest(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログアウト(HttpOnlyのCookieの削除なのでWebだけ)
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public logout(options?: any) {
        return AccountsApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @summary RefreshToken
     * @param {Tokens} tokens 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public refreshToken(tokens: Tokens, options?: any) {
        return AccountsApiFp(this.configuration).refreshToken(tokens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教員を学校から除く
     * @summary Remove Teacher From School
     * @param {InlineObject47} [inlineObject47] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public removeTeacherFromSchool(inlineObject47?: InlineObject47, options?: any) {
        return AccountsApiFp(this.configuration).removeTeacherFromSchool(inlineObject47, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードを忘れたときの再設定メールをリクエストする
     * @summary Request Password Reset Email
     * @param {InlineObject24} [inlineObject24] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public requestPasswordResetEmail(inlineObject24?: InlineObject24, options?: any) {
        return AccountsApiFp(this.configuration).requestPasswordResetEmail(inlineObject24, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update App Screen View Datetime
     * @param {string} [screenName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateAppScreenViewLastDatetime(screenName?: string, options?: any) {
        return AccountsApiFp(this.configuration).updateAppScreenViewLastDatetime(screenName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * firebase_userをid_tokenで更新する
     * @summary Update Firebase User
     * @param {InlineObject29} [inlineObject29] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateOwnFirebaseUser(inlineObject29?: InlineObject29, options?: any) {
        return AccountsApiFp(this.configuration).updateOwnFirebaseUser(inlineObject29, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * (private)自分自身の情報を更新
     * @param {UserPrivateUpdate} [userPrivateUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateUserPrivateData(userPrivateUpdate?: UserPrivateUpdate, options?: any) {
        return AccountsApiFp(this.configuration).updateUserPrivateData(userPrivateUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AchievementsApi - axios parameter creator
 * @export
 */
export const AchievementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * medalの既読をTrueにする。クエリパラメタでメダルの種類を指定する。
         * @summary Post Medal Read
         * @param {string} [medalType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMedalRead: async (medalType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teachers/me/medals/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (medalType !== undefined) {
                localVarQueryParameter['medal_type'] = medalType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchievementsApi - functional programming interface
 * @export
 */
export const AchievementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchievementsApiAxiosParamCreator(configuration)
    return {
        /**
         * medalの既読をTrueにする。クエリパラメタでメダルの種類を指定する。
         * @summary Post Medal Read
         * @param {string} [medalType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMedalRead(medalType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMedalRead(medalType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AchievementsApi - factory interface
 * @export
 */
export const AchievementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchievementsApiFp(configuration)
    return {
        /**
         * medalの既読をTrueにする。クエリパラメタでメダルの種類を指定する。
         * @summary Post Medal Read
         * @param {string} [medalType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMedalRead(medalType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postMedalRead(medalType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchievementsApi - object-oriented interface
 * @export
 * @class AchievementsApi
 * @extends {BaseAPI}
 */
export class AchievementsApi extends BaseAPI {
    /**
     * medalの既読をTrueにする。クエリパラメタでメダルの種類を指定する。
     * @summary Post Medal Read
     * @param {string} [medalType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementsApi
     */
    public postMedalRead(medalType?: string, options?: any) {
        return AchievementsApiFp(this.configuration).postMedalRead(medalType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiFeedbacksApi - axios parameter creator
 * @export
 */
export const AiFeedbacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject30} [inlineObject30] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiFeedback: async (inlineObject30?: InlineObject30, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ai_feedbacks/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject30, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Custom AI Feedback
         * @param {InlineObject32} [inlineObject32] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomAiFeedback: async (inlineObject32?: InlineObject32, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/custom_ai_feedbacks/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject32, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AIに深掘ってほしいテキストをPostする。instructionには、どういった指示でそのテキストが書かれたかが入る(例: 今日楽しかったことを書いてください)
         * @summary Fukabori Ai Post
         * @param {InlineObject31} [inlineObject31] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFukaboriFromAi: async (inlineObject31?: InlineObject31, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/fukabori_ai/async_stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject31, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiFeedbacksApi - functional programming interface
 * @export
 */
export const AiFeedbacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiFeedbacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InlineObject30} [inlineObject30] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiFeedback(inlineObject30?: InlineObject30, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiFeedback(inlineObject30, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Custom AI Feedback
         * @param {InlineObject32} [inlineObject32] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomAiFeedback(inlineObject32?: InlineObject32, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomAiFeedback(inlineObject32, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * AIに深掘ってほしいテキストをPostする。instructionには、どういった指示でそのテキストが書かれたかが入る(例: 今日楽しかったことを書いてください)
         * @summary Fukabori Ai Post
         * @param {InlineObject31} [inlineObject31] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFukaboriFromAi(inlineObject31?: InlineObject31, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFukaboriFromAi(inlineObject31, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiFeedbacksApi - factory interface
 * @export
 */
export const AiFeedbacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiFeedbacksApiFp(configuration)
    return {
        /**
         * 
         * @param {InlineObject30} [inlineObject30] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiFeedback(inlineObject30?: InlineObject30, options?: any): AxiosPromise<string> {
            return localVarFp.getAiFeedback(inlineObject30, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Custom AI Feedback
         * @param {InlineObject32} [inlineObject32] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomAiFeedback(inlineObject32?: InlineObject32, options?: any): AxiosPromise<string> {
            return localVarFp.getCustomAiFeedback(inlineObject32, options).then((request) => request(axios, basePath));
        },
        /**
         * AIに深掘ってほしいテキストをPostする。instructionには、どういった指示でそのテキストが書かれたかが入る(例: 今日楽しかったことを書いてください)
         * @summary Fukabori Ai Post
         * @param {InlineObject31} [inlineObject31] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFukaboriFromAi(inlineObject31?: InlineObject31, options?: any): AxiosPromise<string> {
            return localVarFp.getFukaboriFromAi(inlineObject31, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiFeedbacksApi - object-oriented interface
 * @export
 * @class AiFeedbacksApi
 * @extends {BaseAPI}
 */
export class AiFeedbacksApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject30} [inlineObject30] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFeedbacksApi
     */
    public getAiFeedback(inlineObject30?: InlineObject30, options?: any) {
        return AiFeedbacksApiFp(this.configuration).getAiFeedback(inlineObject30, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Custom AI Feedback
     * @param {InlineObject32} [inlineObject32] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFeedbacksApi
     */
    public getCustomAiFeedback(inlineObject32?: InlineObject32, options?: any) {
        return AiFeedbacksApiFp(this.configuration).getCustomAiFeedback(inlineObject32, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AIに深掘ってほしいテキストをPostする。instructionには、どういった指示でそのテキストが書かれたかが入る(例: 今日楽しかったことを書いてください)
     * @summary Fukabori Ai Post
     * @param {InlineObject31} [inlineObject31] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFeedbacksApi
     */
    public getFukaboriFromAi(inlineObject31?: InlineObject31, options?: any) {
        return AiFeedbacksApiFp(this.configuration).getFukaboriFromAi(inlineObject31, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiNavigatorsApi - axios parameter creator
 * @export
 */
export const AiNavigatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ナビゲーターbotの作成
         * @summary Create Navigator Bot
         * @param {InlineObject60} [inlineObject60] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNavigatorBot: async (inlineObject60?: InlineObject60, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/navigator_bots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject60, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ナビゲーターbotの削除
         * @summary Delete Navigator Bot
         * @param {string} botId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNavigatorBot: async (botId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'botId' is not null or undefined
            assertParamExists('deleteNavigatorBot', 'botId', botId)
            const localVarPath = `/api/v1/navigator_bots/{bot_id}`
                .replace(`{${"bot_id"}}`, encodeURIComponent(String(botId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在の状況を送信して、AIからのサポートを取得
         * @summary Get AI Navigation Async Stream
         * @param {string} courseId 
         * @param {InlineObject62} [inlineObject62] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiNavigationStream: async (courseId: string, inlineObject62?: InlineObject62, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getAiNavigationStream', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/ai_navigation/async_stream`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject62, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ナビゲーターbotの更新
         * @summary Update Navigator Bot
         * @param {string} botId 
         * @param {InlineObject61} [inlineObject61] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNavigatorBot: async (botId: string, inlineObject61?: InlineObject61, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'botId' is not null or undefined
            assertParamExists('updateNavigatorBot', 'botId', botId)
            const localVarPath = `/api/v1/navigator_bots/{bot_id}`
                .replace(`{${"bot_id"}}`, encodeURIComponent(String(botId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject61, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiNavigatorsApi - functional programming interface
 * @export
 */
export const AiNavigatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiNavigatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * ナビゲーターbotの作成
         * @summary Create Navigator Bot
         * @param {InlineObject60} [inlineObject60] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNavigatorBot(inlineObject60?: InlineObject60, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNavigatorBot(inlineObject60, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ナビゲーターbotの削除
         * @summary Delete Navigator Bot
         * @param {string} botId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNavigatorBot(botId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNavigatorBot(botId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在の状況を送信して、AIからのサポートを取得
         * @summary Get AI Navigation Async Stream
         * @param {string} courseId 
         * @param {InlineObject62} [inlineObject62] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiNavigationStream(courseId: string, inlineObject62?: InlineObject62, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiNavigationStream(courseId, inlineObject62, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ナビゲーターbotの更新
         * @summary Update Navigator Bot
         * @param {string} botId 
         * @param {InlineObject61} [inlineObject61] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNavigatorBot(botId: string, inlineObject61?: InlineObject61, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNavigatorBot(botId, inlineObject61, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiNavigatorsApi - factory interface
 * @export
 */
export const AiNavigatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiNavigatorsApiFp(configuration)
    return {
        /**
         * ナビゲーターbotの作成
         * @summary Create Navigator Bot
         * @param {InlineObject60} [inlineObject60] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNavigatorBot(inlineObject60?: InlineObject60, options?: any): AxiosPromise<InlineResponse2016> {
            return localVarFp.createNavigatorBot(inlineObject60, options).then((request) => request(axios, basePath));
        },
        /**
         * ナビゲーターbotの削除
         * @summary Delete Navigator Bot
         * @param {string} botId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNavigatorBot(botId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNavigatorBot(botId, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在の状況を送信して、AIからのサポートを取得
         * @summary Get AI Navigation Async Stream
         * @param {string} courseId 
         * @param {InlineObject62} [inlineObject62] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiNavigationStream(courseId: string, inlineObject62?: InlineObject62, options?: any): AxiosPromise<void> {
            return localVarFp.getAiNavigationStream(courseId, inlineObject62, options).then((request) => request(axios, basePath));
        },
        /**
         * ナビゲーターbotの更新
         * @summary Update Navigator Bot
         * @param {string} botId 
         * @param {InlineObject61} [inlineObject61] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNavigatorBot(botId: string, inlineObject61?: InlineObject61, options?: any): AxiosPromise<void> {
            return localVarFp.updateNavigatorBot(botId, inlineObject61, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiNavigatorsApi - object-oriented interface
 * @export
 * @class AiNavigatorsApi
 * @extends {BaseAPI}
 */
export class AiNavigatorsApi extends BaseAPI {
    /**
     * ナビゲーターbotの作成
     * @summary Create Navigator Bot
     * @param {InlineObject60} [inlineObject60] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiNavigatorsApi
     */
    public createNavigatorBot(inlineObject60?: InlineObject60, options?: any) {
        return AiNavigatorsApiFp(this.configuration).createNavigatorBot(inlineObject60, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ナビゲーターbotの削除
     * @summary Delete Navigator Bot
     * @param {string} botId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiNavigatorsApi
     */
    public deleteNavigatorBot(botId: string, options?: any) {
        return AiNavigatorsApiFp(this.configuration).deleteNavigatorBot(botId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在の状況を送信して、AIからのサポートを取得
     * @summary Get AI Navigation Async Stream
     * @param {string} courseId 
     * @param {InlineObject62} [inlineObject62] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiNavigatorsApi
     */
    public getAiNavigationStream(courseId: string, inlineObject62?: InlineObject62, options?: any) {
        return AiNavigatorsApiFp(this.configuration).getAiNavigationStream(courseId, inlineObject62, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ナビゲーターbotの更新
     * @summary Update Navigator Bot
     * @param {string} botId 
     * @param {InlineObject61} [inlineObject61] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiNavigatorsApi
     */
    public updateNavigatorBot(botId: string, inlineObject61?: InlineObject61, options?: any) {
        return AiNavigatorsApiFp(this.configuration).updateNavigatorBot(botId, inlineObject61, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementsApi - axios parameter creator
 * @export
 */
export const AnnouncementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change Announcement File Orders or Delete File
         * @param {string} announcementId 
         * @param {InlineObject17} [inlineObject17] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAnnouncementFileOrdersOrDeleteFile: async (announcementId: string, inlineObject17?: InlineObject17, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('changeAnnouncementFileOrdersOrDeleteFile', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}/file_orders`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校の連絡を作成。 今は教師のみ作成可能。
         * @summary Create Announcement
         * @param {AnnouncementCreate} [announcementCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnSchoolAnnouncement: async (announcementCreate?: AnnouncementCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 連絡の削除
         * @summary Delete Announcement
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncement: async (announcementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('deleteAnnouncement', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校の連絡詳細の取得。ただし、今は一覧表示と同じ物が返る。
         * @summary Get Announcement Detail
         * @param {string} announcementId 
         * @param {AnnouncementSimpleRead} [announcementSimpleRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement: async (announcementId: string, announcementSimpleRead?: AnnouncementSimpleRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('getAnnouncement', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementSimpleRead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分の学校の連絡を取得。 教師は、予約投稿も取得。
         * @summary Get Announcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolAnnouncements: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 連絡を見たことを記録
         * @summary Register Announcement View
         * @param {string} announcementId 
         * @param {InlineObject15} [inlineObject15] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAnnouncementView: async (announcementId: string, inlineObject15?: InlineObject15, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('registerAnnouncementView', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}/views`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 連絡の更新
         * @summary Update Announcement
         * @param {string} announcementId 
         * @param {AnnouncementCreate} [announcementCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncement: async (announcementId: string, announcementCreate?: AnnouncementCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('updateAnnouncement', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsApi - functional programming interface
 * @export
 */
export const AnnouncementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change Announcement File Orders or Delete File
         * @param {string} announcementId 
         * @param {InlineObject17} [inlineObject17] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAnnouncementFileOrdersOrDeleteFile(announcementId: string, inlineObject17?: InlineObject17, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAnnouncementFileOrdersOrDeleteFile(announcementId, inlineObject17, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校の連絡を作成。 今は教師のみ作成可能。
         * @summary Create Announcement
         * @param {AnnouncementCreate} [announcementCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOwnSchoolAnnouncement(announcementCreate?: AnnouncementCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOwnSchoolAnnouncement(announcementCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 連絡の削除
         * @summary Delete Announcement
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnouncement(announcementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnouncement(announcementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校の連絡詳細の取得。ただし、今は一覧表示と同じ物が返る。
         * @summary Get Announcement Detail
         * @param {string} announcementId 
         * @param {AnnouncementSimpleRead} [announcementSimpleRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncement(announcementId: string, announcementSimpleRead?: AnnouncementSimpleRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncement(announcementId, announcementSimpleRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分の学校の連絡を取得。 教師は、予約投稿も取得。
         * @summary Get Announcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnSchoolAnnouncements(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnSchoolAnnouncements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 連絡を見たことを記録
         * @summary Register Announcement View
         * @param {string} announcementId 
         * @param {InlineObject15} [inlineObject15] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAnnouncementView(announcementId: string, inlineObject15?: InlineObject15, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAnnouncementView(announcementId, inlineObject15, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 連絡の更新
         * @summary Update Announcement
         * @param {string} announcementId 
         * @param {AnnouncementCreate} [announcementCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnouncement(announcementId: string, announcementCreate?: AnnouncementCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnouncement(announcementId, announcementCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsApi - factory interface
 * @export
 */
export const AnnouncementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsApiFp(configuration)
    return {
        /**
         * 
         * @summary Change Announcement File Orders or Delete File
         * @param {string} announcementId 
         * @param {InlineObject17} [inlineObject17] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAnnouncementFileOrdersOrDeleteFile(announcementId: string, inlineObject17?: InlineObject17, options?: any): AxiosPromise<void> {
            return localVarFp.changeAnnouncementFileOrdersOrDeleteFile(announcementId, inlineObject17, options).then((request) => request(axios, basePath));
        },
        /**
         * 学校の連絡を作成。 今は教師のみ作成可能。
         * @summary Create Announcement
         * @param {AnnouncementCreate} [announcementCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnSchoolAnnouncement(announcementCreate?: AnnouncementCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createOwnSchoolAnnouncement(announcementCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 連絡の削除
         * @summary Delete Announcement
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncement(announcementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnouncement(announcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 学校の連絡詳細の取得。ただし、今は一覧表示と同じ物が返る。
         * @summary Get Announcement Detail
         * @param {string} announcementId 
         * @param {AnnouncementSimpleRead} [announcementSimpleRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement(announcementId: string, announcementSimpleRead?: AnnouncementSimpleRead, options?: any): AxiosPromise<void> {
            return localVarFp.getAnnouncement(announcementId, announcementSimpleRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分の学校の連絡を取得。 教師は、予約投稿も取得。
         * @summary Get Announcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolAnnouncements(options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.getOwnSchoolAnnouncements(options).then((request) => request(axios, basePath));
        },
        /**
         * 連絡を見たことを記録
         * @summary Register Announcement View
         * @param {string} announcementId 
         * @param {InlineObject15} [inlineObject15] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAnnouncementView(announcementId: string, inlineObject15?: InlineObject15, options?: any): AxiosPromise<object> {
            return localVarFp.registerAnnouncementView(announcementId, inlineObject15, options).then((request) => request(axios, basePath));
        },
        /**
         * 連絡の更新
         * @summary Update Announcement
         * @param {string} announcementId 
         * @param {AnnouncementCreate} [announcementCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncement(announcementId: string, announcementCreate?: AnnouncementCreate, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnnouncement(announcementId, announcementCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsApi - object-oriented interface
 * @export
 * @class AnnouncementsApi
 * @extends {BaseAPI}
 */
export class AnnouncementsApi extends BaseAPI {
    /**
     * 
     * @summary Change Announcement File Orders or Delete File
     * @param {string} announcementId 
     * @param {InlineObject17} [inlineObject17] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public changeAnnouncementFileOrdersOrDeleteFile(announcementId: string, inlineObject17?: InlineObject17, options?: any) {
        return AnnouncementsApiFp(this.configuration).changeAnnouncementFileOrdersOrDeleteFile(announcementId, inlineObject17, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校の連絡を作成。 今は教師のみ作成可能。
     * @summary Create Announcement
     * @param {AnnouncementCreate} [announcementCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public createOwnSchoolAnnouncement(announcementCreate?: AnnouncementCreate, options?: any) {
        return AnnouncementsApiFp(this.configuration).createOwnSchoolAnnouncement(announcementCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 連絡の削除
     * @summary Delete Announcement
     * @param {string} announcementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public deleteAnnouncement(announcementId: string, options?: any) {
        return AnnouncementsApiFp(this.configuration).deleteAnnouncement(announcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校の連絡詳細の取得。ただし、今は一覧表示と同じ物が返る。
     * @summary Get Announcement Detail
     * @param {string} announcementId 
     * @param {AnnouncementSimpleRead} [announcementSimpleRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public getAnnouncement(announcementId: string, announcementSimpleRead?: AnnouncementSimpleRead, options?: any) {
        return AnnouncementsApiFp(this.configuration).getAnnouncement(announcementId, announcementSimpleRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分の学校の連絡を取得。 教師は、予約投稿も取得。
     * @summary Get Announcements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public getOwnSchoolAnnouncements(options?: any) {
        return AnnouncementsApiFp(this.configuration).getOwnSchoolAnnouncements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 連絡を見たことを記録
     * @summary Register Announcement View
     * @param {string} announcementId 
     * @param {InlineObject15} [inlineObject15] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public registerAnnouncementView(announcementId: string, inlineObject15?: InlineObject15, options?: any) {
        return AnnouncementsApiFp(this.configuration).registerAnnouncementView(announcementId, inlineObject15, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 連絡の更新
     * @summary Update Announcement
     * @param {string} announcementId 
     * @param {AnnouncementCreate} [announcementCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsApi
     */
    public updateAnnouncement(announcementId: string, announcementCreate?: AnnouncementCreate, options?: any) {
        return AnnouncementsApiFp(this.configuration).updateAnnouncement(announcementId, announcementCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CanvasesApi - axios parameter creator
 * @export
 */
export const CanvasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * キャンバスをアップロード
         * @summary Create CavasMaterial
         * @param {CanvasCreate} [canvasCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvas: async (canvasCreate?: CanvasCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/canvases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvasCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスの素材をアップロード
         * @summary Create CavasMaterial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvasMaterials: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/canvas_materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスの削除
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCanvas: async (canvasId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('deleteCanvas', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TemporaryCnavasInputFileの削除
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryCanvasInputFile: async (fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteTemporaryCanvasInputFile', 'fileId', fileId)
            const localVarPath = `/api/v1/temporary_canvas_input_files/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate Canvas
         * @param {string} canvasId 
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateCanvas: async (canvasId: string, inlineObject20?: InlineObject20, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('duplicateCanvas', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate Canvas
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateCanvasForPost: async (courseId: string, canvasId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('duplicateCanvasForPost', 'courseId', courseId)
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('duplicateCanvasForPost', 'canvasId', canvasId)
            const localVarPath = `/api/v1/courses/{course_id}/canvases/{canvas_id}/duplicate_for_post`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスを取得
         * @summary Get Canvas
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvas: async (canvasId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('getCanvas', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Canvasの入力欄の一覧取得
         * @summary Get Canvas Inputs
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvasInputs: async (canvasId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('getCanvasInputs', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/inputs`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 共同編集のグループが未作成なら作成してCanvasのを取得、作成済みならそのままCanvasを取得。
         * @summary Get Or Create Group Canvas
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {InlineObject21} [inlineObject21] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateGroupCanvas: async (courseId: string, canvasId: string, inlineObject21?: InlineObject21, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getOrCreateGroupCanvas', 'courseId', courseId)
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('getOrCreateGroupCanvas', 'canvasId', canvasId)
            const localVarPath = `/api/v1/courses/{course_id}/canvases/{canvas_id}/groups`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject21, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join Cavas Collab Group
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinCanvasCollabGroup: async (canvasId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('joinCanvasCollabGroup', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/group/join`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Leave Cavas Collab Group
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveCanvasCollabGroup: async (canvasId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('leaveCanvasCollabGroup', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/group/leave`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * JSONにあってとRDBにない入力欄が万が一あった場合に、RDBに足りないレコードを挿入する処理。不整合を正す目的なので認証ユーザーなら誰でも操作可能。
         * @summary Sync Canvas Inputs
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCanvasInputs: async (canvasId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('syncCanvasInputs', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/inputs/sync`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスの編集保存
         * @summary Update Canvas
         * @param {string} canvasId 
         * @param {CanvasUpdate} [canvasUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvas: async (canvasId: string, canvasUpdate?: CanvasUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('updateCanvas', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvasUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Canvas Input Answer with feedback
         * @param {string} answerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvasInputAnswerWithFeedback: async (answerId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerId' is not null or undefined
            assertParamExists('updateCanvasInputAnswerWithFeedback', 'answerId', answerId)
            const localVarPath = `/api/v1/canvas_input_answers/{answer_id}/stream`
                .replace(`{${"answer_id"}}`, encodeURIComponent(String(answerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスの入力欄の更新
         * @summary Update Canvas Inputs
         * @param {string} canvasId 
         * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvasInputs: async (canvasId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('updateCanvasInputs', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/inputs`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスのレイヤーリストの更新
         * @summary Update Canvas Layer List
         * @param {string} canvasId 
         * @param {CanvasLayerListUpdate} [canvasLayerListUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvasLayerList: async (canvasId: string, canvasLayerListUpdate?: CanvasLayerListUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('updateCanvasLayerList', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/layer_list`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvasLayerListUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスのリアルタイムでの更新を行う
         * @summary Update Editing Canvas
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditingCanvas: async (canvasId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('updateEditingCanvas', 'canvasId', canvasId)
            const localVarPath = `/api/v1/editing_canvases/{canvas_id}`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンバスの共同編集者の更新
         * @summary Update Users With Edit Permission
         * @param {string} canvasId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsersWithEditPermission: async (canvasId: string, requestBody?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('updateUsersWithEditPermission', 'canvasId', canvasId)
            const localVarPath = `/api/v1/canvases/{canvas_id}/users_with_edit_permission`
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CanvasesApi - functional programming interface
 * @export
 */
export const CanvasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CanvasesApiAxiosParamCreator(configuration)
    return {
        /**
         * キャンバスをアップロード
         * @summary Create CavasMaterial
         * @param {CanvasCreate} [canvasCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanvas(canvasCreate?: CanvasCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanvas(canvasCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスの素材をアップロード
         * @summary Create CavasMaterial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanvasMaterials(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvasMaterialRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanvasMaterials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスの削除
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCanvas(canvasId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCanvas(canvasId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TemporaryCnavasInputFileの削除
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemporaryCanvasInputFile(fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemporaryCanvasInputFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate Canvas
         * @param {string} canvasId 
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateCanvas(canvasId: string, inlineObject20?: InlineObject20, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvasWithUsingCourse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateCanvas(canvasId, inlineObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Duplicate Canvas
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateCanvasForPost(courseId: string, canvasId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvasWithUsingCourse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateCanvasForPost(courseId, canvasId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスを取得
         * @summary Get Canvas
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvas(canvasId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanvasMaterialRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvas(canvasId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Canvasの入力欄の一覧取得
         * @summary Get Canvas Inputs
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvasInputs(canvasId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanvasInputRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvasInputs(canvasId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 共同編集のグループが未作成なら作成してCanvasのを取得、作成済みならそのままCanvasを取得。
         * @summary Get Or Create Group Canvas
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {InlineObject21} [inlineObject21] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrCreateGroupCanvas(courseId: string, canvasId: string, inlineObject21?: InlineObject21, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseOwnEditingCanvas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrCreateGroupCanvas(courseId, canvasId, inlineObject21, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Join Cavas Collab Group
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinCanvasCollabGroup(canvasId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinCanvasCollabGroup(canvasId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Leave Cavas Collab Group
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaveCanvasCollabGroup(canvasId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaveCanvasCollabGroup(canvasId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * JSONにあってとRDBにない入力欄が万が一あった場合に、RDBに足りないレコードを挿入する処理。不整合を正す目的なので認証ユーザーなら誰でも操作可能。
         * @summary Sync Canvas Inputs
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCanvasInputs(canvasId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCanvasInputs(canvasId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスの編集保存
         * @summary Update Canvas
         * @param {string} canvasId 
         * @param {CanvasUpdate} [canvasUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanvas(canvasId: string, canvasUpdate?: CanvasUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanvas(canvasId, canvasUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Canvas Input Answer with feedback
         * @param {string} answerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanvasInputAnswerWithFeedback(answerId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanvasInputAnswerWithFeedback(answerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスの入力欄の更新
         * @summary Update Canvas Inputs
         * @param {string} canvasId 
         * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanvasInputs(canvasId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanvasInputs(canvasId, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスのレイヤーリストの更新
         * @summary Update Canvas Layer List
         * @param {string} canvasId 
         * @param {CanvasLayerListUpdate} [canvasLayerListUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanvasLayerList(canvasId: string, canvasLayerListUpdate?: CanvasLayerListUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanvasLayerList(canvasId, canvasLayerListUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスのリアルタイムでの更新を行う
         * @summary Update Editing Canvas
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEditingCanvas(canvasId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEditingCanvas(canvasId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンバスの共同編集者の更新
         * @summary Update Users With Edit Permission
         * @param {string} canvasId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUsersWithEditPermission(canvasId: string, requestBody?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsersWithEditPermission(canvasId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CanvasesApi - factory interface
 * @export
 */
export const CanvasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CanvasesApiFp(configuration)
    return {
        /**
         * キャンバスをアップロード
         * @summary Create CavasMaterial
         * @param {CanvasCreate} [canvasCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvas(canvasCreate?: CanvasCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createCanvas(canvasCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスの素材をアップロード
         * @summary Create CavasMaterial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvasMaterials(options?: any): AxiosPromise<CanvasMaterialRead> {
            return localVarFp.createCanvasMaterials(options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスの削除
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCanvas(canvasId: string, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.deleteCanvas(canvasId, options).then((request) => request(axios, basePath));
        },
        /**
         * TemporaryCnavasInputFileの削除
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryCanvasInputFile(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemporaryCanvasInputFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate Canvas
         * @param {string} canvasId 
         * @param {InlineObject20} [inlineObject20] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateCanvas(canvasId: string, inlineObject20?: InlineObject20, options?: any): AxiosPromise<CanvasWithUsingCourse> {
            return localVarFp.duplicateCanvas(canvasId, inlineObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate Canvas
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateCanvasForPost(courseId: string, canvasId: string, options?: any): AxiosPromise<CanvasWithUsingCourse> {
            return localVarFp.duplicateCanvasForPost(courseId, canvasId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスを取得
         * @summary Get Canvas
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvas(canvasId: string, options?: any): AxiosPromise<Array<CanvasMaterialRead>> {
            return localVarFp.getCanvas(canvasId, options).then((request) => request(axios, basePath));
        },
        /**
         * Canvasの入力欄の一覧取得
         * @summary Get Canvas Inputs
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvasInputs(canvasId: string, options?: any): AxiosPromise<Array<CanvasInputRead>> {
            return localVarFp.getCanvasInputs(canvasId, options).then((request) => request(axios, basePath));
        },
        /**
         * 共同編集のグループが未作成なら作成してCanvasのを取得、作成済みならそのままCanvasを取得。
         * @summary Get Or Create Group Canvas
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {InlineObject21} [inlineObject21] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreateGroupCanvas(courseId: string, canvasId: string, inlineObject21?: InlineObject21, options?: any): AxiosPromise<CourseOwnEditingCanvas> {
            return localVarFp.getOrCreateGroupCanvas(courseId, canvasId, inlineObject21, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Join Cavas Collab Group
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinCanvasCollabGroup(canvasId: string, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.joinCanvasCollabGroup(canvasId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Leave Cavas Collab Group
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveCanvasCollabGroup(canvasId: string, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.leaveCanvasCollabGroup(canvasId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * JSONにあってとRDBにない入力欄が万が一あった場合に、RDBに足りないレコードを挿入する処理。不整合を正す目的なので認証ユーザーなら誰でも操作可能。
         * @summary Sync Canvas Inputs
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCanvasInputs(canvasId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.syncCanvasInputs(canvasId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスの編集保存
         * @summary Update Canvas
         * @param {string} canvasId 
         * @param {CanvasUpdate} [canvasUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvas(canvasId: string, canvasUpdate?: CanvasUpdate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.updateCanvas(canvasId, canvasUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Canvas Input Answer with feedback
         * @param {string} answerId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvasInputAnswerWithFeedback(answerId: string, body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.updateCanvasInputAnswerWithFeedback(answerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスの入力欄の更新
         * @summary Update Canvas Inputs
         * @param {string} canvasId 
         * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvasInputs(canvasId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options?: any): AxiosPromise<void> {
            return localVarFp.updateCanvasInputs(canvasId, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスのレイヤーリストの更新
         * @summary Update Canvas Layer List
         * @param {string} canvasId 
         * @param {CanvasLayerListUpdate} [canvasLayerListUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvasLayerList(canvasId: string, canvasLayerListUpdate?: CanvasLayerListUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateCanvasLayerList(canvasId, canvasLayerListUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスのリアルタイムでの更新を行う
         * @summary Update Editing Canvas
         * @param {string} canvasId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditingCanvas(canvasId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.updateEditingCanvas(canvasId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンバスの共同編集者の更新
         * @summary Update Users With Edit Permission
         * @param {string} canvasId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsersWithEditPermission(canvasId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.updateUsersWithEditPermission(canvasId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CanvasesApi - object-oriented interface
 * @export
 * @class CanvasesApi
 * @extends {BaseAPI}
 */
export class CanvasesApi extends BaseAPI {
    /**
     * キャンバスをアップロード
     * @summary Create CavasMaterial
     * @param {CanvasCreate} [canvasCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public createCanvas(canvasCreate?: CanvasCreate, options?: any) {
        return CanvasesApiFp(this.configuration).createCanvas(canvasCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスの素材をアップロード
     * @summary Create CavasMaterial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public createCanvasMaterials(options?: any) {
        return CanvasesApiFp(this.configuration).createCanvasMaterials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスの削除
     * @param {string} canvasId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public deleteCanvas(canvasId: string, options?: any) {
        return CanvasesApiFp(this.configuration).deleteCanvas(canvasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TemporaryCnavasInputFileの削除
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public deleteTemporaryCanvasInputFile(fileId: string, options?: any) {
        return CanvasesApiFp(this.configuration).deleteTemporaryCanvasInputFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate Canvas
     * @param {string} canvasId 
     * @param {InlineObject20} [inlineObject20] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public duplicateCanvas(canvasId: string, inlineObject20?: InlineObject20, options?: any) {
        return CanvasesApiFp(this.configuration).duplicateCanvas(canvasId, inlineObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate Canvas
     * @param {string} courseId 
     * @param {string} canvasId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public duplicateCanvasForPost(courseId: string, canvasId: string, options?: any) {
        return CanvasesApiFp(this.configuration).duplicateCanvasForPost(courseId, canvasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスを取得
     * @summary Get Canvas
     * @param {string} canvasId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public getCanvas(canvasId: string, options?: any) {
        return CanvasesApiFp(this.configuration).getCanvas(canvasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Canvasの入力欄の一覧取得
     * @summary Get Canvas Inputs
     * @param {string} canvasId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public getCanvasInputs(canvasId: string, options?: any) {
        return CanvasesApiFp(this.configuration).getCanvasInputs(canvasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 共同編集のグループが未作成なら作成してCanvasのを取得、作成済みならそのままCanvasを取得。
     * @summary Get Or Create Group Canvas
     * @param {string} courseId 
     * @param {string} canvasId 
     * @param {InlineObject21} [inlineObject21] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public getOrCreateGroupCanvas(courseId: string, canvasId: string, inlineObject21?: InlineObject21, options?: any) {
        return CanvasesApiFp(this.configuration).getOrCreateGroupCanvas(courseId, canvasId, inlineObject21, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Join Cavas Collab Group
     * @param {string} canvasId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public joinCanvasCollabGroup(canvasId: string, body?: object, options?: any) {
        return CanvasesApiFp(this.configuration).joinCanvasCollabGroup(canvasId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Leave Cavas Collab Group
     * @param {string} canvasId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public leaveCanvasCollabGroup(canvasId: string, body?: object, options?: any) {
        return CanvasesApiFp(this.configuration).leaveCanvasCollabGroup(canvasId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * JSONにあってとRDBにない入力欄が万が一あった場合に、RDBに足りないレコードを挿入する処理。不整合を正す目的なので認証ユーザーなら誰でも操作可能。
     * @summary Sync Canvas Inputs
     * @param {string} canvasId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public syncCanvasInputs(canvasId: string, body?: object, options?: any) {
        return CanvasesApiFp(this.configuration).syncCanvasInputs(canvasId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスの編集保存
     * @summary Update Canvas
     * @param {string} canvasId 
     * @param {CanvasUpdate} [canvasUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public updateCanvas(canvasId: string, canvasUpdate?: CanvasUpdate, options?: any) {
        return CanvasesApiFp(this.configuration).updateCanvas(canvasId, canvasUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Canvas Input Answer with feedback
     * @param {string} answerId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public updateCanvasInputAnswerWithFeedback(answerId: string, body?: object, options?: any) {
        return CanvasesApiFp(this.configuration).updateCanvasInputAnswerWithFeedback(answerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスの入力欄の更新
     * @summary Update Canvas Inputs
     * @param {string} canvasId 
     * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public updateCanvasInputs(canvasId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options?: any) {
        return CanvasesApiFp(this.configuration).updateCanvasInputs(canvasId, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスのレイヤーリストの更新
     * @summary Update Canvas Layer List
     * @param {string} canvasId 
     * @param {CanvasLayerListUpdate} [canvasLayerListUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public updateCanvasLayerList(canvasId: string, canvasLayerListUpdate?: CanvasLayerListUpdate, options?: any) {
        return CanvasesApiFp(this.configuration).updateCanvasLayerList(canvasId, canvasLayerListUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスのリアルタイムでの更新を行う
     * @summary Update Editing Canvas
     * @param {string} canvasId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public updateEditingCanvas(canvasId: string, body?: object, options?: any) {
        return CanvasesApiFp(this.configuration).updateEditingCanvas(canvasId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンバスの共同編集者の更新
     * @summary Update Users With Edit Permission
     * @param {string} canvasId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvasesApi
     */
    public updateUsersWithEditPermission(canvasId: string, requestBody?: Array<string>, options?: any) {
        return CanvasesApiFp(this.configuration).updateUsersWithEditPermission(canvasId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClassroomsApi - axios parameter creator
 * @export
 */
export const ClassroomsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 生徒のクラスへの参加を承認する
         * @summary Approve a Student to Join Classroom
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveStudentClassroomJoin: async (classroomId: string, studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('approveStudentClassroomJoin', 'classroomId', classroomId)
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('approveStudentClassroomJoin', 'studentId', studentId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students/{student_id}/approve_join`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)))
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * すでに一人以上の児童と紐づいている保護者が追加で児童と紐づけるリクエストを作成
         * @summary Create Additional Patron Request
         * @param {AdditionalPatronRequestCreate} [additionalPatronRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdditionalPatronRequest: async (additionalPatronRequestCreate?: AdditionalPatronRequestCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/classrooms/patron_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalPatronRequestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの作成。
         * @summary Create a Classroom
         * @param {ClassroomCreate} classroomCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassroom: async (classroomCreate: ClassroomCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomCreate' is not null or undefined
            assertParamExists('createClassroom', 'classroomCreate', classroomCreate)
            const localVarPath = `/api/v1/schools/me/classrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classroomCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの削除。そのクラスの担任/副担任のみ実行可能
         * @param {string} classroomId クラスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassroom: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('deleteClassroom', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したクラスの情報の取得。クラスの担任(main_teachers)か副担任(sub_teachers)のみ閲覧可能。
         * @summary Get a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroom: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('getClassroom', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校間の合同授業を作成または更新する際に、学校の外部のクラスを指定するためにフェッチするエンドポイント
         * @summary Get Classroom By ID For External School
         * @param {string} idForExternalSchool 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomByIdForExternalSchool: async (idForExternalSchool: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'idForExternalSchool' is not null or undefined
            assertParamExists('getClassroomByIdForExternalSchool', 'idForExternalSchool', idForExternalSchool)
            const localVarPath = `/api/v1/classrooms/id_for_external_school/{id_for_external_school}`
                .replace(`{${"id_for_external_school"}}`, encodeURIComponent(String(idForExternalSchool)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したClassroomのJoin Codeを取得。その授業の教員のみ閲覧可能。
         * @summary Get a Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomJoinCode: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('getClassroomJoinCode', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/join_code`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したClassroomのPatron Join Codeを取得。その授業の教員のみ閲覧可能。idはクラスのid
         * @summary Get a Patron Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomPatronJoinCode: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('getClassroomPatronJoinCode', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/patron_join_code`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のクラス一覧を取得 教師: 自分が担当/副担当している/いたのクラス 生徒: 自分が所属している/いたクラス 保護者: 自分の監督する生徒が所属している/いたクラス 年度で絞り込もう
         * @summary Your GET endpoint
         * @param {number} [nendo] 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnClassrooms: async (nendo?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/classrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nendo !== undefined) {
                localVarQueryParameter['nendo'] = nendo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身の学校のクラス一覧。学校の教員のみ閲覧可能。 年度で絞り込むべし
         * @summary Get Classrooms of my School
         * @param {number} [nendo] 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolClassrooms: async (nendo?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/classrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nendo !== undefined) {
                localVarQueryParameter['nendo'] = nendo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [保護者用] 自分の子供の現在のクラス一覧
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnStudentsClassrooms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patrons/me/students/classrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 本人のクラスへの未処理の参加リクエストを取得する。参加登録フロー中に中断があったとき用。
         * @summary Get Pending Classroom Join Request By Student
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingClassroomJoinRequest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/pending_classroom_join_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 参加コードのリセット。新しい値はサーバーで自動生成するので、何も送らない。
         * @summary Reset a Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetJoinCode: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('resetJoinCode', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/join_code`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したクラスの一部更新(学校は変えられない)。クラスの担任(main_teachers)か副担任(sub_teachers)のみ更新可能。
         * @summary Update a Classroom
         * @param {string} classroomId 
         * @param {ClassroomUpdate} [classroomUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassroom: async (classroomId: string, classroomUpdate?: ClassroomUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('updateClassroom', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classroomUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
         * @summary Validate Classroom JoinCode
         * @param {JoinCodeCheck} [joinCodeCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassroomJoinCode: async (joinCodeCheck?: JoinCodeCheck, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/classrooms/join_codes/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinCodeCheck, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの保護者参加コードからクラス情報を得る
         * @summary Validate Classroom Patron Join Code
         * @param {InlineObject14} [inlineObject14] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassroomPatronJoinCode: async (inlineObject14?: InlineObject14, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/classrooms/patron_join_codes/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassroomsApi - functional programming interface
 * @export
 */
export const ClassroomsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassroomsApiAxiosParamCreator(configuration)
    return {
        /**
         * 生徒のクラスへの参加を承認する
         * @summary Approve a Student to Join Classroom
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveStudentClassroomJoin(classroomId: string, studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveStudentClassroomJoin(classroomId, studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * すでに一人以上の児童と紐づいている保護者が追加で児童と紐づけるリクエストを作成
         * @summary Create Additional Patron Request
         * @param {AdditionalPatronRequestCreate} [additionalPatronRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdditionalPatronRequest(additionalPatronRequestCreate?: AdditionalPatronRequestCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdditionalPatronRequest(additionalPatronRequestCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの作成。
         * @summary Create a Classroom
         * @param {ClassroomCreate} classroomCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClassroom(classroomCreate: ClassroomCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClassroom(classroomCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの削除。そのクラスの担任/副担任のみ実行可能
         * @param {string} classroomId クラスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClassroom(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClassroom(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したクラスの情報の取得。クラスの担任(main_teachers)か副担任(sub_teachers)のみ閲覧可能。
         * @summary Get a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroom(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroom(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校間の合同授業を作成または更新する際に、学校の外部のクラスを指定するためにフェッチするエンドポイント
         * @summary Get Classroom By ID For External School
         * @param {string} idForExternalSchool 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroomByIdForExternalSchool(idForExternalSchool: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomSimpleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroomByIdForExternalSchool(idForExternalSchool, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したClassroomのJoin Codeを取得。その授業の教員のみ閲覧可能。
         * @summary Get a Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroomJoinCode(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassroomJoinCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroomJoinCode(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したClassroomのPatron Join Codeを取得。その授業の教員のみ閲覧可能。idはクラスのid
         * @summary Get a Patron Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroomPatronJoinCode(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroomPatronJoinCode(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のクラス一覧を取得 教師: 自分が担当/副担当している/いたのクラス 生徒: 自分が所属している/いたクラス 保護者: 自分の監督する生徒が所属している/いたクラス 年度で絞り込もう
         * @summary Your GET endpoint
         * @param {number} [nendo] 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnClassrooms(nendo?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeClassroomSimpleRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnClassrooms(nendo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身の学校のクラス一覧。学校の教員のみ閲覧可能。 年度で絞り込むべし
         * @summary Get Classrooms of my School
         * @param {number} [nendo] 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnSchoolClassrooms(nendo?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeClassroomRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnSchoolClassrooms(nendo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * [保護者用] 自分の子供の現在のクラス一覧
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnStudentsClassrooms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeClassroomSimpleRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnStudentsClassrooms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 本人のクラスへの未処理の参加リクエストを取得する。参加登録フロー中に中断があったとき用。
         * @summary Get Pending Classroom Join Request By Student
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingClassroomJoinRequest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingClassroomJoinRequest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 参加コードのリセット。新しい値はサーバーで自動生成するので、何も送らない。
         * @summary Reset a Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetJoinCode(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassroomJoinCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetJoinCode(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したクラスの一部更新(学校は変えられない)。クラスの担任(main_teachers)か副担任(sub_teachers)のみ更新可能。
         * @summary Update a Classroom
         * @param {string} classroomId 
         * @param {ClassroomUpdate} [classroomUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClassroom(classroomId: string, classroomUpdate?: ClassroomUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClassroom(classroomId, classroomUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
         * @summary Validate Classroom JoinCode
         * @param {JoinCodeCheck} [joinCodeCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateClassroomJoinCode(joinCodeCheck?: JoinCodeCheck, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateClassroomJoinCode(joinCodeCheck, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの保護者参加コードからクラス情報を得る
         * @summary Validate Classroom Patron Join Code
         * @param {InlineObject14} [inlineObject14] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateClassroomPatronJoinCode(inlineObject14?: InlineObject14, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomSimpleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateClassroomPatronJoinCode(inlineObject14, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClassroomsApi - factory interface
 * @export
 */
export const ClassroomsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassroomsApiFp(configuration)
    return {
        /**
         * 生徒のクラスへの参加を承認する
         * @summary Approve a Student to Join Classroom
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveStudentClassroomJoin(classroomId: string, studentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveStudentClassroomJoin(classroomId, studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * すでに一人以上の児童と紐づいている保護者が追加で児童と紐づけるリクエストを作成
         * @summary Create Additional Patron Request
         * @param {AdditionalPatronRequestCreate} [additionalPatronRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdditionalPatronRequest(additionalPatronRequestCreate?: AdditionalPatronRequestCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createAdditionalPatronRequest(additionalPatronRequestCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの作成。
         * @summary Create a Classroom
         * @param {ClassroomCreate} classroomCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassroom(classroomCreate: ClassroomCreate, options?: any): AxiosPromise<HomeClassroomRead> {
            return localVarFp.createClassroom(classroomCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの削除。そのクラスの担任/副担任のみ実行可能
         * @param {string} classroomId クラスID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassroom(classroomId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClassroom(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したクラスの情報の取得。クラスの担任(main_teachers)か副担任(sub_teachers)のみ閲覧可能。
         * @summary Get a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroom(classroomId: string, options?: any): AxiosPromise<HomeClassroomRead> {
            return localVarFp.getClassroom(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 学校間の合同授業を作成または更新する際に、学校の外部のクラスを指定するためにフェッチするエンドポイント
         * @summary Get Classroom By ID For External School
         * @param {string} idForExternalSchool 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomByIdForExternalSchool(idForExternalSchool: string, options?: any): AxiosPromise<HomeClassroomSimpleRead> {
            return localVarFp.getClassroomByIdForExternalSchool(idForExternalSchool, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したClassroomのJoin Codeを取得。その授業の教員のみ閲覧可能。
         * @summary Get a Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomJoinCode(classroomId: string, options?: any): AxiosPromise<ClassroomJoinCode> {
            return localVarFp.getClassroomJoinCode(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したClassroomのPatron Join Codeを取得。その授業の教員のみ閲覧可能。idはクラスのid
         * @summary Get a Patron Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomPatronJoinCode(classroomId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.getClassroomPatronJoinCode(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のクラス一覧を取得 教師: 自分が担当/副担当している/いたのクラス 生徒: 自分が所属している/いたクラス 保護者: 自分の監督する生徒が所属している/いたクラス 年度で絞り込もう
         * @summary Your GET endpoint
         * @param {number} [nendo] 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnClassrooms(nendo?: number, options?: any): AxiosPromise<Array<HomeClassroomSimpleRead>> {
            return localVarFp.getOwnClassrooms(nendo, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身の学校のクラス一覧。学校の教員のみ閲覧可能。 年度で絞り込むべし
         * @summary Get Classrooms of my School
         * @param {number} [nendo] 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolClassrooms(nendo?: number, options?: any): AxiosPromise<Array<HomeClassroomRead>> {
            return localVarFp.getOwnSchoolClassrooms(nendo, options).then((request) => request(axios, basePath));
        },
        /**
         * [保護者用] 自分の子供の現在のクラス一覧
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnStudentsClassrooms(options?: any): AxiosPromise<Array<HomeClassroomSimpleRead>> {
            return localVarFp.getOwnStudentsClassrooms(options).then((request) => request(axios, basePath));
        },
        /**
         * 本人のクラスへの未処理の参加リクエストを取得する。参加登録フロー中に中断があったとき用。
         * @summary Get Pending Classroom Join Request By Student
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingClassroomJoinRequest(options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.getPendingClassroomJoinRequest(options).then((request) => request(axios, basePath));
        },
        /**
         * 参加コードのリセット。新しい値はサーバーで自動生成するので、何も送らない。
         * @summary Reset a Join Code of a Classroom
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetJoinCode(classroomId: string, options?: any): AxiosPromise<ClassroomJoinCode> {
            return localVarFp.resetJoinCode(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したクラスの一部更新(学校は変えられない)。クラスの担任(main_teachers)か副担任(sub_teachers)のみ更新可能。
         * @summary Update a Classroom
         * @param {string} classroomId 
         * @param {ClassroomUpdate} [classroomUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClassroom(classroomId: string, classroomUpdate?: ClassroomUpdate, options?: any): AxiosPromise<HomeClassroomRead> {
            return localVarFp.updateClassroom(classroomId, classroomUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
         * @summary Validate Classroom JoinCode
         * @param {JoinCodeCheck} [joinCodeCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassroomJoinCode(joinCodeCheck?: JoinCodeCheck, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.validateClassroomJoinCode(joinCodeCheck, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの保護者参加コードからクラス情報を得る
         * @summary Validate Classroom Patron Join Code
         * @param {InlineObject14} [inlineObject14] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClassroomPatronJoinCode(inlineObject14?: InlineObject14, options?: any): AxiosPromise<HomeClassroomSimpleRead> {
            return localVarFp.validateClassroomPatronJoinCode(inlineObject14, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassroomsApi - object-oriented interface
 * @export
 * @class ClassroomsApi
 * @extends {BaseAPI}
 */
export class ClassroomsApi extends BaseAPI {
    /**
     * 生徒のクラスへの参加を承認する
     * @summary Approve a Student to Join Classroom
     * @param {string} classroomId 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public approveStudentClassroomJoin(classroomId: string, studentId: string, options?: any) {
        return ClassroomsApiFp(this.configuration).approveStudentClassroomJoin(classroomId, studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * すでに一人以上の児童と紐づいている保護者が追加で児童と紐づけるリクエストを作成
     * @summary Create Additional Patron Request
     * @param {AdditionalPatronRequestCreate} [additionalPatronRequestCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public createAdditionalPatronRequest(additionalPatronRequestCreate?: AdditionalPatronRequestCreate, options?: any) {
        return ClassroomsApiFp(this.configuration).createAdditionalPatronRequest(additionalPatronRequestCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの作成。
     * @summary Create a Classroom
     * @param {ClassroomCreate} classroomCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public createClassroom(classroomCreate: ClassroomCreate, options?: any) {
        return ClassroomsApiFp(this.configuration).createClassroom(classroomCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの削除。そのクラスの担任/副担任のみ実行可能
     * @param {string} classroomId クラスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public deleteClassroom(classroomId: string, options?: any) {
        return ClassroomsApiFp(this.configuration).deleteClassroom(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したクラスの情報の取得。クラスの担任(main_teachers)か副担任(sub_teachers)のみ閲覧可能。
     * @summary Get a Classroom
     * @param {string} classroomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getClassroom(classroomId: string, options?: any) {
        return ClassroomsApiFp(this.configuration).getClassroom(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校間の合同授業を作成または更新する際に、学校の外部のクラスを指定するためにフェッチするエンドポイント
     * @summary Get Classroom By ID For External School
     * @param {string} idForExternalSchool 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getClassroomByIdForExternalSchool(idForExternalSchool: string, options?: any) {
        return ClassroomsApiFp(this.configuration).getClassroomByIdForExternalSchool(idForExternalSchool, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したClassroomのJoin Codeを取得。その授業の教員のみ閲覧可能。
     * @summary Get a Join Code of a Classroom
     * @param {string} classroomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getClassroomJoinCode(classroomId: string, options?: any) {
        return ClassroomsApiFp(this.configuration).getClassroomJoinCode(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したClassroomのPatron Join Codeを取得。その授業の教員のみ閲覧可能。idはクラスのid
     * @summary Get a Patron Join Code of a Classroom
     * @param {string} classroomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getClassroomPatronJoinCode(classroomId: string, options?: any) {
        return ClassroomsApiFp(this.configuration).getClassroomPatronJoinCode(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分のクラス一覧を取得 教師: 自分が担当/副担当している/いたのクラス 生徒: 自分が所属している/いたクラス 保護者: 自分の監督する生徒が所属している/いたクラス 年度で絞り込もう
     * @summary Your GET endpoint
     * @param {number} [nendo] 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getOwnClassrooms(nendo?: number, options?: any) {
        return ClassroomsApiFp(this.configuration).getOwnClassrooms(nendo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自身の学校のクラス一覧。学校の教員のみ閲覧可能。 年度で絞り込むべし
     * @summary Get Classrooms of my School
     * @param {number} [nendo] 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getOwnSchoolClassrooms(nendo?: number, options?: any) {
        return ClassroomsApiFp(this.configuration).getOwnSchoolClassrooms(nendo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [保護者用] 自分の子供の現在のクラス一覧
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getOwnStudentsClassrooms(options?: any) {
        return ClassroomsApiFp(this.configuration).getOwnStudentsClassrooms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 本人のクラスへの未処理の参加リクエストを取得する。参加登録フロー中に中断があったとき用。
     * @summary Get Pending Classroom Join Request By Student
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getPendingClassroomJoinRequest(options?: any) {
        return ClassroomsApiFp(this.configuration).getPendingClassroomJoinRequest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 参加コードのリセット。新しい値はサーバーで自動生成するので、何も送らない。
     * @summary Reset a Join Code of a Classroom
     * @param {string} classroomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public resetJoinCode(classroomId: string, options?: any) {
        return ClassroomsApiFp(this.configuration).resetJoinCode(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したクラスの一部更新(学校は変えられない)。クラスの担任(main_teachers)か副担任(sub_teachers)のみ更新可能。
     * @summary Update a Classroom
     * @param {string} classroomId 
     * @param {ClassroomUpdate} [classroomUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public updateClassroom(classroomId: string, classroomUpdate?: ClassroomUpdate, options?: any) {
        return ClassroomsApiFp(this.configuration).updateClassroom(classroomId, classroomUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
     * @summary Validate Classroom JoinCode
     * @param {JoinCodeCheck} [joinCodeCheck] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public validateClassroomJoinCode(joinCodeCheck?: JoinCodeCheck, options?: any) {
        return ClassroomsApiFp(this.configuration).validateClassroomJoinCode(joinCodeCheck, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの保護者参加コードからクラス情報を得る
     * @summary Validate Classroom Patron Join Code
     * @param {InlineObject14} [inlineObject14] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public validateClassroomPatronJoinCode(inlineObject14?: InlineObject14, options?: any) {
        return ClassroomsApiFp(this.configuration).validateClassroomPatronJoinCode(inlineObject14, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollabBoardsApi - axios parameter creator
 * @export
 */
export const CollabBoardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Noteを分類・類型化する
         * @summary Categorize Notes
         * @param {string} boardSetId 
         * @param {InlineObject53} [inlineObject53] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categorizeNotes: async (boardSetId: string, inlineObject53?: InlineObject53, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('categorizeNotes', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/categorize_stream`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject53, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BoardSetの作成
         * @summary Create Board Set
         * @param {string} courseId 
         * @param {InlineObject50} [inlineObject50] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBoardSet: async (courseId: string, inlineObject50?: InlineObject50, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createBoardSet', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/board_sets`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject50, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BoardSetの削除
         * @summary Delete Board Set
         * @param {string} boardSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardSet: async (boardSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('deleteBoardSet', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コラボボードの複製
         * @summary Create Posts From Notes
         * @param {string} boardSetId 
         * @param {InlineObject55} [inlineObject55] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBoardSet: async (boardSetId: string, inlineObject55?: InlineObject55, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('duplicateBoardSet', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/duplicate`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject55, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CollabBoardのObjectにインポートするための投稿取得
         * @summary Get Posts For CollabBoard
         * @param {string} boardSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostsForCollabBoard: async (boardSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('getCoursePostsForCollabBoard', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/posts_for_collab_board`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特徴的なNoteを抽出する
         * @summary Unique Notes
         * @param {string} boardSetId 
         * @param {InlineObject52} [inlineObject52] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickUpUniqueNotes: async (boardSetId: string, inlineObject52?: InlineObject52, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('pickUpUniqueNotes', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/unique_notes`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject52, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Noteを要約する
         * @summary Summary Notes
         * @param {string} boardSetId 
         * @param {InlineObject54} [inlineObject54] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryNotes: async (boardSetId: string, inlineObject54?: InlineObject54, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('summaryNotes', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/summary_stream`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject54, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BoardSetの編集
         * @summary Update Board Set
         * @param {string} boardSetId 
         * @param {InlineObject49} [inlineObject49] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardSet: async (boardSetId: string, inlineObject49?: InlineObject49, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('updateBoardSet', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject49, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Board Setting
         * @param {string} boardSetId 
         * @param {InlineObject51} [inlineObject51] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardSetting: async (boardSetId: string, inlineObject51?: InlineObject51, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('updateBoardSetting', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/setting`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject51, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BoardSetのリアルタイムでの更新を行う
         * @summary Update Editing Collab Board
         * @param {string} boardSetId 
         * @param {InlineObject48} [inlineObject48] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditingCollabBoard: async (boardSetId: string, inlineObject48?: InlineObject48, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('updateEditingCollabBoard', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/editing_board_sets/{board_set_id}`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject48, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollabBoardsApi - functional programming interface
 * @export
 */
export const CollabBoardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollabBoardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Noteを分類・類型化する
         * @summary Categorize Notes
         * @param {string} boardSetId 
         * @param {InlineObject53} [inlineObject53] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categorizeNotes(boardSetId: string, inlineObject53?: InlineObject53, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categorizeNotes(boardSetId, inlineObject53, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BoardSetの作成
         * @summary Create Board Set
         * @param {string} courseId 
         * @param {InlineObject50} [inlineObject50] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBoardSet(courseId: string, inlineObject50?: InlineObject50, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBoardSet(courseId, inlineObject50, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BoardSetの削除
         * @summary Delete Board Set
         * @param {string} boardSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoardSet(boardSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoardSet(boardSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コラボボードの複製
         * @summary Create Posts From Notes
         * @param {string} boardSetId 
         * @param {InlineObject55} [inlineObject55] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateBoardSet(boardSetId: string, inlineObject55?: InlineObject55, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse20028>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateBoardSet(boardSetId, inlineObject55, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CollabBoardのObjectにインポートするための投稿取得
         * @summary Get Posts For CollabBoard
         * @param {string} boardSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursePostsForCollabBoard(boardSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostForCollabBoardNote>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursePostsForCollabBoard(boardSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特徴的なNoteを抽出する
         * @summary Unique Notes
         * @param {string} boardSetId 
         * @param {InlineObject52} [inlineObject52] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pickUpUniqueNotes(boardSetId: string, inlineObject52?: InlineObject52, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse20027>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pickUpUniqueNotes(boardSetId, inlineObject52, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Noteを要約する
         * @summary Summary Notes
         * @param {string} boardSetId 
         * @param {InlineObject54} [inlineObject54] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summaryNotes(boardSetId: string, inlineObject54?: InlineObject54, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summaryNotes(boardSetId, inlineObject54, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BoardSetの編集
         * @summary Update Board Set
         * @param {string} boardSetId 
         * @param {InlineObject49} [inlineObject49] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBoardSet(boardSetId: string, inlineObject49?: InlineObject49, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBoardSet(boardSetId, inlineObject49, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Board Setting
         * @param {string} boardSetId 
         * @param {InlineObject51} [inlineObject51] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBoardSetting(boardSetId: string, inlineObject51?: InlineObject51, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBoardSetting(boardSetId, inlineObject51, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BoardSetのリアルタイムでの更新を行う
         * @summary Update Editing Collab Board
         * @param {string} boardSetId 
         * @param {InlineObject48} [inlineObject48] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEditingCollabBoard(boardSetId: string, inlineObject48?: InlineObject48, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEditingCollabBoard(boardSetId, inlineObject48, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollabBoardsApi - factory interface
 * @export
 */
export const CollabBoardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollabBoardsApiFp(configuration)
    return {
        /**
         * Noteを分類・類型化する
         * @summary Categorize Notes
         * @param {string} boardSetId 
         * @param {InlineObject53} [inlineObject53] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categorizeNotes(boardSetId: string, inlineObject53?: InlineObject53, options?: any): AxiosPromise<void> {
            return localVarFp.categorizeNotes(boardSetId, inlineObject53, options).then((request) => request(axios, basePath));
        },
        /**
         * BoardSetの作成
         * @summary Create Board Set
         * @param {string} courseId 
         * @param {InlineObject50} [inlineObject50] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBoardSet(courseId: string, inlineObject50?: InlineObject50, options?: any): AxiosPromise<void> {
            return localVarFp.createBoardSet(courseId, inlineObject50, options).then((request) => request(axios, basePath));
        },
        /**
         * BoardSetの削除
         * @summary Delete Board Set
         * @param {string} boardSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoardSet(boardSetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBoardSet(boardSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * コラボボードの複製
         * @summary Create Posts From Notes
         * @param {string} boardSetId 
         * @param {InlineObject55} [inlineObject55] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBoardSet(boardSetId: string, inlineObject55?: InlineObject55, options?: any): AxiosPromise<Array<InlineResponse20028>> {
            return localVarFp.duplicateBoardSet(boardSetId, inlineObject55, options).then((request) => request(axios, basePath));
        },
        /**
         * CollabBoardのObjectにインポートするための投稿取得
         * @summary Get Posts For CollabBoard
         * @param {string} boardSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostsForCollabBoard(boardSetId: string, options?: any): AxiosPromise<Array<PostForCollabBoardNote>> {
            return localVarFp.getCoursePostsForCollabBoard(boardSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 特徴的なNoteを抽出する
         * @summary Unique Notes
         * @param {string} boardSetId 
         * @param {InlineObject52} [inlineObject52] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickUpUniqueNotes(boardSetId: string, inlineObject52?: InlineObject52, options?: any): AxiosPromise<Array<InlineResponse20027>> {
            return localVarFp.pickUpUniqueNotes(boardSetId, inlineObject52, options).then((request) => request(axios, basePath));
        },
        /**
         * Noteを要約する
         * @summary Summary Notes
         * @param {string} boardSetId 
         * @param {InlineObject54} [inlineObject54] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryNotes(boardSetId: string, inlineObject54?: InlineObject54, options?: any): AxiosPromise<void> {
            return localVarFp.summaryNotes(boardSetId, inlineObject54, options).then((request) => request(axios, basePath));
        },
        /**
         * BoardSetの編集
         * @summary Update Board Set
         * @param {string} boardSetId 
         * @param {InlineObject49} [inlineObject49] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardSet(boardSetId: string, inlineObject49?: InlineObject49, options?: any): AxiosPromise<void> {
            return localVarFp.updateBoardSet(boardSetId, inlineObject49, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Board Setting
         * @param {string} boardSetId 
         * @param {InlineObject51} [inlineObject51] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardSetting(boardSetId: string, inlineObject51?: InlineObject51, options?: any): AxiosPromise<void> {
            return localVarFp.updateBoardSetting(boardSetId, inlineObject51, options).then((request) => request(axios, basePath));
        },
        /**
         * BoardSetのリアルタイムでの更新を行う
         * @summary Update Editing Collab Board
         * @param {string} boardSetId 
         * @param {InlineObject48} [inlineObject48] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditingCollabBoard(boardSetId: string, inlineObject48?: InlineObject48, options?: any): AxiosPromise<void> {
            return localVarFp.updateEditingCollabBoard(boardSetId, inlineObject48, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CollabBoardsApi - object-oriented interface
 * @export
 * @class CollabBoardsApi
 * @extends {BaseAPI}
 */
export class CollabBoardsApi extends BaseAPI {
    /**
     * Noteを分類・類型化する
     * @summary Categorize Notes
     * @param {string} boardSetId 
     * @param {InlineObject53} [inlineObject53] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public categorizeNotes(boardSetId: string, inlineObject53?: InlineObject53, options?: any) {
        return CollabBoardsApiFp(this.configuration).categorizeNotes(boardSetId, inlineObject53, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BoardSetの作成
     * @summary Create Board Set
     * @param {string} courseId 
     * @param {InlineObject50} [inlineObject50] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public createBoardSet(courseId: string, inlineObject50?: InlineObject50, options?: any) {
        return CollabBoardsApiFp(this.configuration).createBoardSet(courseId, inlineObject50, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BoardSetの削除
     * @summary Delete Board Set
     * @param {string} boardSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public deleteBoardSet(boardSetId: string, options?: any) {
        return CollabBoardsApiFp(this.configuration).deleteBoardSet(boardSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コラボボードの複製
     * @summary Create Posts From Notes
     * @param {string} boardSetId 
     * @param {InlineObject55} [inlineObject55] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public duplicateBoardSet(boardSetId: string, inlineObject55?: InlineObject55, options?: any) {
        return CollabBoardsApiFp(this.configuration).duplicateBoardSet(boardSetId, inlineObject55, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CollabBoardのObjectにインポートするための投稿取得
     * @summary Get Posts For CollabBoard
     * @param {string} boardSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public getCoursePostsForCollabBoard(boardSetId: string, options?: any) {
        return CollabBoardsApiFp(this.configuration).getCoursePostsForCollabBoard(boardSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特徴的なNoteを抽出する
     * @summary Unique Notes
     * @param {string} boardSetId 
     * @param {InlineObject52} [inlineObject52] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public pickUpUniqueNotes(boardSetId: string, inlineObject52?: InlineObject52, options?: any) {
        return CollabBoardsApiFp(this.configuration).pickUpUniqueNotes(boardSetId, inlineObject52, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Noteを要約する
     * @summary Summary Notes
     * @param {string} boardSetId 
     * @param {InlineObject54} [inlineObject54] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public summaryNotes(boardSetId: string, inlineObject54?: InlineObject54, options?: any) {
        return CollabBoardsApiFp(this.configuration).summaryNotes(boardSetId, inlineObject54, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BoardSetの編集
     * @summary Update Board Set
     * @param {string} boardSetId 
     * @param {InlineObject49} [inlineObject49] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public updateBoardSet(boardSetId: string, inlineObject49?: InlineObject49, options?: any) {
        return CollabBoardsApiFp(this.configuration).updateBoardSet(boardSetId, inlineObject49, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Board Setting
     * @param {string} boardSetId 
     * @param {InlineObject51} [inlineObject51] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public updateBoardSetting(boardSetId: string, inlineObject51?: InlineObject51, options?: any) {
        return CollabBoardsApiFp(this.configuration).updateBoardSetting(boardSetId, inlineObject51, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BoardSetのリアルタイムでの更新を行う
     * @summary Update Editing Collab Board
     * @param {string} boardSetId 
     * @param {InlineObject48} [inlineObject48] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollabBoardsApi
     */
    public updateEditingCollabBoard(boardSetId: string, inlineObject48?: InlineObject48, options?: any) {
        return CollabBoardsApiFp(this.configuration).updateEditingCollabBoard(boardSetId, inlineObject48, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * コメントの作成。指定した授業の教員か生徒しか作れない。
         * @summary Create a Comment
         * @param {string} postId 
         * @param {string} [text] 
         * @param {string} [toComment] どのコメントに対してのコメントか
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (postId: string, text?: string, toComment?: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('createComment', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/comments`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (text !== undefined) { 
                localVarFormParams.append('text', text as any);
            }
    
            if (toComment !== undefined) { 
                localVarFormParams.append('to_comment', new Blob([JSON.stringify(toComment)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したコメントの削除。authorのみ可能。
         * @summary Delete a Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Commentの取得
         * @summary Get A Comment
         * @param {string} commentId コメントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment: async (commentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントを取得(検索に使用)
         * @summary Get Comments
         * @param {Array<string>} [words] 検索用ワード
         * @param {Array<string>} [authorIds] コメント者のUUID
         * @param {Array<string>} [postTagIds] 投稿タグのUUID
         * @param {Array<string>} [classroomIds] クラスルームのUUID
         * @param {Array<string>} [courseIds] 授業のUUID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (words) {
                localVarQueryParameter['words'] = words;
            }

            if (authorIds) {
                localVarQueryParameter['author_ids'] = authorIds;
            }

            if (postTagIds) {
                localVarQueryParameter['post_tag_ids'] = postTagIds;
            }

            if (classroomIds) {
                localVarQueryParameter['classroom_ids'] = classroomIds;
            }

            if (courseIds) {
                localVarQueryParameter['course_ids'] = courseIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (shareRange !== undefined) {
                localVarQueryParameter['share_range'] = shareRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したコメントの一部更新。authorのみ可能。
         * @summary Update a Comment
         * @param {string} commentId 
         * @param {CommentUpdate} [commentUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (commentId: string, commentUpdate?: CommentUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * コメントの作成。指定した授業の教員か生徒しか作れない。
         * @summary Create a Comment
         * @param {string} postId 
         * @param {string} [text] 
         * @param {string} [toComment] どのコメントに対してのコメントか
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(postId: string, text?: string, toComment?: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(postId, text, toComment, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したコメントの削除。authorのみ可能。
         * @summary Delete a Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(commentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Commentの取得
         * @summary Get A Comment
         * @param {string} commentId コメントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComment(commentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentSimpleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントを取得(検索に使用)
         * @summary Get Comments
         * @param {Array<string>} [words] 検索用ワード
         * @param {Array<string>} [authorIds] コメント者のUUID
         * @param {Array<string>} [postTagIds] 投稿タグのUUID
         * @param {Array<string>} [classroomIds] クラスルームのUUID
         * @param {Array<string>} [courseIds] 授業のUUID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(words, authorIds, postTagIds, classroomIds, courseIds, page, pageSize, shareRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したコメントの一部更新。authorのみ可能。
         * @summary Update a Comment
         * @param {string} commentId 
         * @param {CommentUpdate} [commentUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(commentId: string, commentUpdate?: CommentUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(commentId, commentUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsApiFp(configuration)
    return {
        /**
         * コメントの作成。指定した授業の教員か生徒しか作れない。
         * @summary Create a Comment
         * @param {string} postId 
         * @param {string} [text] 
         * @param {string} [toComment] どのコメントに対してのコメントか
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(postId: string, text?: string, toComment?: string, file?: any, options?: any): AxiosPromise<CommentRead> {
            return localVarFp.createComment(postId, text, toComment, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したコメントの削除。authorのみ可能。
         * @summary Delete a Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: string, options?: any): AxiosPromise<CommentRead> {
            return localVarFp.deleteComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Commentの取得
         * @summary Get A Comment
         * @param {string} commentId コメントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment(commentId: string, options?: any): AxiosPromise<CommentSimpleRead> {
            return localVarFp.getComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントを取得(検索に使用)
         * @summary Get Comments
         * @param {Array<string>} [words] 検索用ワード
         * @param {Array<string>} [authorIds] コメント者のUUID
         * @param {Array<string>} [postTagIds] 投稿タグのUUID
         * @param {Array<string>} [classroomIds] クラスルームのUUID
         * @param {Array<string>} [courseIds] 授業のUUID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options?: any): AxiosPromise<CommentListPaginated> {
            return localVarFp.getComments(words, authorIds, postTagIds, classroomIds, courseIds, page, pageSize, shareRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したコメントの一部更新。authorのみ可能。
         * @summary Update a Comment
         * @param {string} commentId 
         * @param {CommentUpdate} [commentUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(commentId: string, commentUpdate?: CommentUpdate, options?: any): AxiosPromise<CommentRead> {
            return localVarFp.updateComment(commentId, commentUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
    /**
     * コメントの作成。指定した授業の教員か生徒しか作れない。
     * @summary Create a Comment
     * @param {string} postId 
     * @param {string} [text] 
     * @param {string} [toComment] どのコメントに対してのコメントか
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public createComment(postId: string, text?: string, toComment?: string, file?: any, options?: any) {
        return CommentsApiFp(this.configuration).createComment(postId, text, toComment, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したコメントの削除。authorのみ可能。
     * @summary Delete a Comment
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public deleteComment(commentId: string, options?: any) {
        return CommentsApiFp(this.configuration).deleteComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Commentの取得
     * @summary Get A Comment
     * @param {string} commentId コメントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public getComment(commentId: string, options?: any) {
        return CommentsApiFp(this.configuration).getComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コメントを取得(検索に使用)
     * @summary Get Comments
     * @param {Array<string>} [words] 検索用ワード
     * @param {Array<string>} [authorIds] コメント者のUUID
     * @param {Array<string>} [postTagIds] 投稿タグのUUID
     * @param {Array<string>} [classroomIds] クラスルームのUUID
     * @param {Array<string>} [courseIds] 授業のUUID
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public getComments(words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options?: any) {
        return CommentsApiFp(this.configuration).getComments(words, authorIds, postTagIds, classroomIds, courseIds, page, pageSize, shareRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したコメントの一部更新。authorのみ可能。
     * @summary Update a Comment
     * @param {string} commentId 
     * @param {CommentUpdate} [commentUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public updateComment(commentId: string, commentUpdate?: CommentUpdate, options?: any) {
        return CommentsApiFp(this.configuration).updateComment(commentId, commentUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseMatchingsApi - axios parameter creator
 * @export
 */
export const CourseMatchingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クラスの授業への参加リクエストの承認
         * @summary Accept Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptCourseJoinRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('acceptCourseJoinRequest', 'requestId', requestId)
            const localVarPath = `/api/v1/course_join_requests/{request_id}/accept`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの授業への参加リクエストの作成
         * @summary Create Course Join Request
         * @param {string} settingId 
         * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassroomsCourseJoinRequest: async (settingId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('createClassroomsCourseJoinRequest', 'settingId', settingId)
            const localVarPath = `/api/v1/course_open_settings/{setting_id}/join_requests`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classroomsCourseJoinRequestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の設定の作成
         * @summary Create Course Open Setting
         * @param {string} courseId 
         * @param {CourseOpenSettingCreate} [courseOpenSettingCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourseOpenSetting: async (courseId: string, courseOpenSettingCreate?: CourseOpenSettingCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createCourseOpenSetting', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/course_open_settings`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseOpenSettingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの授業への参加リクエストの削除
         * @summary Delete Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseJoinRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('deleteCourseJoinRequest', 'requestId', requestId)
            const localVarPath = `/api/v1/course_join_requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の設定の削除
         * @summary Delete Course Open Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseOpenSetting: async (settingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('deleteCourseOpenSetting', 'settingId', settingId)
            const localVarPath = `/api/v1/course_open_settings/{setting_id}`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの授業への参加リクエストの取得
         * @summary Get Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseJoinRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('getCourseJoinRequest', 'requestId', requestId)
            const localVarPath = `/api/v1/course_join_requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分が送った、もしくは送られたクラスへの授業参加リクエストの複数取得
         * @summary List Get Course Join Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseJoinRequests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/course_join_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の設定の取得
         * @summary Get Course Open Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseOpenSetting: async (settingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('getCourseOpenSetting', 'settingId', settingId)
            const localVarPath = `/api/v1/course_open_settings/{setting_id}`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の公開設定の複数取得。
         * @summary List Get Course Open Setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseOpenSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/course_open_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの授業への参加リクエストの更新
         * @summary Update Course Join Request
         * @param {string} requestId 
         * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseJoinRequest: async (requestId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('updateCourseJoinRequest', 'requestId', requestId)
            const localVarPath = `/api/v1/course_join_requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classroomsCourseJoinRequestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の設定の更新
         * @summary Update Course Open Setting
         * @param {string} settingId 
         * @param {CourseOpenSettingUpdate} [courseOpenSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseOpenSetting: async (settingId: string, courseOpenSettingUpdate?: CourseOpenSettingUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('updateCourseOpenSetting', 'settingId', settingId)
            const localVarPath = `/api/v1/course_open_settings/{setting_id}`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseOpenSettingUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseMatchingsApi - functional programming interface
 * @export
 */
export const CourseMatchingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseMatchingsApiAxiosParamCreator(configuration)
    return {
        /**
         * クラスの授業への参加リクエストの承認
         * @summary Accept Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptCourseJoinRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptCourseJoinRequest(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの授業への参加リクエストの作成
         * @summary Create Course Join Request
         * @param {string} settingId 
         * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClassroomsCourseJoinRequest(settingId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClassroomsCourseJoinRequest(settingId, classroomsCourseJoinRequestCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の設定の作成
         * @summary Create Course Open Setting
         * @param {string} courseId 
         * @param {CourseOpenSettingCreate} [courseOpenSettingCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCourseOpenSetting(courseId: string, courseOpenSettingCreate?: CourseOpenSettingCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCourseOpenSetting(courseId, courseOpenSettingCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの授業への参加リクエストの削除
         * @summary Delete Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourseJoinRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCourseJoinRequest(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の設定の削除
         * @summary Delete Course Open Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourseOpenSetting(settingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCourseOpenSetting(settingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの授業への参加リクエストの取得
         * @summary Get Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseJoinRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassroomsCourseJoinRequestRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseJoinRequest(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分が送った、もしくは送られたクラスへの授業参加リクエストの複数取得
         * @summary List Get Course Join Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseJoinRequests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassroomsCourseJoinRequestListRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseJoinRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の設定の取得
         * @summary Get Course Open Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseOpenSetting(settingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseOpenSettingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseOpenSetting(settingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の公開設定の複数取得。
         * @summary List Get Course Open Setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseOpenSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseOpenSettingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseOpenSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの授業への参加リクエストの更新
         * @summary Update Course Join Request
         * @param {string} requestId 
         * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCourseJoinRequest(requestId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCourseJoinRequest(requestId, classroomsCourseJoinRequestCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の設定の更新
         * @summary Update Course Open Setting
         * @param {string} settingId 
         * @param {CourseOpenSettingUpdate} [courseOpenSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCourseOpenSetting(settingId: string, courseOpenSettingUpdate?: CourseOpenSettingUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCourseOpenSetting(settingId, courseOpenSettingUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseMatchingsApi - factory interface
 * @export
 */
export const CourseMatchingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseMatchingsApiFp(configuration)
    return {
        /**
         * クラスの授業への参加リクエストの承認
         * @summary Accept Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptCourseJoinRequest(requestId: string, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.acceptCourseJoinRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの授業への参加リクエストの作成
         * @summary Create Course Join Request
         * @param {string} settingId 
         * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassroomsCourseJoinRequest(settingId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createClassroomsCourseJoinRequest(settingId, classroomsCourseJoinRequestCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の設定の作成
         * @summary Create Course Open Setting
         * @param {string} courseId 
         * @param {CourseOpenSettingCreate} [courseOpenSettingCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourseOpenSetting(courseId: string, courseOpenSettingCreate?: CourseOpenSettingCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createCourseOpenSetting(courseId, courseOpenSettingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの授業への参加リクエストの削除
         * @summary Delete Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseJoinRequest(requestId: string, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.deleteCourseJoinRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の設定の削除
         * @summary Delete Course Open Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseOpenSetting(settingId: string, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.deleteCourseOpenSetting(settingId, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの授業への参加リクエストの取得
         * @summary Get Course Join Request
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseJoinRequest(requestId: string, options?: any): AxiosPromise<ClassroomsCourseJoinRequestRead> {
            return localVarFp.getCourseJoinRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分が送った、もしくは送られたクラスへの授業参加リクエストの複数取得
         * @summary List Get Course Join Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseJoinRequests(options?: any): AxiosPromise<Array<ClassroomsCourseJoinRequestListRead>> {
            return localVarFp.getCourseJoinRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の設定の取得
         * @summary Get Course Open Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseOpenSetting(settingId: string, options?: any): AxiosPromise<CourseOpenSettingRead> {
            return localVarFp.getCourseOpenSetting(settingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の公開設定の複数取得。
         * @summary List Get Course Open Setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseOpenSettings(options?: any): AxiosPromise<CourseOpenSettingRead> {
            return localVarFp.getCourseOpenSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの授業への参加リクエストの更新
         * @summary Update Course Join Request
         * @param {string} requestId 
         * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseJoinRequest(requestId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.updateCourseJoinRequest(requestId, classroomsCourseJoinRequestCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の設定の更新
         * @summary Update Course Open Setting
         * @param {string} settingId 
         * @param {CourseOpenSettingUpdate} [courseOpenSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourseOpenSetting(settingId: string, courseOpenSettingUpdate?: CourseOpenSettingUpdate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.updateCourseOpenSetting(settingId, courseOpenSettingUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseMatchingsApi - object-oriented interface
 * @export
 * @class CourseMatchingsApi
 * @extends {BaseAPI}
 */
export class CourseMatchingsApi extends BaseAPI {
    /**
     * クラスの授業への参加リクエストの承認
     * @summary Accept Course Join Request
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public acceptCourseJoinRequest(requestId: string, options?: any) {
        return CourseMatchingsApiFp(this.configuration).acceptCourseJoinRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの授業への参加リクエストの作成
     * @summary Create Course Join Request
     * @param {string} settingId 
     * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public createClassroomsCourseJoinRequest(settingId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options?: any) {
        return CourseMatchingsApiFp(this.configuration).createClassroomsCourseJoinRequest(settingId, classroomsCourseJoinRequestCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の設定の作成
     * @summary Create Course Open Setting
     * @param {string} courseId 
     * @param {CourseOpenSettingCreate} [courseOpenSettingCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public createCourseOpenSetting(courseId: string, courseOpenSettingCreate?: CourseOpenSettingCreate, options?: any) {
        return CourseMatchingsApiFp(this.configuration).createCourseOpenSetting(courseId, courseOpenSettingCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの授業への参加リクエストの削除
     * @summary Delete Course Join Request
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public deleteCourseJoinRequest(requestId: string, options?: any) {
        return CourseMatchingsApiFp(this.configuration).deleteCourseJoinRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の設定の削除
     * @summary Delete Course Open Setting
     * @param {string} settingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public deleteCourseOpenSetting(settingId: string, options?: any) {
        return CourseMatchingsApiFp(this.configuration).deleteCourseOpenSetting(settingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの授業への参加リクエストの取得
     * @summary Get Course Join Request
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public getCourseJoinRequest(requestId: string, options?: any) {
        return CourseMatchingsApiFp(this.configuration).getCourseJoinRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分が送った、もしくは送られたクラスへの授業参加リクエストの複数取得
     * @summary List Get Course Join Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public getCourseJoinRequests(options?: any) {
        return CourseMatchingsApiFp(this.configuration).getCourseJoinRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の設定の取得
     * @summary Get Course Open Setting
     * @param {string} settingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public getCourseOpenSetting(settingId: string, options?: any) {
        return CourseMatchingsApiFp(this.configuration).getCourseOpenSetting(settingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の公開設定の複数取得。
     * @summary List Get Course Open Setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public getCourseOpenSettings(options?: any) {
        return CourseMatchingsApiFp(this.configuration).getCourseOpenSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの授業への参加リクエストの更新
     * @summary Update Course Join Request
     * @param {string} requestId 
     * @param {ClassroomsCourseJoinRequestCreate} [classroomsCourseJoinRequestCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public updateCourseJoinRequest(requestId: string, classroomsCourseJoinRequestCreate?: ClassroomsCourseJoinRequestCreate, options?: any) {
        return CourseMatchingsApiFp(this.configuration).updateCourseJoinRequest(requestId, classroomsCourseJoinRequestCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の設定の更新
     * @summary Update Course Open Setting
     * @param {string} settingId 
     * @param {CourseOpenSettingUpdate} [courseOpenSettingUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseMatchingsApi
     */
    public updateCourseOpenSetting(settingId: string, courseOpenSettingUpdate?: CourseOpenSettingUpdate, options?: any) {
        return CourseMatchingsApiFp(this.configuration).updateCourseOpenSetting(settingId, courseOpenSettingUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursePostFixationsApi - axios parameter creator
 * @export
 */
export const CoursePostFixationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した投稿の固定解除
         * @summary Cancel Course Post Fixation
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCoursePostFixation: async (postId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('cancelCoursePostFixation', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/course_post_fixations`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業への投稿の固定
         * @summary Fixate Post To Course
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixatePostToCourse: async (postId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('fixatePostToCourse', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/course_post_fixations`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その授業の固定投稿の一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get a Fixated Posts
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseFixatedPosts: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseFixatedPosts', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/fixated_posts`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursePostFixationsApi - functional programming interface
 * @export
 */
export const CoursePostFixationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursePostFixationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した投稿の固定解除
         * @summary Cancel Course Post Fixation
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelCoursePostFixation(postId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursePostFixation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelCoursePostFixation(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業への投稿の固定
         * @summary Fixate Post To Course
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixatePostToCourse(postId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixatePostToCourse(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その授業の固定投稿の一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get a Fixated Posts
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseFixatedPosts(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoursePostFixation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseFixatedPosts(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoursePostFixationsApi - factory interface
 * @export
 */
export const CoursePostFixationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursePostFixationsApiFp(configuration)
    return {
        /**
         * 指定した投稿の固定解除
         * @summary Cancel Course Post Fixation
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCoursePostFixation(postId: string, options?: any): AxiosPromise<CoursePostFixation> {
            return localVarFp.cancelCoursePostFixation(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業への投稿の固定
         * @summary Fixate Post To Course
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixatePostToCourse(postId: string, options?: any): AxiosPromise<void> {
            return localVarFp.fixatePostToCourse(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * その授業の固定投稿の一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get a Fixated Posts
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseFixatedPosts(courseId: string, options?: any): AxiosPromise<Array<CoursePostFixation>> {
            return localVarFp.getCourseFixatedPosts(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursePostFixationsApi - object-oriented interface
 * @export
 * @class CoursePostFixationsApi
 * @extends {BaseAPI}
 */
export class CoursePostFixationsApi extends BaseAPI {
    /**
     * 指定した投稿の固定解除
     * @summary Cancel Course Post Fixation
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursePostFixationsApi
     */
    public cancelCoursePostFixation(postId: string, options?: any) {
        return CoursePostFixationsApiFp(this.configuration).cancelCoursePostFixation(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業への投稿の固定
     * @summary Fixate Post To Course
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursePostFixationsApi
     */
    public fixatePostToCourse(postId: string, options?: any) {
        return CoursePostFixationsApiFp(this.configuration).fixatePostToCourse(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その授業の固定投稿の一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
     * @summary Get a Fixated Posts
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursePostFixationsApi
     */
    public getCourseFixatedPosts(courseId: string, options?: any) {
        return CoursePostFixationsApiFp(this.configuration).getCourseFixatedPosts(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseTagsApi - axios parameter creator
 * @export
 */
export const CourseTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 学校に紐づく授業のタグ作成。教員しか作れない。
         * @summary Create a Course Tag
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourseTag: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/course_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseTagsApi - functional programming interface
 * @export
 */
export const CourseTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 学校に紐づく授業のタグ作成。教員しか作れない。
         * @summary Create a Course Tag
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCourseTag(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseTagRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCourseTag(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseTagsApi - factory interface
 * @export
 */
export const CourseTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseTagsApiFp(configuration)
    return {
        /**
         * 学校に紐づく授業のタグ作成。教員しか作れない。
         * @summary Create a Course Tag
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourseTag(inlineObject?: InlineObject, options?: any): AxiosPromise<CourseTagRead> {
            return localVarFp.createCourseTag(inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseTagsApi - object-oriented interface
 * @export
 * @class CourseTagsApi
 * @extends {BaseAPI}
 */
export class CourseTagsApi extends BaseAPI {
    /**
     * 学校に紐づく授業のタグ作成。教員しか作れない。
     * @summary Create a Course Tag
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseTagsApi
     */
    public createCourseTag(inlineObject?: InlineObject, options?: any) {
        return CourseTagsApiFp(this.configuration).createCourseTag(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ある授業のアクティブ状態を変更する。インアクティブになると、投稿やコメントの作成や編集ができなくなり、自分の授業一覧取得時に取得しなくなる。送るデータにis_activeがないときは状態のtoggle。返り値は更新後のステータス。
         * @param {string} courseId 
         * @param {InlineObject13} [inlineObject13] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCourseActiveStatus: async (courseId: string, inlineObject13?: InlineObject13, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('changeCourseActiveStatus', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/activation`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の作成。pathの学校の教員しか作れない。classroomsとteachersもその学校のものである必要がある。
         * @summary Create a Course
         * @param {CourseCreate} courseCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse: async (courseCreate: CourseCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseCreate' is not null or undefined
            assertParamExists('createCourse', 'courseCreate', courseCreate)
            const localVarPath = `/api/v1/schools/me/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校に紐づく授業のタグ作成。教員しか作れない。
         * @summary Create a Course Tag
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourseTag: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/course_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tangenを作成
         * @summary Translate To Proper Kana
         * @param {InlineObject40} [inlineObject40] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchoolTangen: async (inlineObject40?: InlineObject40, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/tangens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject40, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業の削除。担当か副担当の教員しか実行できない
         * @summary Delete a Course
         * @param {string} courseId 授業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('deleteCourse', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 単元の削除。授業が入った単元はDjangoもmodels.PROTECTによって削除されない。
         * @param {string} tangenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTangen: async (tangenId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tangenId' is not null or undefined
            assertParamExists('deleteTangen', 'tangenId', tangenId)
            const localVarPath = `/api/v1/tangens/{tangen_id}`
                .replace(`{${"tangen_id"}}`, encodeURIComponent(String(tangenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した授業の情報の取得。クラスの属する学校の教員のみ実行可能。
         * @summary Get a Course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourse', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身の授業一覧を取得。教師、生徒のみ。 年度でフィルターすべし
         * @summary Get my Courses
         * @param {number} [nendo] 授業の開始年
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCourses: async (nendo?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nendo !== undefined) {
                localVarQueryParameter['nendo'] = nendo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分の所属している学校の授業 できるだけnendoでフィルターすべし
         * @summary Get Own School Courses
         * @param {number} [nendo] 授業の開始年
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolCourses: async (nendo?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nendo !== undefined) {
                localVarQueryParameter['nendo'] = nendo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分の学校の、自分が担当でない、アクティブな授業一覧(ページネーション)。nendoパラメタ必須。
         * @summary Get Own School Other Teachers Courses
         * @param {number} nendo 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolOtherTeachersCourses: async (nendo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nendo' is not null or undefined
            assertParamExists('getOwnSchoolOtherTeachersCourses', 'nendo', nendo)
            const localVarPath = `/api/v1/schools/me/other_teachers_courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nendo !== undefined) {
                localVarQueryParameter['nendo'] = nendo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した授業の一部更新(学校は変えられない)。course.teachersの教員しか実行できない。classroomsとteachersはその学校のものである必要がある。
         * @summary Update a Course
         * @param {string} courseId 
         * @param {CourseUpdate} [courseUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse: async (courseId: string, courseUpdate?: CourseUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('updateCourse', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tangenを編集
         * @summary Translate To Proper Kana
         * @param {string} tangenId 
         * @param {InlineObject41} [inlineObject41] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTangen: async (tangenId: string, inlineObject41?: InlineObject41, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tangenId' is not null or undefined
            assertParamExists('updateTangen', 'tangenId', tangenId)
            const localVarPath = `/api/v1/tangens/{tangen_id}`
                .replace(`{${"tangen_id"}}`, encodeURIComponent(String(tangenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject41, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
    return {
        /**
         * ある授業のアクティブ状態を変更する。インアクティブになると、投稿やコメントの作成や編集ができなくなり、自分の授業一覧取得時に取得しなくなる。送るデータにis_activeがないときは状態のtoggle。返り値は更新後のステータス。
         * @param {string} courseId 
         * @param {InlineObject13} [inlineObject13] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCourseActiveStatus(courseId: string, inlineObject13?: InlineObject13, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCourseActiveStatus(courseId, inlineObject13, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の作成。pathの学校の教員しか作れない。classroomsとteachersもその学校のものである必要がある。
         * @summary Create a Course
         * @param {CourseCreate} courseCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCourse(courseCreate: CourseCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCourse(courseCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校に紐づく授業のタグ作成。教員しか作れない。
         * @summary Create a Course Tag
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCourseTag(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseTagRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCourseTag(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tangenを作成
         * @summary Translate To Proper Kana
         * @param {InlineObject40} [inlineObject40] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSchoolTangen(inlineObject40?: InlineObject40, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20025>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSchoolTangen(inlineObject40, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業の削除。担当か副担当の教員しか実行できない
         * @summary Delete a Course
         * @param {string} courseId 授業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourse(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 単元の削除。授業が入った単元はDjangoもmodels.PROTECTによって削除されない。
         * @param {string} tangenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTangen(tangenId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTangen(tangenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した授業の情報の取得。クラスの属する学校の教員のみ実行可能。
         * @summary Get a Course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourse(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourse(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身の授業一覧を取得。教師、生徒のみ。 年度でフィルターすべし
         * @summary Get my Courses
         * @param {number} [nendo] 授業の開始年
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnCourses(nendo?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnCourses(nendo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分の所属している学校の授業 できるだけnendoでフィルターすべし
         * @summary Get Own School Courses
         * @param {number} [nendo] 授業の開始年
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnSchoolCourses(nendo?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnSchoolCourses(nendo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分の学校の、自分が担当でない、アクティブな授業一覧(ページネーション)。nendoパラメタ必須。
         * @summary Get Own School Other Teachers Courses
         * @param {number} nendo 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnSchoolOtherTeachersCourses(nendo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseSimpleWithTeachersListPaginated>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnSchoolOtherTeachersCourses(nendo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した授業の一部更新(学校は変えられない)。course.teachersの教員しか実行できない。classroomsとteachersはその学校のものである必要がある。
         * @summary Update a Course
         * @param {string} courseId 
         * @param {CourseUpdate} [courseUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCourse(courseId: string, courseUpdate?: CourseUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCourse(courseId, courseUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tangenを編集
         * @summary Translate To Proper Kana
         * @param {string} tangenId 
         * @param {InlineObject41} [inlineObject41] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTangen(tangenId: string, inlineObject41?: InlineObject41, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTangen(tangenId, inlineObject41, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesApiFp(configuration)
    return {
        /**
         * ある授業のアクティブ状態を変更する。インアクティブになると、投稿やコメントの作成や編集ができなくなり、自分の授業一覧取得時に取得しなくなる。送るデータにis_activeがないときは状態のtoggle。返り値は更新後のステータス。
         * @param {string} courseId 
         * @param {InlineObject13} [inlineObject13] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCourseActiveStatus(courseId: string, inlineObject13?: InlineObject13, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.changeCourseActiveStatus(courseId, inlineObject13, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の作成。pathの学校の教員しか作れない。classroomsとteachersもその学校のものである必要がある。
         * @summary Create a Course
         * @param {CourseCreate} courseCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(courseCreate: CourseCreate, options?: any): AxiosPromise<InlineResponse2012> {
            return localVarFp.createCourse(courseCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 学校に紐づく授業のタグ作成。教員しか作れない。
         * @summary Create a Course Tag
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourseTag(inlineObject?: InlineObject, options?: any): AxiosPromise<CourseTagRead> {
            return localVarFp.createCourseTag(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * tangenを作成
         * @summary Translate To Proper Kana
         * @param {InlineObject40} [inlineObject40] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchoolTangen(inlineObject40?: InlineObject40, options?: any): AxiosPromise<InlineResponse20025> {
            return localVarFp.createSchoolTangen(inlineObject40, options).then((request) => request(axios, basePath));
        },
        /**
         * 授業の削除。担当か副担当の教員しか実行できない
         * @summary Delete a Course
         * @param {string} courseId 授業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse(courseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 単元の削除。授業が入った単元はDjangoもmodels.PROTECTによって削除されない。
         * @param {string} tangenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTangen(tangenId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTangen(tangenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した授業の情報の取得。クラスの属する学校の教員のみ実行可能。
         * @summary Get a Course
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(courseId: string, options?: any): AxiosPromise<CourseRead> {
            return localVarFp.getCourse(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身の授業一覧を取得。教師、生徒のみ。 年度でフィルターすべし
         * @summary Get my Courses
         * @param {number} [nendo] 授業の開始年
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCourses(nendo?: number, options?: any): AxiosPromise<Array<CourseRead>> {
            return localVarFp.getOwnCourses(nendo, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分の所属している学校の授業 できるだけnendoでフィルターすべし
         * @summary Get Own School Courses
         * @param {number} [nendo] 授業の開始年
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolCourses(nendo?: number, options?: any): AxiosPromise<Array<CourseRead>> {
            return localVarFp.getOwnSchoolCourses(nendo, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分の学校の、自分が担当でない、アクティブな授業一覧(ページネーション)。nendoパラメタ必須。
         * @summary Get Own School Other Teachers Courses
         * @param {number} nendo 年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnSchoolOtherTeachersCourses(nendo: number, options?: any): AxiosPromise<Array<CourseSimpleWithTeachersListPaginated>> {
            return localVarFp.getOwnSchoolOtherTeachersCourses(nendo, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した授業の一部更新(学校は変えられない)。course.teachersの教員しか実行できない。classroomsとteachersはその学校のものである必要がある。
         * @summary Update a Course
         * @param {string} courseId 
         * @param {CourseUpdate} [courseUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse(courseId: string, courseUpdate?: CourseUpdate, options?: any): AxiosPromise<CourseRead> {
            return localVarFp.updateCourse(courseId, courseUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * tangenを編集
         * @summary Translate To Proper Kana
         * @param {string} tangenId 
         * @param {InlineObject41} [inlineObject41] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTangen(tangenId: string, inlineObject41?: InlineObject41, options?: any): AxiosPromise<object> {
            return localVarFp.updateTangen(tangenId, inlineObject41, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * ある授業のアクティブ状態を変更する。インアクティブになると、投稿やコメントの作成や編集ができなくなり、自分の授業一覧取得時に取得しなくなる。送るデータにis_activeがないときは状態のtoggle。返り値は更新後のステータス。
     * @param {string} courseId 
     * @param {InlineObject13} [inlineObject13] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public changeCourseActiveStatus(courseId: string, inlineObject13?: InlineObject13, options?: any) {
        return CoursesApiFp(this.configuration).changeCourseActiveStatus(courseId, inlineObject13, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の作成。pathの学校の教員しか作れない。classroomsとteachersもその学校のものである必要がある。
     * @summary Create a Course
     * @param {CourseCreate} courseCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public createCourse(courseCreate: CourseCreate, options?: any) {
        return CoursesApiFp(this.configuration).createCourse(courseCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校に紐づく授業のタグ作成。教員しか作れない。
     * @summary Create a Course Tag
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public createCourseTag(inlineObject?: InlineObject, options?: any) {
        return CoursesApiFp(this.configuration).createCourseTag(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tangenを作成
     * @summary Translate To Proper Kana
     * @param {InlineObject40} [inlineObject40] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public createSchoolTangen(inlineObject40?: InlineObject40, options?: any) {
        return CoursesApiFp(this.configuration).createSchoolTangen(inlineObject40, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業の削除。担当か副担当の教員しか実行できない
     * @summary Delete a Course
     * @param {string} courseId 授業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteCourse(courseId: string, options?: any) {
        return CoursesApiFp(this.configuration).deleteCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 単元の削除。授業が入った単元はDjangoもmodels.PROTECTによって削除されない。
     * @param {string} tangenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteTangen(tangenId: string, options?: any) {
        return CoursesApiFp(this.configuration).deleteTangen(tangenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した授業の情報の取得。クラスの属する学校の教員のみ実行可能。
     * @summary Get a Course
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourse(courseId: string, options?: any) {
        return CoursesApiFp(this.configuration).getCourse(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自身の授業一覧を取得。教師、生徒のみ。 年度でフィルターすべし
     * @summary Get my Courses
     * @param {number} [nendo] 授業の開始年
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getOwnCourses(nendo?: number, options?: any) {
        return CoursesApiFp(this.configuration).getOwnCourses(nendo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分の所属している学校の授業 できるだけnendoでフィルターすべし
     * @summary Get Own School Courses
     * @param {number} [nendo] 授業の開始年
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getOwnSchoolCourses(nendo?: number, options?: any) {
        return CoursesApiFp(this.configuration).getOwnSchoolCourses(nendo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分の学校の、自分が担当でない、アクティブな授業一覧(ページネーション)。nendoパラメタ必須。
     * @summary Get Own School Other Teachers Courses
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getOwnSchoolOtherTeachersCourses(nendo: number, options?: any) {
        return CoursesApiFp(this.configuration).getOwnSchoolOtherTeachersCourses(nendo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した授業の一部更新(学校は変えられない)。course.teachersの教員しか実行できない。classroomsとteachersはその学校のものである必要がある。
     * @summary Update a Course
     * @param {string} courseId 
     * @param {CourseUpdate} [courseUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public updateCourse(courseId: string, courseUpdate?: CourseUpdate, options?: any) {
        return CoursesApiFp(this.configuration).updateCourse(courseId, courseUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tangenを編集
     * @summary Translate To Proper Kana
     * @param {string} tangenId 
     * @param {InlineObject41} [inlineObject41] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public updateTangen(tangenId: string, inlineObject41?: InlineObject41, options?: any) {
        return CoursesApiFp(this.configuration).updateTangen(tangenId, inlineObject41, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 連絡のファイル作成
         * @summary Create Announcement File
         * @param {string} announcementId 
         * @param {InlineObject16} [inlineObject16] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnouncementFile: async (announcementId: string, inlineObject16?: InlineObject16, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('createAnnouncementFile', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}/files`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject16, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 文章をAIが生成する
         * @summary Generate Text Stream
         * @param {InlineObject44} [inlineObject44] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextStream: async (inlineObject44?: InlineObject44, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/generate_text/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject44, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AIからのメッセージを取得
         * @summary Get AI Message
         * @param {Array<AiChatMessage>} [aiChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiMsg: async (aiChatMessage?: Array<AiChatMessage>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ai_chat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiChatMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AIからのメッセージを取得
         * @summary Get AI Message Async Stream
         * @param {Array<AiChatMessage>} [aiChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiMsgAsyncStream: async (aiChatMessage?: Array<AiChatMessage>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ai_chat/async_stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiChatMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスごとの保護者が閲覧済みかどうかを配列で取得
         * @summary Get Announcement Patron  List With Read Status
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementPatronListWithReadStatus: async (announcementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('getAnnouncementPatronListWithReadStatus', 'announcementId', announcementId)
            const localVarPath = `/api/v1/announcements/{announcement_id}/views`
                .replace(`{${"announcement_id"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 連絡の未読数の取得。保護者のみ。
         * @summary Announcements Unread Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementsUnreadCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/announcements/unread_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業とキャンバスのIDから共同編集のグループのリストを取得
         * @summary List Canvas Collab Group
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvasCollabGroups: async (courseId: string, canvasId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCanvasCollabGroups', 'courseId', courseId)
            // verify required parameter 'canvasId' is not null or undefined
            assertParamExists('getCanvasCollabGroups', 'canvasId', canvasId)
            const localVarPath = `/api/v1/courses/{course_id}/canvases/{canvas_id}/groups`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"canvas_id"}}`, encodeURIComponent(String(canvasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスの生徒一覧を取得
         * @summary Your GET endpoint
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomStudents: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('getClassroomStudents', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教師専用。メールアドレス、連携先(google.com等)を含んだクラスのの児童一覧を取得
         * @summary Your GET endpoint
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomStudentsForPrivileged: async (classroomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('getClassroomStudentsForPrivileged', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students_for_privileged`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントの履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
         * @summary Get Post Histories
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentHistories: async (commentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentHistories', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}/histories`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の児童のコメントのネットワークのnodeとedgeを取得
         * @summary Get Course Comment Network
         * @param {string} courseId 
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCommentNetwork: async (courseId: string, inlineObject8?: InlineObject8, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseCommentNetwork', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/comment_network`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の児童達のコメントスタッツの取得
         * @summary Get Course Students Comment Stats
         * @param {string} courseId 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseStudentsCommentStats: async (courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseStudentsCommentStats', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/students/comment_stats`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の児童達の投稿スタッツの取得
         * @summary Get Course Students Post Stats
         * @param {string} courseId 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseStudentsPostStats: async (courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseStudentsPostStats', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/students/post_stats`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特徴語のトレンドを取得
         * @summary Get Course Word Trend
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseWordTrend: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseWordTrend', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/word_trend`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アプリの最新の情報を取得
         * @summary Get Latest App Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestAppInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/app_version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のアップロードした素材を取得
         * @summary Get Canvas Own Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCanvasMaterilals: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/canvas_materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のアップロードした素材を取得
         * @summary Get Canvas Own Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCanvases: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/canvases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのクラスと学校の情報をまとめた文字列を取得
         * @summary User Own Classrooms Properties String Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnProperties: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/classrooms_properties_string`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分の子供の投稿のうち、自分が未読なものの数を授業ごとに要素として配列で取得する
         * @summary Get  Own Students Unread Posts Count Per Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnStudentsUnreadPostsCountPerCourse: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/unread_own_students_posts_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿の履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
         * @summary Get Post Histories
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostHistories: async (postId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getPostHistories', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/histories`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教員限定。その教員の承認しうる保護者リクエストをクラスごとの配列で取得。
         * @summary Get Teachers Patron Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersPatronRequests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teachers/me/patron_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業ごとの未読投稿数を配列で取得する
         * @summary Get Unread Posts Count Per Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadPostsCountPerCourse: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/unread_posts_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 文章をAIが改善する
         * @summary Improve Text Stream
         * @param {InlineObject43} [inlineObject43] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        improveTextStream: async (inlineObject43?: InlineObject43, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/improve_text/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject43, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SlackAPIへのPOSTをProxyしてもらう
         * @summary Proxy Slack API
         * @param {InlineObject25} [inlineObject25] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProxySlackApi: async (inlineObject25?: InlineObject25, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/proxy_slack_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject25, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * textを、かなに変換して返す。ただし、そのユーザーの学年で既習のものは漢字のまま。
         * @summary Translate To Proper Kana
         * @param {InlineObject39} [inlineObject39] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateToProperKana: async (inlineObject39?: InlineObject39, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/proper_kana_text_translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject39, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Sales Chat Bot PassCode
         * @param {InlineObject58} [inlineObject58] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSalesChatBotPassCode: async (inlineObject58?: InlineObject58, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sales_chat_bot_pass_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject58, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 連絡のファイル作成
         * @summary Create Announcement File
         * @param {string} announcementId 
         * @param {InlineObject16} [inlineObject16] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnouncementFile(announcementId: string, inlineObject16?: InlineObject16, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnouncementFile(announcementId, inlineObject16, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 文章をAIが生成する
         * @summary Generate Text Stream
         * @param {InlineObject44} [inlineObject44] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTextStream(inlineObject44?: InlineObject44, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTextStream(inlineObject44, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * AIからのメッセージを取得
         * @summary Get AI Message
         * @param {Array<AiChatMessage>} [aiChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiMsg(aiChatMessage?: Array<AiChatMessage>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiMsg(aiChatMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * AIからのメッセージを取得
         * @summary Get AI Message Async Stream
         * @param {Array<AiChatMessage>} [aiChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiMsgAsyncStream(aiChatMessage?: Array<AiChatMessage>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiMsgAsyncStream(aiChatMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスごとの保護者が閲覧済みかどうかを配列で取得
         * @summary Get Announcement Patron  List With Read Status
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncementPatronListWithReadStatus(announcementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse20016>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncementPatronListWithReadStatus(announcementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 連絡の未読数の取得。保護者のみ。
         * @summary Announcements Unread Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncementsUnreadCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncementsUnreadCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業とキャンバスのIDから共同編集のグループのリストを取得
         * @summary List Canvas Collab Group
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvasCollabGroups(courseId: string, canvasId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanvasCollabGroupWithUsersRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvasCollabGroups(courseId, canvasId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスの生徒一覧を取得
         * @summary Your GET endpoint
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroomStudents(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentUserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroomStudents(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教師専用。メールアドレス、連携先(google.com等)を含んだクラスのの児童一覧を取得
         * @summary Your GET endpoint
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroomStudentsForPrivileged(classroomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentUserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroomStudentsForPrivileged(classroomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントの履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
         * @summary Get Post Histories
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentHistories(commentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentHistories(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の児童のコメントのネットワークのnodeとedgeを取得
         * @summary Get Course Comment Network
         * @param {string} courseId 
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCommentNetwork(courseId: string, inlineObject8?: InlineObject8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCommentNetwork(courseId, inlineObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の児童達のコメントスタッツの取得
         * @summary Get Course Students Comment Stats
         * @param {string} courseId 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseStudentsCommentStats(courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseStudentsCommentStats(courseId, uNKNOWNBASETYPE, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の児童達の投稿スタッツの取得
         * @summary Get Course Students Post Stats
         * @param {string} courseId 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseStudentsPostStats(courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseStudentsPostStats(courseId, uNKNOWNBASETYPE, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特徴語のトレンドを取得
         * @summary Get Course Word Trend
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseWordTrend(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse20018>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseWordTrend(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アプリの最新の情報を取得
         * @summary Get Latest App Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestAppInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestAppInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のアップロードした素材を取得
         * @summary Get Canvas Own Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnCanvasMaterilals(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanvasMaterialRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnCanvasMaterilals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のアップロードした素材を取得
         * @summary Get Canvas Own Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnCanvases(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CanvasMaterialRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnCanvases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのクラスと学校の情報をまとめた文字列を取得
         * @summary User Own Classrooms Properties String Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnProperties(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnProperties(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分の子供の投稿のうち、自分が未読なものの数を授業ごとに要素として配列で取得する
         * @summary Get  Own Students Unread Posts Count Per Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnStudentsUnreadPostsCountPerCourse(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2009>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnStudentsUnreadPostsCountPerCourse(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿の履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
         * @summary Get Post Histories
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostHistories(postId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostHistories(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教員限定。その教員の承認しうる保護者リクエストをクラスごとの配列で取得。
         * @summary Get Teachers Patron Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachersPatronRequests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse20014>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachersPatronRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業ごとの未読投稿数を配列で取得する
         * @summary Get Unread Posts Count Per Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadPostsCountPerCourse(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2004>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadPostsCountPerCourse(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 文章をAIが改善する
         * @summary Improve Text Stream
         * @param {InlineObject43} [inlineObject43] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async improveTextStream(inlineObject43?: InlineObject43, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.improveTextStream(inlineObject43, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SlackAPIへのPOSTをProxyしてもらう
         * @summary Proxy Slack API
         * @param {InlineObject25} [inlineObject25] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProxySlackApi(inlineObject25?: InlineObject25, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProxySlackApi(inlineObject25, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * textを、かなに変換して返す。ただし、そのユーザーの学年で既習のものは漢字のまま。
         * @summary Translate To Proper Kana
         * @param {InlineObject39} [inlineObject39] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translateToProperKana(inlineObject39?: InlineObject39, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translateToProperKana(inlineObject39, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate Sales Chat Bot PassCode
         * @param {InlineObject58} [inlineObject58] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSalesChatBotPassCode(inlineObject58?: InlineObject58, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSalesChatBotPassCode(inlineObject58, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 連絡のファイル作成
         * @summary Create Announcement File
         * @param {string} announcementId 
         * @param {InlineObject16} [inlineObject16] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnouncementFile(announcementId: string, inlineObject16?: InlineObject16, options?: any): AxiosPromise<void> {
            return localVarFp.createAnnouncementFile(announcementId, inlineObject16, options).then((request) => request(axios, basePath));
        },
        /**
         * 文章をAIが生成する
         * @summary Generate Text Stream
         * @param {InlineObject44} [inlineObject44] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextStream(inlineObject44?: InlineObject44, options?: any): AxiosPromise<object> {
            return localVarFp.generateTextStream(inlineObject44, options).then((request) => request(axios, basePath));
        },
        /**
         * AIからのメッセージを取得
         * @summary Get AI Message
         * @param {Array<AiChatMessage>} [aiChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiMsg(aiChatMessage?: Array<AiChatMessage>, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.getAiMsg(aiChatMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * AIからのメッセージを取得
         * @summary Get AI Message Async Stream
         * @param {Array<AiChatMessage>} [aiChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiMsgAsyncStream(aiChatMessage?: Array<AiChatMessage>, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.getAiMsgAsyncStream(aiChatMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスごとの保護者が閲覧済みかどうかを配列で取得
         * @summary Get Announcement Patron  List With Read Status
         * @param {string} announcementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementPatronListWithReadStatus(announcementId: string, options?: any): AxiosPromise<Array<InlineResponse20016>> {
            return localVarFp.getAnnouncementPatronListWithReadStatus(announcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 連絡の未読数の取得。保護者のみ。
         * @summary Announcements Unread Count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementsUnreadCount(options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.getAnnouncementsUnreadCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 授業とキャンバスのIDから共同編集のグループのリストを取得
         * @summary List Canvas Collab Group
         * @param {string} courseId 
         * @param {string} canvasId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvasCollabGroups(courseId: string, canvasId: string, options?: any): AxiosPromise<Array<CanvasCollabGroupWithUsersRead>> {
            return localVarFp.getCanvasCollabGroups(courseId, canvasId, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスの生徒一覧を取得
         * @summary Your GET endpoint
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomStudents(classroomId: string, options?: any): AxiosPromise<Array<StudentUserRead>> {
            return localVarFp.getClassroomStudents(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 教師専用。メールアドレス、連携先(google.com等)を含んだクラスのの児童一覧を取得
         * @summary Your GET endpoint
         * @param {string} classroomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomStudentsForPrivileged(classroomId: string, options?: any): AxiosPromise<Array<StudentUserRead>> {
            return localVarFp.getClassroomStudentsForPrivileged(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントの履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
         * @summary Get Post Histories
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentHistories(commentId: string, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.getCommentHistories(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の児童のコメントのネットワークのnodeとedgeを取得
         * @summary Get Course Comment Network
         * @param {string} courseId 
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCommentNetwork(courseId: string, inlineObject8?: InlineObject8, options?: any): AxiosPromise<void> {
            return localVarFp.getCourseCommentNetwork(courseId, inlineObject8, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の児童達のコメントスタッツの取得
         * @summary Get Course Students Comment Stats
         * @param {string} courseId 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseStudentsCommentStats(courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<void> {
            return localVarFp.getCourseStudentsCommentStats(courseId, uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の児童達の投稿スタッツの取得
         * @summary Get Course Students Post Stats
         * @param {string} courseId 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseStudentsPostStats(courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<void> {
            return localVarFp.getCourseStudentsPostStats(courseId, uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         * 特徴語のトレンドを取得
         * @summary Get Course Word Trend
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseWordTrend(courseId: string, options?: any): AxiosPromise<Array<InlineResponse20018>> {
            return localVarFp.getCourseWordTrend(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * アプリの最新の情報を取得
         * @summary Get Latest App Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestAppInfo(options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.getLatestAppInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のアップロードした素材を取得
         * @summary Get Canvas Own Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCanvasMaterilals(options?: any): AxiosPromise<Array<CanvasMaterialRead>> {
            return localVarFp.getOwnCanvasMaterilals(options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のアップロードした素材を取得
         * @summary Get Canvas Own Materials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnCanvases(options?: any): AxiosPromise<Array<CanvasMaterialRead>> {
            return localVarFp.getOwnCanvases(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのクラスと学校の情報をまとめた文字列を取得
         * @summary User Own Classrooms Properties String Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnProperties(options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.getOwnProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 自分の子供の投稿のうち、自分が未読なものの数を授業ごとに要素として配列で取得する
         * @summary Get  Own Students Unread Posts Count Per Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnStudentsUnreadPostsCountPerCourse(options?: any): AxiosPromise<Array<InlineResponse2009>> {
            return localVarFp.getOwnStudentsUnreadPostsCountPerCourse(options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿の履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
         * @summary Get Post Histories
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostHistories(postId: string, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.getPostHistories(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 教員限定。その教員の承認しうる保護者リクエストをクラスごとの配列で取得。
         * @summary Get Teachers Patron Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersPatronRequests(options?: any): AxiosPromise<Array<InlineResponse20014>> {
            return localVarFp.getTeachersPatronRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 授業ごとの未読投稿数を配列で取得する
         * @summary Get Unread Posts Count Per Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadPostsCountPerCourse(options?: any): AxiosPromise<Array<InlineResponse2004>> {
            return localVarFp.getUnreadPostsCountPerCourse(options).then((request) => request(axios, basePath));
        },
        /**
         * 文章をAIが改善する
         * @summary Improve Text Stream
         * @param {InlineObject43} [inlineObject43] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        improveTextStream(inlineObject43?: InlineObject43, options?: any): AxiosPromise<object> {
            return localVarFp.improveTextStream(inlineObject43, options).then((request) => request(axios, basePath));
        },
        /**
         * SlackAPIへのPOSTをProxyしてもらう
         * @summary Proxy Slack API
         * @param {InlineObject25} [inlineObject25] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProxySlackApi(inlineObject25?: InlineObject25, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.postProxySlackApi(inlineObject25, options).then((request) => request(axios, basePath));
        },
        /**
         * textを、かなに変換して返す。ただし、そのユーザーの学年で既習のものは漢字のまま。
         * @summary Translate To Proper Kana
         * @param {InlineObject39} [inlineObject39] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translateToProperKana(inlineObject39?: InlineObject39, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.translateToProperKana(inlineObject39, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Sales Chat Bot PassCode
         * @param {InlineObject58} [inlineObject58] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSalesChatBotPassCode(inlineObject58?: InlineObject58, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.validateSalesChatBotPassCode(inlineObject58, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 連絡のファイル作成
     * @summary Create Announcement File
     * @param {string} announcementId 
     * @param {InlineObject16} [inlineObject16] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAnnouncementFile(announcementId: string, inlineObject16?: InlineObject16, options?: any) {
        return DefaultApiFp(this.configuration).createAnnouncementFile(announcementId, inlineObject16, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 文章をAIが生成する
     * @summary Generate Text Stream
     * @param {InlineObject44} [inlineObject44] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateTextStream(inlineObject44?: InlineObject44, options?: any) {
        return DefaultApiFp(this.configuration).generateTextStream(inlineObject44, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AIからのメッセージを取得
     * @summary Get AI Message
     * @param {Array<AiChatMessage>} [aiChatMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiMsg(aiChatMessage?: Array<AiChatMessage>, options?: any) {
        return DefaultApiFp(this.configuration).getAiMsg(aiChatMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AIからのメッセージを取得
     * @summary Get AI Message Async Stream
     * @param {Array<AiChatMessage>} [aiChatMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAiMsgAsyncStream(aiChatMessage?: Array<AiChatMessage>, options?: any) {
        return DefaultApiFp(this.configuration).getAiMsgAsyncStream(aiChatMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスごとの保護者が閲覧済みかどうかを配列で取得
     * @summary Get Announcement Patron  List With Read Status
     * @param {string} announcementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnouncementPatronListWithReadStatus(announcementId: string, options?: any) {
        return DefaultApiFp(this.configuration).getAnnouncementPatronListWithReadStatus(announcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 連絡の未読数の取得。保護者のみ。
     * @summary Announcements Unread Count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnouncementsUnreadCount(options?: any) {
        return DefaultApiFp(this.configuration).getAnnouncementsUnreadCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業とキャンバスのIDから共同編集のグループのリストを取得
     * @summary List Canvas Collab Group
     * @param {string} courseId 
     * @param {string} canvasId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCanvasCollabGroups(courseId: string, canvasId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCanvasCollabGroups(courseId, canvasId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスの生徒一覧を取得
     * @summary Your GET endpoint
     * @param {string} classroomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassroomStudents(classroomId: string, options?: any) {
        return DefaultApiFp(this.configuration).getClassroomStudents(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教師専用。メールアドレス、連携先(google.com等)を含んだクラスのの児童一覧を取得
     * @summary Your GET endpoint
     * @param {string} classroomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassroomStudentsForPrivileged(classroomId: string, options?: any) {
        return DefaultApiFp(this.configuration).getClassroomStudentsForPrivileged(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コメントの履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
     * @summary Get Post Histories
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommentHistories(commentId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCommentHistories(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の児童のコメントのネットワークのnodeとedgeを取得
     * @summary Get Course Comment Network
     * @param {string} courseId 
     * @param {InlineObject8} [inlineObject8] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCourseCommentNetwork(courseId: string, inlineObject8?: InlineObject8, options?: any) {
        return DefaultApiFp(this.configuration).getCourseCommentNetwork(courseId, inlineObject8, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の児童達のコメントスタッツの取得
     * @summary Get Course Students Comment Stats
     * @param {string} courseId 
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCourseStudentsCommentStats(courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any) {
        return DefaultApiFp(this.configuration).getCourseStudentsCommentStats(courseId, uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の児童達の投稿スタッツの取得
     * @summary Get Course Students Post Stats
     * @param {string} courseId 
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCourseStudentsPostStats(courseId: string, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any) {
        return DefaultApiFp(this.configuration).getCourseStudentsPostStats(courseId, uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特徴語のトレンドを取得
     * @summary Get Course Word Trend
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCourseWordTrend(courseId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCourseWordTrend(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アプリの最新の情報を取得
     * @summary Get Latest App Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLatestAppInfo(options?: any) {
        return DefaultApiFp(this.configuration).getLatestAppInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分のアップロードした素材を取得
     * @summary Get Canvas Own Materials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOwnCanvasMaterilals(options?: any) {
        return DefaultApiFp(this.configuration).getOwnCanvasMaterilals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分のアップロードした素材を取得
     * @summary Get Canvas Own Materials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOwnCanvases(options?: any) {
        return DefaultApiFp(this.configuration).getOwnCanvases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーのクラスと学校の情報をまとめた文字列を取得
     * @summary User Own Classrooms Properties String Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOwnProperties(options?: any) {
        return DefaultApiFp(this.configuration).getOwnProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分の子供の投稿のうち、自分が未読なものの数を授業ごとに要素として配列で取得する
     * @summary Get  Own Students Unread Posts Count Per Course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOwnStudentsUnreadPostsCountPerCourse(options?: any) {
        return DefaultApiFp(this.configuration).getOwnStudentsUnreadPostsCountPerCourse(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿の履歴一覧を取得。削除済みの投稿に関しては、教師のみ取得可能。
     * @summary Get Post Histories
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPostHistories(postId: string, options?: any) {
        return DefaultApiFp(this.configuration).getPostHistories(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教員限定。その教員の承認しうる保護者リクエストをクラスごとの配列で取得。
     * @summary Get Teachers Patron Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTeachersPatronRequests(options?: any) {
        return DefaultApiFp(this.configuration).getTeachersPatronRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業ごとの未読投稿数を配列で取得する
     * @summary Get Unread Posts Count Per Course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUnreadPostsCountPerCourse(options?: any) {
        return DefaultApiFp(this.configuration).getUnreadPostsCountPerCourse(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 文章をAIが改善する
     * @summary Improve Text Stream
     * @param {InlineObject43} [inlineObject43] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public improveTextStream(inlineObject43?: InlineObject43, options?: any) {
        return DefaultApiFp(this.configuration).improveTextStream(inlineObject43, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SlackAPIへのPOSTをProxyしてもらう
     * @summary Proxy Slack API
     * @param {InlineObject25} [inlineObject25] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProxySlackApi(inlineObject25?: InlineObject25, options?: any) {
        return DefaultApiFp(this.configuration).postProxySlackApi(inlineObject25, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * textを、かなに変換して返す。ただし、そのユーザーの学年で既習のものは漢字のまま。
     * @summary Translate To Proper Kana
     * @param {InlineObject39} [inlineObject39] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public translateToProperKana(inlineObject39?: InlineObject39, options?: any) {
        return DefaultApiFp(this.configuration).translateToProperKana(inlineObject39, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Sales Chat Bot PassCode
     * @param {InlineObject58} [inlineObject58] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateSalesChatBotPassCode(inlineObject58?: InlineObject58, options?: any) {
        return DefaultApiFp(this.configuration).validateSalesChatBotPassCode(inlineObject58, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deviceの削除。ログアウト時にリクエストする。
         * @summary Delete Device by Expo Push Token
         * @param {string} expoPushToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceByExpoPushToken: async (expoPushToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'expoPushToken' is not null or undefined
            assertParamExists('deleteDeviceByExpoPushToken', 'expoPushToken', expoPushToken)
            const localVarPath = `/api/v1/users/me/devices/{expo_push_token}`
                .replace(`{${"expo_push_token"}}`, encodeURIComponent(String(expoPushToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deviceの登録をする。今はexpo_push_tokenの登録のみ
         * @summary Register Expo Push Token
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerExpoPushToken: async (inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * Deviceの削除。ログアウト時にリクエストする。
         * @summary Delete Device by Expo Push Token
         * @param {string} expoPushToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceByExpoPushToken(expoPushToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceByExpoPushToken(expoPushToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deviceの登録をする。今はexpo_push_tokenの登録のみ
         * @summary Register Expo Push Token
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerExpoPushToken(inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerExpoPushToken(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * Deviceの削除。ログアウト時にリクエストする。
         * @summary Delete Device by Expo Push Token
         * @param {string} expoPushToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceByExpoPushToken(expoPushToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceByExpoPushToken(expoPushToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Deviceの登録をする。今はexpo_push_tokenの登録のみ
         * @summary Register Expo Push Token
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerExpoPushToken(inlineObject2?: InlineObject2, options?: any): AxiosPromise<void> {
            return localVarFp.registerExpoPushToken(inlineObject2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * Deviceの削除。ログアウト時にリクエストする。
     * @summary Delete Device by Expo Push Token
     * @param {string} expoPushToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDeviceByExpoPushToken(expoPushToken: string, options?: any) {
        return DeviceApiFp(this.configuration).deleteDeviceByExpoPushToken(expoPushToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deviceの登録をする。今はexpo_push_tokenの登録のみ
     * @summary Register Expo Push Token
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public registerExpoPushToken(inlineObject2?: InlineObject2, options?: any) {
        return DeviceApiFp(this.configuration).registerExpoPushToken(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GradesApi - axios parameter creator
 * @export
 */
export const GradesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 所見を生成もしくは既に存在したら更新
         * @summary Create Or Update Shoken
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {string} subjectValue 
         * @param {string} [term] 
         * @param {InlineObject35} [inlineObject35] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateShoken: async (classroomId: string, studentId: string, subjectValue: string, term?: string, inlineObject35?: InlineObject35, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('createOrUpdateShoken', 'classroomId', classroomId)
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('createOrUpdateShoken', 'studentId', studentId)
            // verify required parameter 'subjectValue' is not null or undefined
            assertParamExists('createOrUpdateShoken', 'subjectValue', subjectValue)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students/{student_id}/shokens`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)))
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subjectValue !== undefined) {
                localVarQueryParameter['subject_value'] = subjectValue;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject35, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 総合所見を生成もしくは既に存在したら更新
         * @summary Create Or Update Sogo Shoken
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {string} [term] 
         * @param {InlineObject36} [inlineObject36] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSogoShoken: async (classroomId: string, studentId: string, term?: string, inlineObject36?: InlineObject36, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('createOrUpdateSogoShoken', 'classroomId', classroomId)
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('createOrUpdateSogoShoken', 'studentId', studentId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students/{student_id}/sogo_shokens`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)))
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject36, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 評価の削除
         * @summary Delete Post Grade
         * @param {string} gradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostGrade: async (gradeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradeId' is not null or undefined
            assertParamExists('deletePostGrade', 'gradeId', gradeId)
            const localVarPath = `/api/v1/evaluations/grades/{grade_id}`
                .replace(`{${"grade_id"}}`, encodeURIComponent(String(gradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿の評価を観点ごとにPut
         * @summary Put Post Grade
         * @param {string} postId 
         * @param {InlineObject59} [inlineObject59] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPostGrade: async (postId: string, inlineObject59?: InlineObject59, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('putPostGrade', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/grades`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject59, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 所見生成のリクエスト クラス単位 クエリストリングにterm
         * @summary Request Shoken Generate
         * @param {string} classroomId 
         * @param {InlineObject33} [inlineObject33] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestShokenGenerate: async (classroomId: string, inlineObject33?: InlineObject33, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('requestShokenGenerate', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/shokens`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject33, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 総合所見生成のリクエスト クラス単位 クエリストリングにterm
         * @summary Request Shoken Generate
         * @param {string} classroomId 
         * @param {InlineObject34} [inlineObject34] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestSogoShokenGenerate: async (classroomId: string, inlineObject34?: InlineObject34, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('requestSogoShokenGenerate', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/sogo_shokens`
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject34, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GradesApi - functional programming interface
 * @export
 */
export const GradesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GradesApiAxiosParamCreator(configuration)
    return {
        /**
         * 所見を生成もしくは既に存在したら更新
         * @summary Create Or Update Shoken
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {string} subjectValue 
         * @param {string} [term] 
         * @param {InlineObject35} [inlineObject35] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateShoken(classroomId: string, studentId: string, subjectValue: string, term?: string, inlineObject35?: InlineObject35, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateShoken(classroomId, studentId, subjectValue, term, inlineObject35, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 総合所見を生成もしくは既に存在したら更新
         * @summary Create Or Update Sogo Shoken
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {string} [term] 
         * @param {InlineObject36} [inlineObject36] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateSogoShoken(classroomId: string, studentId: string, term?: string, inlineObject36?: InlineObject36, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateSogoShoken(classroomId, studentId, term, inlineObject36, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 評価の削除
         * @summary Delete Post Grade
         * @param {string} gradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostGrade(gradeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePostGrade(gradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿の評価を観点ごとにPut
         * @summary Put Post Grade
         * @param {string} postId 
         * @param {InlineObject59} [inlineObject59] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPostGrade(postId: string, inlineObject59?: InlineObject59, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GradeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPostGrade(postId, inlineObject59, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 所見生成のリクエスト クラス単位 クエリストリングにterm
         * @summary Request Shoken Generate
         * @param {string} classroomId 
         * @param {InlineObject33} [inlineObject33] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestShokenGenerate(classroomId: string, inlineObject33?: InlineObject33, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestShokenGenerate(classroomId, inlineObject33, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 総合所見生成のリクエスト クラス単位 クエリストリングにterm
         * @summary Request Shoken Generate
         * @param {string} classroomId 
         * @param {InlineObject34} [inlineObject34] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestSogoShokenGenerate(classroomId: string, inlineObject34?: InlineObject34, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestSogoShokenGenerate(classroomId, inlineObject34, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GradesApi - factory interface
 * @export
 */
export const GradesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GradesApiFp(configuration)
    return {
        /**
         * 所見を生成もしくは既に存在したら更新
         * @summary Create Or Update Shoken
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {string} subjectValue 
         * @param {string} [term] 
         * @param {InlineObject35} [inlineObject35] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateShoken(classroomId: string, studentId: string, subjectValue: string, term?: string, inlineObject35?: InlineObject35, options?: any): AxiosPromise<string> {
            return localVarFp.createOrUpdateShoken(classroomId, studentId, subjectValue, term, inlineObject35, options).then((request) => request(axios, basePath));
        },
        /**
         * 総合所見を生成もしくは既に存在したら更新
         * @summary Create Or Update Sogo Shoken
         * @param {string} classroomId 
         * @param {string} studentId 
         * @param {string} [term] 
         * @param {InlineObject36} [inlineObject36] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateSogoShoken(classroomId: string, studentId: string, term?: string, inlineObject36?: InlineObject36, options?: any): AxiosPromise<string> {
            return localVarFp.createOrUpdateSogoShoken(classroomId, studentId, term, inlineObject36, options).then((request) => request(axios, basePath));
        },
        /**
         * 評価の削除
         * @summary Delete Post Grade
         * @param {string} gradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostGrade(gradeId: string, options?: any): AxiosPromise<InlineResponse20030> {
            return localVarFp.deletePostGrade(gradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿の評価を観点ごとにPut
         * @summary Put Post Grade
         * @param {string} postId 
         * @param {InlineObject59} [inlineObject59] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPostGrade(postId: string, inlineObject59?: InlineObject59, options?: any): AxiosPromise<GradeRead> {
            return localVarFp.putPostGrade(postId, inlineObject59, options).then((request) => request(axios, basePath));
        },
        /**
         * 所見生成のリクエスト クラス単位 クエリストリングにterm
         * @summary Request Shoken Generate
         * @param {string} classroomId 
         * @param {InlineObject33} [inlineObject33] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestShokenGenerate(classroomId: string, inlineObject33?: InlineObject33, options?: any): AxiosPromise<string> {
            return localVarFp.requestShokenGenerate(classroomId, inlineObject33, options).then((request) => request(axios, basePath));
        },
        /**
         * 総合所見生成のリクエスト クラス単位 クエリストリングにterm
         * @summary Request Shoken Generate
         * @param {string} classroomId 
         * @param {InlineObject34} [inlineObject34] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestSogoShokenGenerate(classroomId: string, inlineObject34?: InlineObject34, options?: any): AxiosPromise<string> {
            return localVarFp.requestSogoShokenGenerate(classroomId, inlineObject34, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GradesApi - object-oriented interface
 * @export
 * @class GradesApi
 * @extends {BaseAPI}
 */
export class GradesApi extends BaseAPI {
    /**
     * 所見を生成もしくは既に存在したら更新
     * @summary Create Or Update Shoken
     * @param {string} classroomId 
     * @param {string} studentId 
     * @param {string} subjectValue 
     * @param {string} [term] 
     * @param {InlineObject35} [inlineObject35] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GradesApi
     */
    public createOrUpdateShoken(classroomId: string, studentId: string, subjectValue: string, term?: string, inlineObject35?: InlineObject35, options?: any) {
        return GradesApiFp(this.configuration).createOrUpdateShoken(classroomId, studentId, subjectValue, term, inlineObject35, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 総合所見を生成もしくは既に存在したら更新
     * @summary Create Or Update Sogo Shoken
     * @param {string} classroomId 
     * @param {string} studentId 
     * @param {string} [term] 
     * @param {InlineObject36} [inlineObject36] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GradesApi
     */
    public createOrUpdateSogoShoken(classroomId: string, studentId: string, term?: string, inlineObject36?: InlineObject36, options?: any) {
        return GradesApiFp(this.configuration).createOrUpdateSogoShoken(classroomId, studentId, term, inlineObject36, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 評価の削除
     * @summary Delete Post Grade
     * @param {string} gradeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GradesApi
     */
    public deletePostGrade(gradeId: string, options?: any) {
        return GradesApiFp(this.configuration).deletePostGrade(gradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿の評価を観点ごとにPut
     * @summary Put Post Grade
     * @param {string} postId 
     * @param {InlineObject59} [inlineObject59] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GradesApi
     */
    public putPostGrade(postId: string, inlineObject59?: InlineObject59, options?: any) {
        return GradesApiFp(this.configuration).putPostGrade(postId, inlineObject59, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 所見生成のリクエスト クラス単位 クエリストリングにterm
     * @summary Request Shoken Generate
     * @param {string} classroomId 
     * @param {InlineObject33} [inlineObject33] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GradesApi
     */
    public requestShokenGenerate(classroomId: string, inlineObject33?: InlineObject33, options?: any) {
        return GradesApiFp(this.configuration).requestShokenGenerate(classroomId, inlineObject33, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 総合所見生成のリクエスト クラス単位 クエリストリングにterm
     * @summary Request Shoken Generate
     * @param {string} classroomId 
     * @param {InlineObject34} [inlineObject34] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GradesApi
     */
    public requestSogoShokenGenerate(classroomId: string, inlineObject34?: InlineObject34, options?: any) {
        return GradesApiFp(this.configuration).requestSogoShokenGenerate(classroomId, inlineObject34, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーをグループに追加する
         * @summary Add User To Group
         * @param {string} groupId 
         * @param {InlineObject46} [inlineObject46] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToGroup: async (groupId: string, inlineObject46?: InlineObject46, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('addUserToGroup', 'groupId', groupId)
            const localVarPath = `/api/v1/groups/{group_id}/add_user`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject46, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * グループの作成
         * @summary Create Group
         * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (groupCreateOrUpdate?: GroupCreateOrUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupCreateOrUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * グループの削除
         * @summary Delete Group
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deleteGroup', 'groupId', groupId)
            const localVarPath = `/api/v1/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 参加コードをPOSTしてグループに参加する
         * @summary Join Group
         * @param {InlineObject42} [inlineObject42] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinGroup: async (inlineObject42?: InlineObject42, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject42, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーをグループから除く
         * @summary Remove User From Group
         * @param {string} groupId 
         * @param {InlineObject45} [inlineObject45] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromGroup: async (groupId: string, inlineObject45?: InlineObject45, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('removeUserFromGroup', 'groupId', groupId)
            const localVarPath = `/api/v1/groups/{group_id}/remove_user`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject45, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * グループの更新
         * @summary Create Group
         * @param {string} groupId 
         * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (groupId: string, groupCreateOrUpdate?: GroupCreateOrUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateGroup', 'groupId', groupId)
            const localVarPath = `/api/v1/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupCreateOrUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーをグループに追加する
         * @summary Add User To Group
         * @param {string} groupId 
         * @param {InlineObject46} [inlineObject46] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToGroup(groupId: string, inlineObject46?: InlineObject46, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToGroup(groupId, inlineObject46, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * グループの作成
         * @summary Create Group
         * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(groupCreateOrUpdate?: GroupCreateOrUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(groupCreateOrUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * グループの削除
         * @summary Delete Group
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 参加コードをPOSTしてグループに参加する
         * @summary Join Group
         * @param {InlineObject42} [inlineObject42] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinGroup(inlineObject42?: InlineObject42, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupSimpleRead & object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinGroup(inlineObject42, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーをグループから除く
         * @summary Remove User From Group
         * @param {string} groupId 
         * @param {InlineObject45} [inlineObject45] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromGroup(groupId: string, inlineObject45?: InlineObject45, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromGroup(groupId, inlineObject45, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * グループの更新
         * @summary Create Group
         * @param {string} groupId 
         * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(groupId: string, groupCreateOrUpdate?: GroupCreateOrUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(groupId, groupCreateOrUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * ユーザーをグループに追加する
         * @summary Add User To Group
         * @param {string} groupId 
         * @param {InlineObject46} [inlineObject46] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToGroup(groupId: string, inlineObject46?: InlineObject46, options?: any): AxiosPromise<void> {
            return localVarFp.addUserToGroup(groupId, inlineObject46, options).then((request) => request(axios, basePath));
        },
        /**
         * グループの作成
         * @summary Create Group
         * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(groupCreateOrUpdate?: GroupCreateOrUpdate, options?: any): AxiosPromise<InlineResponse2015> {
            return localVarFp.createGroup(groupCreateOrUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * グループの削除
         * @summary Delete Group
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId: string, options?: any): AxiosPromise<InlineResponse20026> {
            return localVarFp.deleteGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 参加コードをPOSTしてグループに参加する
         * @summary Join Group
         * @param {InlineObject42} [inlineObject42] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinGroup(inlineObject42?: InlineObject42, options?: any): AxiosPromise<GroupSimpleRead & object> {
            return localVarFp.joinGroup(inlineObject42, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーをグループから除く
         * @summary Remove User From Group
         * @param {string} groupId 
         * @param {InlineObject45} [inlineObject45] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromGroup(groupId: string, inlineObject45?: InlineObject45, options?: any): AxiosPromise<void> {
            return localVarFp.removeUserFromGroup(groupId, inlineObject45, options).then((request) => request(axios, basePath));
        },
        /**
         * グループの更新
         * @summary Create Group
         * @param {string} groupId 
         * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupId: string, groupCreateOrUpdate?: GroupCreateOrUpdate, options?: any): AxiosPromise<object> {
            return localVarFp.updateGroup(groupId, groupCreateOrUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * ユーザーをグループに追加する
     * @summary Add User To Group
     * @param {string} groupId 
     * @param {InlineObject46} [inlineObject46] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public addUserToGroup(groupId: string, inlineObject46?: InlineObject46, options?: any) {
        return GroupsApiFp(this.configuration).addUserToGroup(groupId, inlineObject46, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * グループの作成
     * @summary Create Group
     * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public createGroup(groupCreateOrUpdate?: GroupCreateOrUpdate, options?: any) {
        return GroupsApiFp(this.configuration).createGroup(groupCreateOrUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * グループの削除
     * @summary Delete Group
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public deleteGroup(groupId: string, options?: any) {
        return GroupsApiFp(this.configuration).deleteGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 参加コードをPOSTしてグループに参加する
     * @summary Join Group
     * @param {InlineObject42} [inlineObject42] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public joinGroup(inlineObject42?: InlineObject42, options?: any) {
        return GroupsApiFp(this.configuration).joinGroup(inlineObject42, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーをグループから除く
     * @summary Remove User From Group
     * @param {string} groupId 
     * @param {InlineObject45} [inlineObject45] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public removeUserFromGroup(groupId: string, inlineObject45?: InlineObject45, options?: any) {
        return GroupsApiFp(this.configuration).removeUserFromGroup(groupId, inlineObject45, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * グループの更新
     * @summary Create Group
     * @param {string} groupId 
     * @param {GroupCreateOrUpdate} [groupCreateOrUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public updateGroup(groupId: string, groupCreateOrUpdate?: GroupCreateOrUpdate, options?: any) {
        return GroupsApiFp(this.configuration).updateGroup(groupId, groupCreateOrUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MoodTrackersApi - axios parameter creator
 * @export
 */
export const MoodTrackersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 現在ログインしているユーザーの今日の心の天気を追加する
         * @param {InlineObject56} inlineObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnMood: async (inlineObject56: InlineObject56, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject56' is not null or undefined
            assertParamExists('createOwnMood', 'inlineObject56', inlineObject56)
            const localVarPath = `/api/v1/users/me/moods/today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject56, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーの心の天気を部分更新する
         * @param {string} moodId 更新する心の天気のUUID
         * @param {InlineObject57} inlineObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnMood: async (moodId: string, inlineObject57: InlineObject57, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'moodId' is not null or undefined
            assertParamExists('updateOwnMood', 'moodId', moodId)
            // verify required parameter 'inlineObject57' is not null or undefined
            assertParamExists('updateOwnMood', 'inlineObject57', inlineObject57)
            const localVarPath = `/api/v1/users/me/moods/{mood_id}`
                .replace(`{${"mood_id"}}`, encodeURIComponent(String(moodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject57, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MoodTrackersApi - functional programming interface
 * @export
 */
export const MoodTrackersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MoodTrackersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 現在ログインしているユーザーの今日の心の天気を追加する
         * @param {InlineObject56} inlineObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOwnMood(inlineObject56: InlineObject56, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMood>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOwnMood(inlineObject56, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーの心の天気を部分更新する
         * @param {string} moodId 更新する心の天気のUUID
         * @param {InlineObject57} inlineObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnMood(moodId: string, inlineObject57: InlineObject57, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMood>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnMood(moodId, inlineObject57, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MoodTrackersApi - factory interface
 * @export
 */
export const MoodTrackersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MoodTrackersApiFp(configuration)
    return {
        /**
         * 
         * @summary 現在ログインしているユーザーの今日の心の天気を追加する
         * @param {InlineObject56} inlineObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnMood(inlineObject56: InlineObject56, options?: any): AxiosPromise<UserMood> {
            return localVarFp.createOwnMood(inlineObject56, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーの心の天気を部分更新する
         * @param {string} moodId 更新する心の天気のUUID
         * @param {InlineObject57} inlineObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnMood(moodId: string, inlineObject57: InlineObject57, options?: any): AxiosPromise<UserMood> {
            return localVarFp.updateOwnMood(moodId, inlineObject57, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MoodTrackersApi - object-oriented interface
 * @export
 * @class MoodTrackersApi
 * @extends {BaseAPI}
 */
export class MoodTrackersApi extends BaseAPI {
    /**
     * 
     * @summary 現在ログインしているユーザーの今日の心の天気を追加する
     * @param {InlineObject56} inlineObject56 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoodTrackersApi
     */
    public createOwnMood(inlineObject56: InlineObject56, options?: any) {
        return MoodTrackersApiFp(this.configuration).createOwnMood(inlineObject56, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーの心の天気を部分更新する
     * @param {string} moodId 更新する心の天気のUUID
     * @param {InlineObject57} inlineObject57 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoodTrackersApi
     */
    public updateOwnMood(moodId: string, inlineObject57: InlineObject57, options?: any) {
        return MoodTrackersApiFp(this.configuration).updateOwnMood(moodId, inlineObject57, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 授業内通知を取得するためのエンドポイント
         * @summary Get Course Common Notifications
         * @param {string} [courseId] 授業ID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCommonNotifications: async (courseId?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/course_common_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿と通知ボトムタブに反映させる数を取得。授業ごとではない。
         * @summary Get New Arrival Exists State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewArrivalExistsState: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/new_arrival_exists_state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 授業ごとの未読の通知数を取得
         * @summary Get Unread Notification Count of Each Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationCountOfEachCourse: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/notifications/unread_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通知を取得するエンドポイント
         * @summary Get User Notifications
         * @param {string} courseId course id
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications: async (courseId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getUserNotifications', 'courseId', courseId)
            const localVarPath = `/api/v1/users/me/user_specific_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の授業共通通知を閲覧したことを伝えるためのエンドポイント
         * @summary Register course common notification view event
         * @param {string} courseId 授業ID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCourseCommonNotificationView: async (courseId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('registerCourseCommonNotificationView', 'courseId', courseId)
            const localVarPath = `/api/v1/users/me/course_common_notifications/viewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業のユーザ固有通知を閲覧したことを伝えるためのエンドポイント
         * @summary Register user specific notification view event
         * @param {string} courseId 授業ID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserSpecificNotificationView: async (courseId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('registerUserSpecificNotificationView', 'courseId', courseId)
            const localVarPath = `/api/v1/users/me/user_specific_notifications/viewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 授業内通知を取得するためのエンドポイント
         * @summary Get Course Common Notifications
         * @param {string} [courseId] 授業ID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCommonNotifications(courseId?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseCommonNotificationListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCommonNotifications(courseId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿と通知ボトムタブに反映させる数を取得。授業ごとではない。
         * @summary Get New Arrival Exists State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewArrivalExistsState(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewArrivalState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewArrivalExistsState(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 授業ごとの未読の通知数を取得
         * @summary Get Unread Notification Count of Each Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationCountOfEachCourse(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationUnreadCountOfCourse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotificationCountOfEachCourse(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 通知を取得するエンドポイント
         * @summary Get User Notifications
         * @param {string} courseId course id
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotifications(courseId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSpecificNotificationListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotifications(courseId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の授業共通通知を閲覧したことを伝えるためのエンドポイント
         * @summary Register course common notification view event
         * @param {string} courseId 授業ID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCourseCommonNotificationView(courseId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCourseCommonNotificationView(courseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業のユーザ固有通知を閲覧したことを伝えるためのエンドポイント
         * @summary Register user specific notification view event
         * @param {string} courseId 授業ID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserSpecificNotificationView(courseId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserSpecificNotificationView(courseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 授業内通知を取得するためのエンドポイント
         * @summary Get Course Common Notifications
         * @param {string} [courseId] 授業ID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCommonNotifications(courseId?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<CourseCommonNotificationListPaginated> {
            return localVarFp.getCourseCommonNotifications(courseId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿と通知ボトムタブに反映させる数を取得。授業ごとではない。
         * @summary Get New Arrival Exists State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewArrivalExistsState(options?: any): AxiosPromise<NewArrivalState> {
            return localVarFp.getNewArrivalExistsState(options).then((request) => request(axios, basePath));
        },
        /**
         * 授業ごとの未読の通知数を取得
         * @summary Get Unread Notification Count of Each Course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationCountOfEachCourse(options?: any): AxiosPromise<Array<NotificationUnreadCountOfCourse>> {
            return localVarFp.getUnreadNotificationCountOfEachCourse(options).then((request) => request(axios, basePath));
        },
        /**
         * 通知を取得するエンドポイント
         * @summary Get User Notifications
         * @param {string} courseId course id
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications(courseId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<UserSpecificNotificationListPaginated> {
            return localVarFp.getUserNotifications(courseId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の授業共通通知を閲覧したことを伝えるためのエンドポイント
         * @summary Register course common notification view event
         * @param {string} courseId 授業ID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCourseCommonNotificationView(courseId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.registerCourseCommonNotificationView(courseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業のユーザ固有通知を閲覧したことを伝えるためのエンドポイント
         * @summary Register user specific notification view event
         * @param {string} courseId 授業ID
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserSpecificNotificationView(courseId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.registerUserSpecificNotificationView(courseId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 授業内通知を取得するためのエンドポイント
     * @summary Get Course Common Notifications
     * @param {string} [courseId] 授業ID
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getCourseCommonNotifications(courseId?: string, page?: number, pageSize?: number, options?: any) {
        return NotificationsApiFp(this.configuration).getCourseCommonNotifications(courseId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿と通知ボトムタブに反映させる数を取得。授業ごとではない。
     * @summary Get New Arrival Exists State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNewArrivalExistsState(options?: any) {
        return NotificationsApiFp(this.configuration).getNewArrivalExistsState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 授業ごとの未読の通知数を取得
     * @summary Get Unread Notification Count of Each Course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUnreadNotificationCountOfEachCourse(options?: any) {
        return NotificationsApiFp(this.configuration).getUnreadNotificationCountOfEachCourse(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通知を取得するエンドポイント
     * @summary Get User Notifications
     * @param {string} courseId course id
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUserNotifications(courseId: string, page?: number, pageSize?: number, options?: any) {
        return NotificationsApiFp(this.configuration).getUserNotifications(courseId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の授業共通通知を閲覧したことを伝えるためのエンドポイント
     * @summary Register course common notification view event
     * @param {string} courseId 授業ID
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public registerCourseCommonNotificationView(courseId: string, body?: object, options?: any) {
        return NotificationsApiFp(this.configuration).registerCourseCommonNotificationView(courseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業のユーザ固有通知を閲覧したことを伝えるためのエンドポイント
     * @summary Register user specific notification view event
     * @param {string} courseId 授業ID
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public registerUserSpecificNotificationView(courseId: string, body?: object, options?: any) {
        return NotificationsApiFp(this.configuration).registerUserSpecificNotificationView(courseId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatronsApi - axios parameter creator
 * @export
 */
export const PatronsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 保護者確認リクエストを承諾する
         * @summary Approve Patron Request
         * @param {string} patronRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePatronRequest: async (patronRequestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patronRequestId' is not null or undefined
            assertParamExists('approvePatronRequest', 'patronRequestId', patronRequestId)
            const localVarPath = `/api/v1/patron_requests/{patron_request_id}`
                .replace(`{${"patron_request_id"}}`, encodeURIComponent(String(patronRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者作成
         * @summary Create Patron
         * @param {PatronCreate} [patronCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatron: async (patronCreate?: PatronCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patrons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patronCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者確認リクエストを却下する
         * @param {string} patronRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPatronRequest: async (patronRequestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patronRequestId' is not null or undefined
            assertParamExists('rejectPatronRequest', 'patronRequestId', patronRequestId)
            const localVarPath = `/api/v1/patron_requests/{patron_request_id}`
                .replace(`{${"patron_request_id"}}`, encodeURIComponent(String(patronRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 確認メールの再送用
         * @summary Request To Send Verify Email Again
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestToSendVerifyEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/verify_emails/request_to_send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者更新用API
         * @summary Update a Patron
         * @param {string} patronId 
         * @param {string} firstName 名前（漢字）
         * @param {string} firstNameKana なまえ（かな）
         * @param {string} lastName 苗字（漢字）
         * @param {string} lastNameKana みょうじ（なまえ）
         * @param {string} [profileImage] プロフィール画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatron: async (patronId: string, firstName: string, firstNameKana: string, lastName: string, lastNameKana: string, profileImage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patronId' is not null or undefined
            assertParamExists('updatePatron', 'patronId', patronId)
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('updatePatron', 'firstName', firstName)
            // verify required parameter 'firstNameKana' is not null or undefined
            assertParamExists('updatePatron', 'firstNameKana', firstNameKana)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('updatePatron', 'lastName', lastName)
            // verify required parameter 'lastNameKana' is not null or undefined
            assertParamExists('updatePatron', 'lastNameKana', lastNameKana)
            const localVarPath = `/api/v1/patrons/{patron_id}`
                .replace(`{${"patron_id"}}`, encodeURIComponent(String(patronId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (firstName !== undefined) { 
                localVarFormParams.set('first_name', firstName as any);
            }
    
            if (firstNameKana !== undefined) { 
                localVarFormParams.set('first_name_kana', firstNameKana as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.set('last_name', lastName as any);
            }
    
            if (lastNameKana !== undefined) { 
                localVarFormParams.set('last_name_kana', lastNameKana as any);
            }
    
            if (profileImage !== undefined) { 
                localVarFormParams.set('profile_image', profileImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証メールのリンクを踏んだことをサーバーに通知してverify
         * @summary Verify Email
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (inlineObject5?: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/email_verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatronsApi - functional programming interface
 * @export
 */
export const PatronsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatronsApiAxiosParamCreator(configuration)
    return {
        /**
         * 保護者確認リクエストを承諾する
         * @summary Approve Patron Request
         * @param {string} patronRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePatronRequest(patronRequestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePatronRequest(patronRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者作成
         * @summary Create Patron
         * @param {PatronCreate} [patronCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatron(patronCreate?: PatronCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatron(patronCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者確認リクエストを却下する
         * @param {string} patronRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectPatronRequest(patronRequestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectPatronRequest(patronRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 確認メールの再送用
         * @summary Request To Send Verify Email Again
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestToSendVerifyEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestToSendVerifyEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者更新用API
         * @summary Update a Patron
         * @param {string} patronId 
         * @param {string} firstName 名前（漢字）
         * @param {string} firstNameKana なまえ（かな）
         * @param {string} lastName 苗字（漢字）
         * @param {string} lastNameKana みょうじ（なまえ）
         * @param {string} [profileImage] プロフィール画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatron(patronId: string, firstName: string, firstNameKana: string, lastName: string, lastNameKana: string, profileImage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatronUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatron(patronId, firstName, firstNameKana, lastName, lastNameKana, profileImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証メールのリンクを踏んだことをサーバーに通知してverify
         * @summary Verify Email
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(inlineObject5?: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatronsApi - factory interface
 * @export
 */
export const PatronsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatronsApiFp(configuration)
    return {
        /**
         * 保護者確認リクエストを承諾する
         * @summary Approve Patron Request
         * @param {string} patronRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePatronRequest(patronRequestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.approvePatronRequest(patronRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者作成
         * @summary Create Patron
         * @param {PatronCreate} [patronCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatron(patronCreate?: PatronCreate, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.createPatron(patronCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者確認リクエストを却下する
         * @param {string} patronRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPatronRequest(patronRequestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.rejectPatronRequest(patronRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 確認メールの再送用
         * @summary Request To Send Verify Email Again
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestToSendVerifyEmail(options?: any): AxiosPromise<void> {
            return localVarFp.requestToSendVerifyEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者更新用API
         * @summary Update a Patron
         * @param {string} patronId 
         * @param {string} firstName 名前（漢字）
         * @param {string} firstNameKana なまえ（かな）
         * @param {string} lastName 苗字（漢字）
         * @param {string} lastNameKana みょうじ（なまえ）
         * @param {string} [profileImage] プロフィール画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatron(patronId: string, firstName: string, firstNameKana: string, lastName: string, lastNameKana: string, profileImage?: string, options?: any): AxiosPromise<PatronUserRead> {
            return localVarFp.updatePatron(patronId, firstName, firstNameKana, lastName, lastNameKana, profileImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証メールのリンクを踏んだことをサーバーに通知してverify
         * @summary Verify Email
         * @param {InlineObject5} [inlineObject5] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(inlineObject5?: InlineObject5, options?: any): AxiosPromise<void> {
            return localVarFp.verifyEmail(inlineObject5, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatronsApi - object-oriented interface
 * @export
 * @class PatronsApi
 * @extends {BaseAPI}
 */
export class PatronsApi extends BaseAPI {
    /**
     * 保護者確認リクエストを承諾する
     * @summary Approve Patron Request
     * @param {string} patronRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatronsApi
     */
    public approvePatronRequest(patronRequestId: string, options?: any) {
        return PatronsApiFp(this.configuration).approvePatronRequest(patronRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者作成
     * @summary Create Patron
     * @param {PatronCreate} [patronCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatronsApi
     */
    public createPatron(patronCreate?: PatronCreate, options?: any) {
        return PatronsApiFp(this.configuration).createPatron(patronCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者確認リクエストを却下する
     * @param {string} patronRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatronsApi
     */
    public rejectPatronRequest(patronRequestId: string, options?: any) {
        return PatronsApiFp(this.configuration).rejectPatronRequest(patronRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 確認メールの再送用
     * @summary Request To Send Verify Email Again
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatronsApi
     */
    public requestToSendVerifyEmail(options?: any) {
        return PatronsApiFp(this.configuration).requestToSendVerifyEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者更新用API
     * @summary Update a Patron
     * @param {string} patronId 
     * @param {string} firstName 名前（漢字）
     * @param {string} firstNameKana なまえ（かな）
     * @param {string} lastName 苗字（漢字）
     * @param {string} lastNameKana みょうじ（なまえ）
     * @param {string} [profileImage] プロフィール画像
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatronsApi
     */
    public updatePatron(patronId: string, firstName: string, firstNameKana: string, lastName: string, lastNameKana: string, profileImage?: string, options?: any) {
        return PatronsApiFp(this.configuration).updatePatron(patronId, firstName, firstNameKana, lastName, lastNameKana, profileImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証メールのリンクを踏んだことをサーバーに通知してverify
     * @summary Verify Email
     * @param {InlineObject5} [inlineObject5] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatronsApi
     */
    public verifyEmail(inlineObject5?: InlineObject5, options?: any) {
        return PatronsApiFp(this.configuration).verifyEmail(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PollsApi - axios parameter creator
 * @export
 */
export const PollsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Poll
         * @param {PollCreate} [pollCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoll: async (pollCreate?: PollCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pollCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Own Created Polls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnPolls: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Poll
         * @param {string} pollId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoll: async (pollId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pollId' is not null or undefined
            assertParamExists('getPoll', 'pollId', pollId)
            const localVarPath = `/api/v1/polls/{poll_id}`
                .replace(`{${"poll_id"}}`, encodeURIComponent(String(pollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投票する
         * @summary Vote To Poll
         * @param {string} choiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteToPoll: async (choiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'choiceId' is not null or undefined
            assertParamExists('voteToPoll', 'choiceId', choiceId)
            const localVarPath = `/api/v1/poll_choices/{choice_id}/vote`
                .replace(`{${"choice_id"}}`, encodeURIComponent(String(choiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PollsApi - functional programming interface
 * @export
 */
export const PollsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PollsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Poll
         * @param {PollCreate} [pollCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPoll(pollCreate?: PollCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPoll(pollCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Own Created Polls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnPolls(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnPolls(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Poll
         * @param {string} pollId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoll(pollId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoll(pollId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投票する
         * @summary Vote To Poll
         * @param {string} choiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voteToPoll(choiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voteToPoll(choiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PollsApi - factory interface
 * @export
 */
export const PollsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PollsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Poll
         * @param {PollCreate} [pollCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoll(pollCreate?: PollCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createPoll(pollCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Own Created Polls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnPolls(options?: any): AxiosPromise<PollListPaginated> {
            return localVarFp.getOwnPolls(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Poll
         * @param {string} pollId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoll(pollId: string, options?: any): AxiosPromise<PollRead> {
            return localVarFp.getPoll(pollId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投票する
         * @summary Vote To Poll
         * @param {string} choiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteToPoll(choiceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.voteToPoll(choiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PollsApi - object-oriented interface
 * @export
 * @class PollsApi
 * @extends {BaseAPI}
 */
export class PollsApi extends BaseAPI {
    /**
     * 
     * @summary Create Poll
     * @param {PollCreate} [pollCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public createPoll(pollCreate?: PollCreate, options?: any) {
        return PollsApiFp(this.configuration).createPoll(pollCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Own Created Polls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public getOwnPolls(options?: any) {
        return PollsApiFp(this.configuration).getOwnPolls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Poll
     * @param {string} pollId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public getPoll(pollId: string, options?: any) {
        return PollsApiFp(this.configuration).getPoll(pollId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投票する
     * @summary Vote To Poll
     * @param {string} choiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public voteToPoll(choiceId: string, options?: any) {
        return PollsApiFp(this.configuration).voteToPoll(choiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostSettingsApi - axios parameter creator
 * @export
 */
export const PostSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change PostSettingFile Orders or Delete File
         * @param {string} postSettingId 
         * @param {InlineObject22} [inlineObject22] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePostSettingFileOrdersOrDeleteFile: async (postSettingId: string, inlineObject22?: InlineObject22, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postSettingId' is not null or undefined
            assertParamExists('changePostSettingFileOrdersOrDeleteFile', 'postSettingId', postSettingId)
            const localVarPath = `/api/v1/post_settings/{post_setting_id}/file_orders`
                .replace(`{${"post_setting_id"}}`, encodeURIComponent(String(postSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject22, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Post Setteing
         * @param {string} courseId 
         * @param {PostSettingUpdate} [postSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoursesPostSetting: async (courseId: string, postSettingUpdate?: PostSettingUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createCoursesPostSetting', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_settings`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSettingUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Post Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostSetting: async (settingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('deletePostSetting', 'settingId', settingId)
            const localVarPath = `/api/v1/post_settings/{setting_id}`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の、投稿設定を取得
         * @summary Get Course Post Setting
         * @param {string} courseId 
         * @param {Array<PostSettingRead>} [postSettingRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostSettings: async (courseId: string, postSettingRead?: Array<PostSettingRead>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCoursePostSettings', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_settings`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSettingRead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿設定を取得
         * @summary Get Course Post Setting
         * @param {string} settingId 
         * @param {PostSettingRead} [postSettingRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostSetting: async (settingId: string, postSettingRead?: PostSettingRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('getPostSetting', 'settingId', settingId)
            const localVarPath = `/api/v1/post_settings/{setting_id}`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSettingRead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Post Setting
         * @param {string} settingId 
         * @param {PostSettingUpdate} [postSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostSetting: async (settingId: string, postSettingUpdate?: PostSettingUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('updatePostSetting', 'settingId', settingId)
            const localVarPath = `/api/v1/post_settings/{setting_id}`
                .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSettingUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostSettingsApi - functional programming interface
 * @export
 */
export const PostSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change PostSettingFile Orders or Delete File
         * @param {string} postSettingId 
         * @param {InlineObject22} [inlineObject22] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePostSettingFileOrdersOrDeleteFile(postSettingId: string, inlineObject22?: InlineObject22, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePostSettingFileOrdersOrDeleteFile(postSettingId, inlineObject22, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Post Setteing
         * @param {string} courseId 
         * @param {PostSettingUpdate} [postSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCoursesPostSetting(courseId: string, postSettingUpdate?: PostSettingUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCoursesPostSetting(courseId, postSettingUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Post Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostSetting(settingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePostSetting(settingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の、投稿設定を取得
         * @summary Get Course Post Setting
         * @param {string} courseId 
         * @param {Array<PostSettingRead>} [postSettingRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursePostSettings(courseId: string, postSettingRead?: Array<PostSettingRead>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursePostSettings(courseId, postSettingRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿設定を取得
         * @summary Get Course Post Setting
         * @param {string} settingId 
         * @param {PostSettingRead} [postSettingRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostSetting(settingId: string, postSettingRead?: PostSettingRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostSetting(settingId, postSettingRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Post Setting
         * @param {string} settingId 
         * @param {PostSettingUpdate} [postSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePostSetting(settingId: string, postSettingUpdate?: PostSettingUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePostSetting(settingId, postSettingUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostSettingsApi - factory interface
 * @export
 */
export const PostSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Change PostSettingFile Orders or Delete File
         * @param {string} postSettingId 
         * @param {InlineObject22} [inlineObject22] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePostSettingFileOrdersOrDeleteFile(postSettingId: string, inlineObject22?: InlineObject22, options?: any): AxiosPromise<void> {
            return localVarFp.changePostSettingFileOrdersOrDeleteFile(postSettingId, inlineObject22, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Post Setteing
         * @param {string} courseId 
         * @param {PostSettingUpdate} [postSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoursesPostSetting(courseId: string, postSettingUpdate?: PostSettingUpdate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createCoursesPostSetting(courseId, postSettingUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Post Setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostSetting(settingId: string, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.deletePostSetting(settingId, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の、投稿設定を取得
         * @summary Get Course Post Setting
         * @param {string} courseId 
         * @param {Array<PostSettingRead>} [postSettingRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostSettings(courseId: string, postSettingRead?: Array<PostSettingRead>, options?: any): AxiosPromise<void> {
            return localVarFp.getCoursePostSettings(courseId, postSettingRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿設定を取得
         * @summary Get Course Post Setting
         * @param {string} settingId 
         * @param {PostSettingRead} [postSettingRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostSetting(settingId: string, postSettingRead?: PostSettingRead, options?: any): AxiosPromise<void> {
            return localVarFp.getPostSetting(settingId, postSettingRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Post Setting
         * @param {string} settingId 
         * @param {PostSettingUpdate} [postSettingUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostSetting(settingId: string, postSettingUpdate?: PostSettingUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updatePostSetting(settingId, postSettingUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostSettingsApi - object-oriented interface
 * @export
 * @class PostSettingsApi
 * @extends {BaseAPI}
 */
export class PostSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Change PostSettingFile Orders or Delete File
     * @param {string} postSettingId 
     * @param {InlineObject22} [inlineObject22] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostSettingsApi
     */
    public changePostSettingFileOrdersOrDeleteFile(postSettingId: string, inlineObject22?: InlineObject22, options?: any) {
        return PostSettingsApiFp(this.configuration).changePostSettingFileOrdersOrDeleteFile(postSettingId, inlineObject22, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Post Setteing
     * @param {string} courseId 
     * @param {PostSettingUpdate} [postSettingUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostSettingsApi
     */
    public createCoursesPostSetting(courseId: string, postSettingUpdate?: PostSettingUpdate, options?: any) {
        return PostSettingsApiFp(this.configuration).createCoursesPostSetting(courseId, postSettingUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Post Setting
     * @param {string} settingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostSettingsApi
     */
    public deletePostSetting(settingId: string, options?: any) {
        return PostSettingsApiFp(this.configuration).deletePostSetting(settingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の、投稿設定を取得
     * @summary Get Course Post Setting
     * @param {string} courseId 
     * @param {Array<PostSettingRead>} [postSettingRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostSettingsApi
     */
    public getCoursePostSettings(courseId: string, postSettingRead?: Array<PostSettingRead>, options?: any) {
        return PostSettingsApiFp(this.configuration).getCoursePostSettings(courseId, postSettingRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿設定を取得
     * @summary Get Course Post Setting
     * @param {string} settingId 
     * @param {PostSettingRead} [postSettingRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostSettingsApi
     */
    public getPostSetting(settingId: string, postSettingRead?: PostSettingRead, options?: any) {
        return PostSettingsApiFp(this.configuration).getPostSetting(settingId, postSettingRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Post Setting
     * @param {string} settingId 
     * @param {PostSettingUpdate} [postSettingUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostSettingsApi
     */
    public updatePostSetting(settingId: string, postSettingUpdate?: PostSettingUpdate, options?: any) {
        return PostSettingsApiFp(this.configuration).updatePostSetting(settingId, postSettingUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostTagsApi - axios parameter creator
 * @export
 */
export const PostTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 投稿タグの作成。指定した授業の教員か生徒しか作れない。
         * @summary Create a Post Tag
         * @param {string} courseId 
         * @param {PostTagCreate} postTagCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostTag: async (courseId: string, postTagCreate: PostTagCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createPostTag', 'courseId', courseId)
            // verify required parameter 'postTagCreate' is not null or undefined
            assertParamExists('createPostTag', 'postTagCreate', postTagCreate)
            const localVarPath = `/api/v1/courses/{course_id}/post_tags`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTagCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したタグの削除。authorのみ可能。
         * @summary Delete a Post Tag
         * @param {string} postTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostTag: async (postTagId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTagId' is not null or undefined
            assertParamExists('deletePostTag', 'postTagId', postTagId)
            const localVarPath = `/api/v1/post_tags/{post_tag_id}`
                .replace(`{${"post_tag_id"}}`, encodeURIComponent(String(postTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した授業のタグ一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get Post Tags
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTagsByCourseId: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getPostTagsByCourseId', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_tags`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身が受講(生徒の場合)/担当(教員の場合)の全ての授業のタグを取得する
         * @summary Get Post Tags of my Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTagsOfAllOfMyCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/post_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した授業の、授業公開タグの情報とその紐づいた投稿の数 紐づいた投稿の数が多い順にタグが並ぶ
         * @summary Get Course Post Tags with Post Count
         * @param {string} courseId 授業のid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTagsWithPostCount: async (courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getPostTagsWithPostCount', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_tags_with_post_count`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したタグの一部更新。authorのみ可能。
         * @summary Update a Post tag
         * @param {string} postTagId 
         * @param {PostTagUpdate} [postTagUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostTag: async (postTagId: string, postTagUpdate?: PostTagUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTagId' is not null or undefined
            assertParamExists('updatePostTag', 'postTagId', postTagId)
            const localVarPath = `/api/v1/post_tags/{post_tag_id}`
                .replace(`{${"post_tag_id"}}`, encodeURIComponent(String(postTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTagUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostTagsApi - functional programming interface
 * @export
 */
export const PostTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 投稿タグの作成。指定した授業の教員か生徒しか作れない。
         * @summary Create a Post Tag
         * @param {string} courseId 
         * @param {PostTagCreate} postTagCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPostTag(courseId: string, postTagCreate: PostTagCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPostTag(courseId, postTagCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したタグの削除。authorのみ可能。
         * @summary Delete a Post Tag
         * @param {string} postTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostTag(postTagId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTagRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePostTag(postTagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した授業のタグ一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get Post Tags
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostTagsByCourseId(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostTagRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostTagsByCourseId(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身が受講(生徒の場合)/担当(教員の場合)の全ての授業のタグを取得する
         * @summary Get Post Tags of my Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostTagsOfAllOfMyCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostTagRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostTagsOfAllOfMyCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した授業の、授業公開タグの情報とその紐づいた投稿の数 紐づいた投稿の数が多い順にタグが並ぶ
         * @summary Get Course Post Tags with Post Count
         * @param {string} courseId 授業のid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostTagsWithPostCount(courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostTagWithPostCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostTagsWithPostCount(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したタグの一部更新。authorのみ可能。
         * @summary Update a Post tag
         * @param {string} postTagId 
         * @param {PostTagUpdate} [postTagUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePostTag(postTagId: string, postTagUpdate?: PostTagUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTagRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePostTag(postTagId, postTagUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostTagsApi - factory interface
 * @export
 */
export const PostTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostTagsApiFp(configuration)
    return {
        /**
         * 投稿タグの作成。指定した授業の教員か生徒しか作れない。
         * @summary Create a Post Tag
         * @param {string} courseId 
         * @param {PostTagCreate} postTagCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostTag(courseId: string, postTagCreate: PostTagCreate, options?: any): AxiosPromise<InlineResponse2011> {
            return localVarFp.createPostTag(courseId, postTagCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したタグの削除。authorのみ可能。
         * @summary Delete a Post Tag
         * @param {string} postTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostTag(postTagId: string, options?: any): AxiosPromise<PostTagRead> {
            return localVarFp.deletePostTag(postTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した授業のタグ一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get Post Tags
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTagsByCourseId(courseId: string, options?: any): AxiosPromise<Array<PostTagRead>> {
            return localVarFp.getPostTagsByCourseId(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身が受講(生徒の場合)/担当(教員の場合)の全ての授業のタグを取得する
         * @summary Get Post Tags of my Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTagsOfAllOfMyCourses(options?: any): AxiosPromise<Array<PostTagRead>> {
            return localVarFp.getPostTagsOfAllOfMyCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した授業の、授業公開タグの情報とその紐づいた投稿の数 紐づいた投稿の数が多い順にタグが並ぶ
         * @summary Get Course Post Tags with Post Count
         * @param {string} courseId 授業のid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTagsWithPostCount(courseId: string, options?: any): AxiosPromise<Array<PostTagWithPostCount>> {
            return localVarFp.getPostTagsWithPostCount(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したタグの一部更新。authorのみ可能。
         * @summary Update a Post tag
         * @param {string} postTagId 
         * @param {PostTagUpdate} [postTagUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostTag(postTagId: string, postTagUpdate?: PostTagUpdate, options?: any): AxiosPromise<PostTagRead> {
            return localVarFp.updatePostTag(postTagId, postTagUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostTagsApi - object-oriented interface
 * @export
 * @class PostTagsApi
 * @extends {BaseAPI}
 */
export class PostTagsApi extends BaseAPI {
    /**
     * 投稿タグの作成。指定した授業の教員か生徒しか作れない。
     * @summary Create a Post Tag
     * @param {string} courseId 
     * @param {PostTagCreate} postTagCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTagsApi
     */
    public createPostTag(courseId: string, postTagCreate: PostTagCreate, options?: any) {
        return PostTagsApiFp(this.configuration).createPostTag(courseId, postTagCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したタグの削除。authorのみ可能。
     * @summary Delete a Post Tag
     * @param {string} postTagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTagsApi
     */
    public deletePostTag(postTagId: string, options?: any) {
        return PostTagsApiFp(this.configuration).deletePostTag(postTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した授業のタグ一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
     * @summary Get Post Tags
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTagsApi
     */
    public getPostTagsByCourseId(courseId: string, options?: any) {
        return PostTagsApiFp(this.configuration).getPostTagsByCourseId(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自身が受講(生徒の場合)/担当(教員の場合)の全ての授業のタグを取得する
     * @summary Get Post Tags of my Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTagsApi
     */
    public getPostTagsOfAllOfMyCourses(options?: any) {
        return PostTagsApiFp(this.configuration).getPostTagsOfAllOfMyCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した授業の、授業公開タグの情報とその紐づいた投稿の数 紐づいた投稿の数が多い順にタグが並ぶ
     * @summary Get Course Post Tags with Post Count
     * @param {string} courseId 授業のid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTagsApi
     */
    public getPostTagsWithPostCount(courseId: string, options?: any) {
        return PostTagsApiFp(this.configuration).getPostTagsWithPostCount(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したタグの一部更新。authorのみ可能。
     * @summary Update a Post tag
     * @param {string} postTagId 
     * @param {PostTagUpdate} [postTagUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTagsApi
     */
    public updatePostTag(postTagId: string, postTagUpdate?: PostTagUpdate, options?: any) {
        return PostTagsApiFp(this.configuration).updatePostTag(postTagId, postTagUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostTemplatesApi - axios parameter creator
 * @export
 */
export const PostTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 投稿テンプレートの削除
         * @summary Delete Post Template
         * @param {string} postTemplateId 
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostTemplate: async (postTemplateId: string, inlineObject10?: InlineObject10, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTemplateId' is not null or undefined
            assertParamExists('deletePostTemplate', 'postTemplateId', postTemplateId)
            const localVarPath = `/api/v1/post_templates/{post_template_id}`
                .replace(`{${"post_template_id"}}`, encodeURIComponent(String(postTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の現在指定してある投稿テンプレートを取得。なかったらnull
         * @summary Get Course Specified Post Template
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSpecifiedPostTemplate: async (courseId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseSpecifiedPostTemplate', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/specified_post_template`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿テンプレートの取得
         * @summary Get Post Template
         * @param {string} postTemplateId 
         * @param {PostTemplateRead} [postTemplateRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTemplate: async (postTemplateId: string, postTemplateRead?: PostTemplateRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTemplateId' is not null or undefined
            assertParamExists('getPostTemplate', 'postTemplateId', postTemplateId)
            const localVarPath = `/api/v1/post_templates/{post_template_id}`
                .replace(`{${"post_template_id"}}`, encodeURIComponent(String(postTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTemplateRead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の指定投稿テンプレートを変更。パラメタに何も入れない時は指定を外す
         * @summary Change Course Specified Post Template
         * @param {string} courseId 
         * @param {InlineObject12} [inlineObject12] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCourseSpecifiedPostTemplate: async (courseId: string, inlineObject12?: InlineObject12, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('patchCourseSpecifiedPostTemplate', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/specified_post_template`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿テンプレートの更新
         * @summary Update Post Template
         * @param {string} postTemplateId 
         * @param {InlineObject11} [inlineObject11] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostTemplate: async (postTemplateId: string, inlineObject11?: InlineObject11, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTemplateId' is not null or undefined
            assertParamExists('updatePostTemplate', 'postTemplateId', postTemplateId)
            const localVarPath = `/api/v1/post_templates/{post_template_id}`
                .replace(`{${"post_template_id"}}`, encodeURIComponent(String(postTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject11, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostTemplatesApi - functional programming interface
 * @export
 */
export const PostTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 投稿テンプレートの削除
         * @summary Delete Post Template
         * @param {string} postTemplateId 
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostTemplate(postTemplateId: string, inlineObject10?: InlineObject10, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePostTemplate(postTemplateId, inlineObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の現在指定してある投稿テンプレートを取得。なかったらnull
         * @summary Get Course Specified Post Template
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSpecifiedPostTemplate(courseId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseSpecifiedPostTemplate(courseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿テンプレートの取得
         * @summary Get Post Template
         * @param {string} postTemplateId 
         * @param {PostTemplateRead} [postTemplateRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostTemplate(postTemplateId: string, postTemplateRead?: PostTemplateRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTemplateRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostTemplate(postTemplateId, postTemplateRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の指定投稿テンプレートを変更。パラメタに何も入れない時は指定を外す
         * @summary Change Course Specified Post Template
         * @param {string} courseId 
         * @param {InlineObject12} [inlineObject12] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCourseSpecifiedPostTemplate(courseId: string, inlineObject12?: InlineObject12, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCourseSpecifiedPostTemplate(courseId, inlineObject12, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿テンプレートの更新
         * @summary Update Post Template
         * @param {string} postTemplateId 
         * @param {InlineObject11} [inlineObject11] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePostTemplate(postTemplateId: string, inlineObject11?: InlineObject11, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePostTemplate(postTemplateId, inlineObject11, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostTemplatesApi - factory interface
 * @export
 */
export const PostTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostTemplatesApiFp(configuration)
    return {
        /**
         * 投稿テンプレートの削除
         * @summary Delete Post Template
         * @param {string} postTemplateId 
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostTemplate(postTemplateId: string, inlineObject10?: InlineObject10, options?: any): AxiosPromise<void> {
            return localVarFp.deletePostTemplate(postTemplateId, inlineObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の現在指定してある投稿テンプレートを取得。なかったらnull
         * @summary Get Course Specified Post Template
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSpecifiedPostTemplate(courseId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.getCourseSpecifiedPostTemplate(courseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿テンプレートの取得
         * @summary Get Post Template
         * @param {string} postTemplateId 
         * @param {PostTemplateRead} [postTemplateRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostTemplate(postTemplateId: string, postTemplateRead?: PostTemplateRead, options?: any): AxiosPromise<PostTemplateRead> {
            return localVarFp.getPostTemplate(postTemplateId, postTemplateRead, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の指定投稿テンプレートを変更。パラメタに何も入れない時は指定を外す
         * @summary Change Course Specified Post Template
         * @param {string} courseId 
         * @param {InlineObject12} [inlineObject12] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCourseSpecifiedPostTemplate(courseId: string, inlineObject12?: InlineObject12, options?: any): AxiosPromise<void> {
            return localVarFp.patchCourseSpecifiedPostTemplate(courseId, inlineObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿テンプレートの更新
         * @summary Update Post Template
         * @param {string} postTemplateId 
         * @param {InlineObject11} [inlineObject11] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostTemplate(postTemplateId: string, inlineObject11?: InlineObject11, options?: any): AxiosPromise<void> {
            return localVarFp.updatePostTemplate(postTemplateId, inlineObject11, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostTemplatesApi - object-oriented interface
 * @export
 * @class PostTemplatesApi
 * @extends {BaseAPI}
 */
export class PostTemplatesApi extends BaseAPI {
    /**
     * 投稿テンプレートの削除
     * @summary Delete Post Template
     * @param {string} postTemplateId 
     * @param {InlineObject10} [inlineObject10] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTemplatesApi
     */
    public deletePostTemplate(postTemplateId: string, inlineObject10?: InlineObject10, options?: any) {
        return PostTemplatesApiFp(this.configuration).deletePostTemplate(postTemplateId, inlineObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の現在指定してある投稿テンプレートを取得。なかったらnull
     * @summary Get Course Specified Post Template
     * @param {string} courseId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTemplatesApi
     */
    public getCourseSpecifiedPostTemplate(courseId: string, body?: object, options?: any) {
        return PostTemplatesApiFp(this.configuration).getCourseSpecifiedPostTemplate(courseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿テンプレートの取得
     * @summary Get Post Template
     * @param {string} postTemplateId 
     * @param {PostTemplateRead} [postTemplateRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTemplatesApi
     */
    public getPostTemplate(postTemplateId: string, postTemplateRead?: PostTemplateRead, options?: any) {
        return PostTemplatesApiFp(this.configuration).getPostTemplate(postTemplateId, postTemplateRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の指定投稿テンプレートを変更。パラメタに何も入れない時は指定を外す
     * @summary Change Course Specified Post Template
     * @param {string} courseId 
     * @param {InlineObject12} [inlineObject12] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTemplatesApi
     */
    public patchCourseSpecifiedPostTemplate(courseId: string, inlineObject12?: InlineObject12, options?: any) {
        return PostTemplatesApiFp(this.configuration).patchCourseSpecifiedPostTemplate(courseId, inlineObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿テンプレートの更新
     * @summary Update Post Template
     * @param {string} postTemplateId 
     * @param {InlineObject11} [inlineObject11] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostTemplatesApi
     */
    public updatePostTemplate(postTemplateId: string, inlineObject11?: InlineObject11, options?: any) {
        return PostTemplatesApiFp(this.configuration).updatePostTemplate(postTemplateId, inlineObject11, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostsApi - axios parameter creator
 * @export
 */
export const PostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change Post File Orders or Delete File
         * @param {string} postId 
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePostFileOrdersOrDeleteFile: async (postId: string, inlineObject18?: InlineObject18, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('changePostFileOrdersOrDeleteFile', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/file_orders`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の投稿テンプレートを作る
         * @summary Create Course Post Template
         * @param {string} courseId 
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoursePostTemplate: async (courseId: string, inlineObject9?: InlineObject9, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createCoursePostTemplate', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_templates`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject9, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿の作成。指定した授業の教員か生徒しか作れない。タグは授業に紐づく、myownの自分のタグか授業で共有のタグ
         * @summary Create a Post to a Course
         * @param {string} courseId 
         * @param {PostDetailCreate} postDetailCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostByCourseId: async (courseId: string, postDetailCreate: PostDetailCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createPostByCourseId', 'courseId', courseId)
            // verify required parameter 'postDetailCreate' is not null or undefined
            assertParamExists('createPostByCourseId', 'postDetailCreate', postDetailCreate)
            const localVarPath = `/api/v1/courses/{course_id}/posts`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDetailCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿の作成
         * @summary Create Posts From Notes
         * @param {string} boardSetId 
         * @param {Array<InlineObject2>} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostsFromNotes: async (boardSetId: string, inlineObject2?: Array<InlineObject2>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardSetId' is not null or undefined
            assertParamExists('createPostsFromNotes', 'boardSetId', boardSetId)
            const localVarPath = `/api/v1/board_sets/{board_set_id}/posts_from_notes`
                .replace(`{${"board_set_id"}}`, encodeURIComponent(String(boardSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿の削除。authorのみ可能。
         * @summary Detele a Post
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: async (postId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('deletePost', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 配列でidを指定したcanvas input filesを削除。
         * @summary Delete Post Canvas Input Files
         * @param {string} postId 
         * @param {Array<InlineObject1>} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostCanvasInputFiles: async (postId: string, inlineObject1?: Array<InlineObject1>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('deletePostCanvasInputFiles', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/canvas_input_files`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿の一時添付ファイルの削除
         * @summary Delete Temporary Post File
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryPostFile: async (fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteTemporaryPostFile', 'fileId', fileId)
            const localVarPath = `/api/v1/temporary_post_files/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した授業の投稿一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get Posts of a Course
         * @param {string} courseId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPostsByCourseId: async (courseId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getAllPostsByCourseId', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/posts`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業での、児童の公開された投稿数とコメント数を取得
         * @summary Course Post And Comment Count Get
         * @param {string} courseId 
         * @param {Array<CoursePostAndCommentCount>} [coursePostAndCommentCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostAndCommentCount: async (courseId: string, coursePostAndCommentCount?: Array<CoursePostAndCommentCount>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCoursePostAndCommentCount', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_and_comment_count`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursePostAndCommentCount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業の、投稿テンプレートを取得
         * @summary Get Course Post Templates
         * @param {string} courseId 
         * @param {Array<PostTemplateRead>} [postTemplateRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostTemplates: async (courseId: string, postTemplateRead?: Array<PostTemplateRead>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCoursePostTemplates', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/post_templates`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTemplateRead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AIに指示文を送って分析させる
         * @summary Custom Ai Analysis
         * @param {string} courseId 
         * @param {InlineObject37} [inlineObject37] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomPostsAnalysis: async (courseId: string, inlineObject37?: InlineObject37, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCustomPostsAnalysis', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/custom_posts_analysis_stream`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject37, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * later_than_or_equal_to_post_idで指定したpostより最新のcourseの投稿を取得
         * @summary Get Latest Posts in Course
         * @param {string} courseId 
         * @param {string} laterThanOrEqualToPostId pull to refresh用の、取得済み最新Postのid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPostsInCourse: async (courseId: string, laterThanOrEqualToPostId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getLatestPostsInCourse', 'courseId', courseId)
            // verify required parameter 'laterThanOrEqualToPostId' is not null or undefined
            assertParamExists('getLatestPostsInCourse', 'laterThanOrEqualToPostId', laterThanOrEqualToPostId)
            const localVarPath = `/api/v1/courses/{course_id}/latest_posts`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (laterThanOrEqualToPostId !== undefined) {
                localVarQueryParameter['later_than_or_equal_to_post_id'] = laterThanOrEqualToPostId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定のユーザーの、特定の授業での投稿の一覧表示。ページネーション。
         * @summary Get Posts of one of User\'s Course
         * @param {string} userId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagenatedPostsByCourseId: async (userId: string, courseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPagenatedPostsByCourseId', 'userId', userId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getPagenatedPostsByCourseId', 'courseId', courseId)
            const localVarPath = `/users/{user_id}/courses/{course_id}/posts`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿を取得(検索に使用)
         * @summary Get Posts
         * @param {Array<string>} [words] 検索用ワード
         * @param {Array<string>} [authorIds] 投稿者のUUID
         * @param {Array<string>} [postTagIds] 投稿タグのUUID
         * @param {Array<string>} [classroomIds] クラスルームのUUID
         * @param {Array<string>} [courseIds] 授業のUUID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: async (words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (words) {
                localVarQueryParameter['words'] = words;
            }

            if (authorIds) {
                localVarQueryParameter['author_ids'] = authorIds;
            }

            if (postTagIds) {
                localVarQueryParameter['post_tag_ids'] = postTagIds;
            }

            if (classroomIds) {
                localVarQueryParameter['classroom_ids'] = classroomIds;
            }

            if (courseIds) {
                localVarQueryParameter['course_ids'] = courseIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (shareRange !== undefined) {
                localVarQueryParameter['share_range'] = shareRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 一人のユーザーの全ての授業の投稿を取得。ただし、リクエストユーザーの閲覧可能な授業のみ。{user_id}はmeの自分のidのエイリアスあり
         * @summary Get all Posts of a User
         * @param {string} userId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostsByUserId: async (userId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPostsByUserId', 'userId', userId)
            const localVarPath = `/api/v1/users/{user_id}/posts`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link Temporary Canvas Input File To Post
         * @param {string} postId 
         * @param {Array<InlineResponse201>} [inlineResponse201] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTemporaryCanvasInputFileToPost: async (postId: string, inlineResponse201?: Array<InlineResponse201>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('linkTemporaryCanvasInputFileToPost', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/canvas_input_files/link`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineResponse201, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 一時的にアップロードしていたファイルを投稿に紐づける
         * @summary Link TemporaryPostFile To Post
         * @param {string} postId 
         * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTemporaryPostFileToPost: async (postId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('linkTemporaryPostFileToPost', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/files/link`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当Postを閲覧したことを登録(更新)するためのエンドポイント
         * @summary Register a post view event
         * @param {string} postId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPostView: async (postId: string, inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('registerPostView', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/viewed`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 児童が投稿を書き込んでいる様子をデモする
         * @summary Start Realtime Editing Posts Demo
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRealtimeEditingPostsDemo: async (courseId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('startRealtimeEditingPostsDemo', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/realtime_editing_posts_demo`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿のリアルタイムでの更新を行う
         * @summary Update Editing Post
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditingPost: async (courseId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('updateEditingPost', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/editing_posts`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿の一部更新。authorのみ可能。
         * @summary Update a Post
         * @param {string} postId 
         * @param {PostDetailUpdate} [postDetailUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: async (postId: string, postDetailUpdate?: PostDetailUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('updatePost', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDetailUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostsApi - functional programming interface
 * @export
 */
export const PostsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change Post File Orders or Delete File
         * @param {string} postId 
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePostFileOrdersOrDeleteFile(postId: string, inlineObject18?: InlineObject18, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePostFileOrdersOrDeleteFile(postId, inlineObject18, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の投稿テンプレートを作る
         * @summary Create Course Post Template
         * @param {string} courseId 
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCoursePostTemplate(courseId: string, inlineObject9?: InlineObject9, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCoursePostTemplate(courseId, inlineObject9, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿の作成。指定した授業の教員か生徒しか作れない。タグは授業に紐づく、myownの自分のタグか授業で共有のタグ
         * @summary Create a Post to a Course
         * @param {string} courseId 
         * @param {PostDetailCreate} postDetailCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPostByCourseId(courseId: string, postDetailCreate: PostDetailCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPostByCourseId(courseId, postDetailCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿の作成
         * @summary Create Posts From Notes
         * @param {string} boardSetId 
         * @param {Array<InlineObject2>} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPostsFromNotes(boardSetId: string, inlineObject2?: Array<InlineObject2>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse20028>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPostsFromNotes(boardSetId, inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿の削除。authorのみ可能。
         * @summary Detele a Post
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePost(postId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePost(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 配列でidを指定したcanvas input filesを削除。
         * @summary Delete Post Canvas Input Files
         * @param {string} postId 
         * @param {Array<InlineObject1>} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostCanvasInputFiles(postId: string, inlineObject1?: Array<InlineObject1>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePostCanvasInputFiles(postId, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿の一時添付ファイルの削除
         * @summary Delete Temporary Post File
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemporaryPostFile(fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemporaryPostFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した授業の投稿一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get Posts of a Course
         * @param {string} courseId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPostsByCourseId(courseId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPostsByCourseId(courseId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業での、児童の公開された投稿数とコメント数を取得
         * @summary Course Post And Comment Count Get
         * @param {string} courseId 
         * @param {Array<CoursePostAndCommentCount>} [coursePostAndCommentCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursePostAndCommentCount(courseId: string, coursePostAndCommentCount?: Array<CoursePostAndCommentCount>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursePostAndCommentCount(courseId, coursePostAndCommentCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業の、投稿テンプレートを取得
         * @summary Get Course Post Templates
         * @param {string} courseId 
         * @param {Array<PostTemplateRead>} [postTemplateRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoursePostTemplates(courseId: string, postTemplateRead?: Array<PostTemplateRead>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoursePostTemplates(courseId, postTemplateRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * AIに指示文を送って分析させる
         * @summary Custom Ai Analysis
         * @param {string} courseId 
         * @param {InlineObject37} [inlineObject37] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomPostsAnalysis(courseId: string, inlineObject37?: InlineObject37, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomPostsAnalysis(courseId, inlineObject37, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * later_than_or_equal_to_post_idで指定したpostより最新のcourseの投稿を取得
         * @summary Get Latest Posts in Course
         * @param {string} courseId 
         * @param {string} laterThanOrEqualToPostId pull to refresh用の、取得済み最新Postのid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestPostsInCourse(courseId: string, laterThanOrEqualToPostId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostTimelineRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPostsInCourse(courseId, laterThanOrEqualToPostId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定のユーザーの、特定の授業での投稿の一覧表示。ページネーション。
         * @summary Get Posts of one of User\'s Course
         * @param {string} userId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPagenatedPostsByCourseId(userId: string, courseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPagenatedPostsByCourseId(userId, courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿を取得(検索に使用)
         * @summary Get Posts
         * @param {Array<string>} [words] 検索用ワード
         * @param {Array<string>} [authorIds] 投稿者のUUID
         * @param {Array<string>} [postTagIds] 投稿タグのUUID
         * @param {Array<string>} [classroomIds] クラスルームのUUID
         * @param {Array<string>} [courseIds] 授業のUUID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosts(words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPosts(words, authorIds, postTagIds, classroomIds, courseIds, page, pageSize, shareRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 一人のユーザーの全ての授業の投稿を取得。ただし、リクエストユーザーの閲覧可能な授業のみ。{user_id}はmeの自分のidのエイリアスあり
         * @summary Get all Posts of a User
         * @param {string} userId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostsByUserId(userId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostTimelineRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostsByUserId(userId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link Temporary Canvas Input File To Post
         * @param {string} postId 
         * @param {Array<InlineResponse201>} [inlineResponse201] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkTemporaryCanvasInputFileToPost(postId: string, inlineResponse201?: Array<InlineResponse201>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkTemporaryCanvasInputFileToPost(postId, inlineResponse201, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 一時的にアップロードしていたファイルを投稿に紐づける
         * @summary Link TemporaryPostFile To Post
         * @param {string} postId 
         * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkTemporaryPostFileToPost(postId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkTemporaryPostFileToPost(postId, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当Postを閲覧したことを登録(更新)するためのエンドポイント
         * @summary Register a post view event
         * @param {string} postId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPostView(postId: string, inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPostView(postId, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 児童が投稿を書き込んでいる様子をデモする
         * @summary Start Realtime Editing Posts Demo
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startRealtimeEditingPostsDemo(courseId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startRealtimeEditingPostsDemo(courseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿のリアルタイムでの更新を行う
         * @summary Update Editing Post
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEditingPost(courseId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEditingPost(courseId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿の一部更新。authorのみ可能。
         * @summary Update a Post
         * @param {string} postId 
         * @param {PostDetailUpdate} [postDetailUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePost(postId: string, postDetailUpdate?: PostDetailUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePost(postId, postDetailUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostsApi - factory interface
 * @export
 */
export const PostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostsApiFp(configuration)
    return {
        /**
         * 
         * @summary Change Post File Orders or Delete File
         * @param {string} postId 
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePostFileOrdersOrDeleteFile(postId: string, inlineObject18?: InlineObject18, options?: any): AxiosPromise<void> {
            return localVarFp.changePostFileOrdersOrDeleteFile(postId, inlineObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の投稿テンプレートを作る
         * @summary Create Course Post Template
         * @param {string} courseId 
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoursePostTemplate(courseId: string, inlineObject9?: InlineObject9, options?: any): AxiosPromise<void> {
            return localVarFp.createCoursePostTemplate(courseId, inlineObject9, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿の作成。指定した授業の教員か生徒しか作れない。タグは授業に紐づく、myownの自分のタグか授業で共有のタグ
         * @summary Create a Post to a Course
         * @param {string} courseId 
         * @param {PostDetailCreate} postDetailCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostByCourseId(courseId: string, postDetailCreate: PostDetailCreate, options?: any): AxiosPromise<PostDetailRead> {
            return localVarFp.createPostByCourseId(courseId, postDetailCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿の作成
         * @summary Create Posts From Notes
         * @param {string} boardSetId 
         * @param {Array<InlineObject2>} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostsFromNotes(boardSetId: string, inlineObject2?: Array<InlineObject2>, options?: any): AxiosPromise<Array<InlineResponse20028>> {
            return localVarFp.createPostsFromNotes(boardSetId, inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿の削除。authorのみ可能。
         * @summary Detele a Post
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost(postId: string, options?: any): AxiosPromise<PostDetailRead> {
            return localVarFp.deletePost(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 配列でidを指定したcanvas input filesを削除。
         * @summary Delete Post Canvas Input Files
         * @param {string} postId 
         * @param {Array<InlineObject1>} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostCanvasInputFiles(postId: string, inlineObject1?: Array<InlineObject1>, options?: any): AxiosPromise<void> {
            return localVarFp.deletePostCanvasInputFiles(postId, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿の一時添付ファイルの削除
         * @summary Delete Temporary Post File
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryPostFile(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemporaryPostFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した授業の投稿一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
         * @summary Get Posts of a Course
         * @param {string} courseId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPostsByCourseId(courseId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PostDetailListPaginated> {
            return localVarFp.getAllPostsByCourseId(courseId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業での、児童の公開された投稿数とコメント数を取得
         * @summary Course Post And Comment Count Get
         * @param {string} courseId 
         * @param {Array<CoursePostAndCommentCount>} [coursePostAndCommentCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostAndCommentCount(courseId: string, coursePostAndCommentCount?: Array<CoursePostAndCommentCount>, options?: any): AxiosPromise<void> {
            return localVarFp.getCoursePostAndCommentCount(courseId, coursePostAndCommentCount, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業の、投稿テンプレートを取得
         * @summary Get Course Post Templates
         * @param {string} courseId 
         * @param {Array<PostTemplateRead>} [postTemplateRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoursePostTemplates(courseId: string, postTemplateRead?: Array<PostTemplateRead>, options?: any): AxiosPromise<void> {
            return localVarFp.getCoursePostTemplates(courseId, postTemplateRead, options).then((request) => request(axios, basePath));
        },
        /**
         * AIに指示文を送って分析させる
         * @summary Custom Ai Analysis
         * @param {string} courseId 
         * @param {InlineObject37} [inlineObject37] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomPostsAnalysis(courseId: string, inlineObject37?: InlineObject37, options?: any): AxiosPromise<string> {
            return localVarFp.getCustomPostsAnalysis(courseId, inlineObject37, options).then((request) => request(axios, basePath));
        },
        /**
         * later_than_or_equal_to_post_idで指定したpostより最新のcourseの投稿を取得
         * @summary Get Latest Posts in Course
         * @param {string} courseId 
         * @param {string} laterThanOrEqualToPostId pull to refresh用の、取得済み最新Postのid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPostsInCourse(courseId: string, laterThanOrEqualToPostId: string, options?: any): AxiosPromise<Array<PostTimelineRead>> {
            return localVarFp.getLatestPostsInCourse(courseId, laterThanOrEqualToPostId, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定のユーザーの、特定の授業での投稿の一覧表示。ページネーション。
         * @summary Get Posts of one of User\'s Course
         * @param {string} userId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagenatedPostsByCourseId(userId: string, courseId: string, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.getPagenatedPostsByCourseId(userId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿を取得(検索に使用)
         * @summary Get Posts
         * @param {Array<string>} [words] 検索用ワード
         * @param {Array<string>} [authorIds] 投稿者のUUID
         * @param {Array<string>} [postTagIds] 投稿タグのUUID
         * @param {Array<string>} [classroomIds] クラスルームのUUID
         * @param {Array<string>} [courseIds] 授業のUUID
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts(words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options?: any): AxiosPromise<PostDetailListPaginated> {
            return localVarFp.getPosts(words, authorIds, postTagIds, classroomIds, courseIds, page, pageSize, shareRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 一人のユーザーの全ての授業の投稿を取得。ただし、リクエストユーザーの閲覧可能な授業のみ。{user_id}はmeの自分のidのエイリアスあり
         * @summary Get all Posts of a User
         * @param {string} userId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostsByUserId(userId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<PostTimelineRead>> {
            return localVarFp.getPostsByUserId(userId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link Temporary Canvas Input File To Post
         * @param {string} postId 
         * @param {Array<InlineResponse201>} [inlineResponse201] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTemporaryCanvasInputFileToPost(postId: string, inlineResponse201?: Array<InlineResponse201>, options?: any): AxiosPromise<void> {
            return localVarFp.linkTemporaryCanvasInputFileToPost(postId, inlineResponse201, options).then((request) => request(axios, basePath));
        },
        /**
         * 一時的にアップロードしていたファイルを投稿に紐づける
         * @summary Link TemporaryPostFile To Post
         * @param {string} postId 
         * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTemporaryPostFileToPost(postId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options?: any): AxiosPromise<void> {
            return localVarFp.linkTemporaryPostFileToPost(postId, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当Postを閲覧したことを登録(更新)するためのエンドポイント
         * @summary Register a post view event
         * @param {string} postId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPostView(postId: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<void> {
            return localVarFp.registerPostView(postId, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 児童が投稿を書き込んでいる様子をデモする
         * @summary Start Realtime Editing Posts Demo
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRealtimeEditingPostsDemo(courseId: string, body?: object, options?: any): AxiosPromise<string> {
            return localVarFp.startRealtimeEditingPostsDemo(courseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿のリアルタイムでの更新を行う
         * @summary Update Editing Post
         * @param {string} courseId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditingPost(courseId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.updateEditingPost(courseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿の一部更新。authorのみ可能。
         * @summary Update a Post
         * @param {string} postId 
         * @param {PostDetailUpdate} [postDetailUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost(postId: string, postDetailUpdate?: PostDetailUpdate, options?: any): AxiosPromise<PostDetailRead> {
            return localVarFp.updatePost(postId, postDetailUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostsApi - object-oriented interface
 * @export
 * @class PostsApi
 * @extends {BaseAPI}
 */
export class PostsApi extends BaseAPI {
    /**
     * 
     * @summary Change Post File Orders or Delete File
     * @param {string} postId 
     * @param {InlineObject18} [inlineObject18] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public changePostFileOrdersOrDeleteFile(postId: string, inlineObject18?: InlineObject18, options?: any) {
        return PostsApiFp(this.configuration).changePostFileOrdersOrDeleteFile(postId, inlineObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の投稿テンプレートを作る
     * @summary Create Course Post Template
     * @param {string} courseId 
     * @param {InlineObject9} [inlineObject9] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public createCoursePostTemplate(courseId: string, inlineObject9?: InlineObject9, options?: any) {
        return PostsApiFp(this.configuration).createCoursePostTemplate(courseId, inlineObject9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿の作成。指定した授業の教員か生徒しか作れない。タグは授業に紐づく、myownの自分のタグか授業で共有のタグ
     * @summary Create a Post to a Course
     * @param {string} courseId 
     * @param {PostDetailCreate} postDetailCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public createPostByCourseId(courseId: string, postDetailCreate: PostDetailCreate, options?: any) {
        return PostsApiFp(this.configuration).createPostByCourseId(courseId, postDetailCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿の作成
     * @summary Create Posts From Notes
     * @param {string} boardSetId 
     * @param {Array<InlineObject2>} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public createPostsFromNotes(boardSetId: string, inlineObject2?: Array<InlineObject2>, options?: any) {
        return PostsApiFp(this.configuration).createPostsFromNotes(boardSetId, inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿の削除。authorのみ可能。
     * @summary Detele a Post
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public deletePost(postId: string, options?: any) {
        return PostsApiFp(this.configuration).deletePost(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 配列でidを指定したcanvas input filesを削除。
     * @summary Delete Post Canvas Input Files
     * @param {string} postId 
     * @param {Array<InlineObject1>} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public deletePostCanvasInputFiles(postId: string, inlineObject1?: Array<InlineObject1>, options?: any) {
        return PostsApiFp(this.configuration).deletePostCanvasInputFiles(postId, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿の一時添付ファイルの削除
     * @summary Delete Temporary Post File
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public deleteTemporaryPostFile(fileId: string, options?: any) {
        return PostsApiFp(this.configuration).deleteTemporaryPostFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した授業の投稿一覧取得。その授業の教員か生徒、もしくはその生徒の保護者のみ可能。
     * @summary Get Posts of a Course
     * @param {string} courseId 
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getAllPostsByCourseId(courseId: string, page?: number, pageSize?: number, options?: any) {
        return PostsApiFp(this.configuration).getAllPostsByCourseId(courseId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業での、児童の公開された投稿数とコメント数を取得
     * @summary Course Post And Comment Count Get
     * @param {string} courseId 
     * @param {Array<CoursePostAndCommentCount>} [coursePostAndCommentCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getCoursePostAndCommentCount(courseId: string, coursePostAndCommentCount?: Array<CoursePostAndCommentCount>, options?: any) {
        return PostsApiFp(this.configuration).getCoursePostAndCommentCount(courseId, coursePostAndCommentCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業の、投稿テンプレートを取得
     * @summary Get Course Post Templates
     * @param {string} courseId 
     * @param {Array<PostTemplateRead>} [postTemplateRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getCoursePostTemplates(courseId: string, postTemplateRead?: Array<PostTemplateRead>, options?: any) {
        return PostsApiFp(this.configuration).getCoursePostTemplates(courseId, postTemplateRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AIに指示文を送って分析させる
     * @summary Custom Ai Analysis
     * @param {string} courseId 
     * @param {InlineObject37} [inlineObject37] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getCustomPostsAnalysis(courseId: string, inlineObject37?: InlineObject37, options?: any) {
        return PostsApiFp(this.configuration).getCustomPostsAnalysis(courseId, inlineObject37, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * later_than_or_equal_to_post_idで指定したpostより最新のcourseの投稿を取得
     * @summary Get Latest Posts in Course
     * @param {string} courseId 
     * @param {string} laterThanOrEqualToPostId pull to refresh用の、取得済み最新Postのid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getLatestPostsInCourse(courseId: string, laterThanOrEqualToPostId: string, options?: any) {
        return PostsApiFp(this.configuration).getLatestPostsInCourse(courseId, laterThanOrEqualToPostId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定のユーザーの、特定の授業での投稿の一覧表示。ページネーション。
     * @summary Get Posts of one of User\'s Course
     * @param {string} userId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getPagenatedPostsByCourseId(userId: string, courseId: string, options?: any) {
        return PostsApiFp(this.configuration).getPagenatedPostsByCourseId(userId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿を取得(検索に使用)
     * @summary Get Posts
     * @param {Array<string>} [words] 検索用ワード
     * @param {Array<string>} [authorIds] 投稿者のUUID
     * @param {Array<string>} [postTagIds] 投稿タグのUUID
     * @param {Array<string>} [classroomIds] クラスルームのUUID
     * @param {Array<string>} [courseIds] 授業のUUID
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {'course' | 'myown'} [shareRange] 投稿の公開範囲
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getPosts(words?: Array<string>, authorIds?: Array<string>, postTagIds?: Array<string>, classroomIds?: Array<string>, courseIds?: Array<string>, page?: number, pageSize?: number, shareRange?: 'course' | 'myown', options?: any) {
        return PostsApiFp(this.configuration).getPosts(words, authorIds, postTagIds, classroomIds, courseIds, page, pageSize, shareRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 一人のユーザーの全ての授業の投稿を取得。ただし、リクエストユーザーの閲覧可能な授業のみ。{user_id}はmeの自分のidのエイリアスあり
     * @summary Get all Posts of a User
     * @param {string} userId 
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getPostsByUserId(userId: string, page?: number, pageSize?: number, options?: any) {
        return PostsApiFp(this.configuration).getPostsByUserId(userId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link Temporary Canvas Input File To Post
     * @param {string} postId 
     * @param {Array<InlineResponse201>} [inlineResponse201] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public linkTemporaryCanvasInputFileToPost(postId: string, inlineResponse201?: Array<InlineResponse201>, options?: any) {
        return PostsApiFp(this.configuration).linkTemporaryCanvasInputFileToPost(postId, inlineResponse201, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 一時的にアップロードしていたファイルを投稿に紐づける
     * @summary Link TemporaryPostFile To Post
     * @param {string} postId 
     * @param {Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>} [apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public linkTemporaryPostFileToPost(postId: string, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders?: Array<ApiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders>, options?: any) {
        return PostsApiFp(this.configuration).linkTemporaryPostFileToPost(postId, apiV1AnnouncementsAnnouncementIdFileOrdersNewFileOrders, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当Postを閲覧したことを登録(更新)するためのエンドポイント
     * @summary Register a post view event
     * @param {string} postId 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public registerPostView(postId: string, inlineObject1?: InlineObject1, options?: any) {
        return PostsApiFp(this.configuration).registerPostView(postId, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 児童が投稿を書き込んでいる様子をデモする
     * @summary Start Realtime Editing Posts Demo
     * @param {string} courseId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public startRealtimeEditingPostsDemo(courseId: string, body?: object, options?: any) {
        return PostsApiFp(this.configuration).startRealtimeEditingPostsDemo(courseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿のリアルタイムでの更新を行う
     * @summary Update Editing Post
     * @param {string} courseId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public updateEditingPost(courseId: string, body?: object, options?: any) {
        return PostsApiFp(this.configuration).updateEditingPost(courseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿の一部更新。authorのみ可能。
     * @summary Update a Post
     * @param {string} postId 
     * @param {PostDetailUpdate} [postDetailUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public updatePost(postId: string, postDetailUpdate?: PostDetailUpdate, options?: any) {
        return PostsApiFp(this.configuration).updatePost(postId, postDetailUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReactionsApi - axios parameter creator
 * @export
 */
export const ReactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
         * @summary Create a Reaction to a Comment
         * @param {string} commentId 
         * @param {ReactionCreate} reactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentReaction: async (commentId: string, reactionCreate: ReactionCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('createCommentReaction', 'commentId', commentId)
            // verify required parameter 'reactionCreate' is not null or undefined
            assertParamExists('createCommentReaction', 'reactionCreate', reactionCreate)
            const localVarPath = `/api/v1/comments/{comment_id}/reactions`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reactionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
         * @summary Create a Reaction
         * @param {string} postId 
         * @param {ReactionCreate} reactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostReaction: async (postId: string, reactionCreate: ReactionCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('createPostReaction', 'postId', postId)
            // verify required parameter 'reactionCreate' is not null or undefined
            assertParamExists('createPostReaction', 'reactionCreate', reactionCreate)
            const localVarPath = `/api/v1/posts/{post_id}/reactions`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reactionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したリアクションの削除。authorのみ可能。
         * @summary Delete a Reaction
         * @param {string} reactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReaction: async (reactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reactionId' is not null or undefined
            assertParamExists('deleteReaction', 'reactionId', reactionId)
            const localVarPath = `/api/v1/reactions/{reaction_id}`
                .replace(`{${"reaction_id"}}`, encodeURIComponent(String(reactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReactionsApi - functional programming interface
 * @export
 */
export const ReactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
         * @summary Create a Reaction to a Comment
         * @param {string} commentId 
         * @param {ReactionCreate} reactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentReaction(commentId: string, reactionCreate: ReactionCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentReaction(commentId, reactionCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
         * @summary Create a Reaction
         * @param {string} postId 
         * @param {ReactionCreate} reactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPostReaction(postId: string, reactionCreate: ReactionCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPostReaction(postId, reactionCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したリアクションの削除。authorのみ可能。
         * @summary Delete a Reaction
         * @param {string} reactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReaction(reactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionListItemRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReaction(reactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReactionsApi - factory interface
 * @export
 */
export const ReactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReactionsApiFp(configuration)
    return {
        /**
         * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
         * @summary Create a Reaction to a Comment
         * @param {string} commentId 
         * @param {ReactionCreate} reactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentReaction(commentId: string, reactionCreate: ReactionCreate, options?: any): AxiosPromise<ReactionRead> {
            return localVarFp.createCommentReaction(commentId, reactionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
         * @summary Create a Reaction
         * @param {string} postId 
         * @param {ReactionCreate} reactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostReaction(postId: string, reactionCreate: ReactionCreate, options?: any): AxiosPromise<ReactionRead> {
            return localVarFp.createPostReaction(postId, reactionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したリアクションの削除。authorのみ可能。
         * @summary Delete a Reaction
         * @param {string} reactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReaction(reactionId: string, options?: any): AxiosPromise<ReactionListItemRead> {
            return localVarFp.deleteReaction(reactionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReactionsApi - object-oriented interface
 * @export
 * @class ReactionsApi
 * @extends {BaseAPI}
 */
export class ReactionsApi extends BaseAPI {
    /**
     * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
     * @summary Create a Reaction to a Comment
     * @param {string} commentId 
     * @param {ReactionCreate} reactionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionsApi
     */
    public createCommentReaction(commentId: string, reactionCreate: ReactionCreate, options?: any) {
        return ReactionsApiFp(this.configuration).createCommentReaction(commentId, reactionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リアクションの作成。閲覧できるオブジェクト(投稿かコメント)にしか作成できない。        target_model_nameは、投稿かコメントを表し、target_object_idは、そのidを表す。target_model_nameとpathの整合性制約をかけられていないので、変更するかも
     * @summary Create a Reaction
     * @param {string} postId 
     * @param {ReactionCreate} reactionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionsApi
     */
    public createPostReaction(postId: string, reactionCreate: ReactionCreate, options?: any) {
        return ReactionsApiFp(this.configuration).createPostReaction(postId, reactionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したリアクションの削除。authorのみ可能。
     * @summary Delete a Reaction
     * @param {string} reactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionsApi
     */
    public deleteReaction(reactionId: string, options?: any) {
        return ReactionsApiFp(this.configuration).deleteReaction(reactionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 不快報告一覧の取得（ページネーション）
         * @summary Get Discomfort Reports
         * @param {string} courseId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscomfortReports: async (courseId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getDiscomfortReports', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/discomfort_reports`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントに対する不快報告のPost
         * @summary Post Discomfort Report Of Comment
         * @param {string} commentId 
         * @param {DiscomfortReportCreate} [discomfortReportCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiscomfortReportOfComment: async (commentId: string, discomfortReportCreate?: DiscomfortReportCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('postDiscomfortReportOfComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}/discomfort_reports`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discomfortReportCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿に対する不快報告のPost
         * @summary Post Discomfort Report Of Post
         * @param {string} postId 
         * @param {DiscomfortReportCreate} [discomfortReportCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiscomfortReportOfPost: async (postId: string, discomfortReportCreate?: DiscomfortReportCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('postDiscomfortReportOfPost', 'postId', postId)
            const localVarPath = `/api/v1/posts/{post_id}/discomfort_reports`
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discomfortReportCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 不快報告一覧の取得（ページネーション）
         * @summary Get Discomfort Reports
         * @param {string} courseId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscomfortReports(courseId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscomfortReportListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscomfortReports(courseId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントに対する不快報告のPost
         * @summary Post Discomfort Report Of Comment
         * @param {string} commentId 
         * @param {DiscomfortReportCreate} [discomfortReportCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDiscomfortReportOfComment(commentId: string, discomfortReportCreate?: DiscomfortReportCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDiscomfortReportOfComment(commentId, discomfortReportCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿に対する不快報告のPost
         * @summary Post Discomfort Report Of Post
         * @param {string} postId 
         * @param {DiscomfortReportCreate} [discomfortReportCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDiscomfortReportOfPost(postId: string, discomfortReportCreate?: DiscomfortReportCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDiscomfortReportOfPost(postId, discomfortReportCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 不快報告一覧の取得（ページネーション）
         * @summary Get Discomfort Reports
         * @param {string} courseId 
         * @param {number} [page] A page number within the paginated result set.何ページ目か
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscomfortReports(courseId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<DiscomfortReportListPaginated> {
            return localVarFp.getDiscomfortReports(courseId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントに対する不快報告のPost
         * @summary Post Discomfort Report Of Comment
         * @param {string} commentId 
         * @param {DiscomfortReportCreate} [discomfortReportCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiscomfortReportOfComment(commentId: string, discomfortReportCreate?: DiscomfortReportCreate, options?: any): AxiosPromise<void> {
            return localVarFp.postDiscomfortReportOfComment(commentId, discomfortReportCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿に対する不快報告のPost
         * @summary Post Discomfort Report Of Post
         * @param {string} postId 
         * @param {DiscomfortReportCreate} [discomfortReportCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDiscomfortReportOfPost(postId: string, discomfortReportCreate?: DiscomfortReportCreate, options?: any): AxiosPromise<void> {
            return localVarFp.postDiscomfortReportOfPost(postId, discomfortReportCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 不快報告一覧の取得（ページネーション）
     * @summary Get Discomfort Reports
     * @param {string} courseId 
     * @param {number} [page] A page number within the paginated result set.何ページ目か
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDiscomfortReports(courseId: string, page?: number, pageSize?: number, options?: any) {
        return ReportsApiFp(this.configuration).getDiscomfortReports(courseId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コメントに対する不快報告のPost
     * @summary Post Discomfort Report Of Comment
     * @param {string} commentId 
     * @param {DiscomfortReportCreate} [discomfortReportCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public postDiscomfortReportOfComment(commentId: string, discomfortReportCreate?: DiscomfortReportCreate, options?: any) {
        return ReportsApiFp(this.configuration).postDiscomfortReportOfComment(commentId, discomfortReportCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿に対する不快報告のPost
     * @summary Post Discomfort Report Of Post
     * @param {string} postId 
     * @param {DiscomfortReportCreate} [discomfortReportCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public postDiscomfortReportOfPost(postId: string, discomfortReportCreate?: DiscomfortReportCreate, options?: any) {
        return ReportsApiFp(this.configuration).postDiscomfortReportOfPost(postId, discomfortReportCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchemaApi - axios parameter creator
 * @export
 */
export const SchemaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         * @param {'json' | 'yaml'} [format] 
         * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaRetrieve: async (format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchemaApi - functional programming interface
 * @export
 */
export const SchemaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchemaApiAxiosParamCreator(configuration)
    return {
        /**
         * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         * @param {'json' | 'yaml'} [format] 
         * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schemaRetrieve(format, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchemaApi - factory interface
 * @export
 */
export const SchemaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchemaApiFp(configuration)
    return {
        /**
         * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         * @param {'json' | 'yaml'} [format] 
         * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.schemaRetrieve(format, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchemaApi - object-oriented interface
 * @export
 * @class SchemaApi
 * @extends {BaseAPI}
 */
export class SchemaApi extends BaseAPI {
    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param {'json' | 'yaml'} [format] 
     * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', options?: any) {
        return SchemaApiFp(this.configuration).schemaRetrieve(format, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchoolsApi - axios parameter creator
 * @export
 */
export const SchoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した学校の情報の取得。その学校の教員のみ閲覧可能。
         * @summary Get my School
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学校のjoin_codeを取得。その学校の教員のみ閲覧可能。
         * @summary Get a join code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolJoinCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/join_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校の参加コードを再発行する
         * @summary Reset School Join Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSchoolJoinCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/join_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学校の一部更新(学校は変えられない)。その学校の教員のみ更新可能。
         * @summary Update a School
         * @param {SchoolUpdate} [schoolUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchool: async (schoolUpdate?: SchoolUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schoolUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校の参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
         * @summary Validate School Join Code
         * @param {JoinCodeCheck} joinCodeCheck 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSchoolJoinCode: async (joinCodeCheck: JoinCodeCheck, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinCodeCheck' is not null or undefined
            assertParamExists('validateSchoolJoinCode', 'joinCodeCheck', joinCodeCheck)
            const localVarPath = `/api/v1/schools/join_codes/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinCodeCheck, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchoolsApi - functional programming interface
 * @export
 */
export const SchoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した学校の情報の取得。その学校の教員のみ閲覧可能。
         * @summary Get my School
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchool(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchoolRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchool(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学校のjoin_codeを取得。その学校の教員のみ閲覧可能。
         * @summary Get a join code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchoolJoinCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchoolJoinCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolJoinCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校の参加コードを再発行する
         * @summary Reset School Join Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetSchoolJoinCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetSchoolJoinCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学校の一部更新(学校は変えられない)。その学校の教員のみ更新可能。
         * @summary Update a School
         * @param {SchoolUpdate} [schoolUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSchool(schoolUpdate?: SchoolUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchoolRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSchool(schoolUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校の参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
         * @summary Validate School Join Code
         * @param {JoinCodeCheck} joinCodeCheck 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSchoolJoinCode(joinCodeCheck: JoinCodeCheck, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSchoolJoinCode(joinCodeCheck, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchoolsApi - factory interface
 * @export
 */
export const SchoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchoolsApiFp(configuration)
    return {
        /**
         * 指定した学校の情報の取得。その学校の教員のみ閲覧可能。
         * @summary Get my School
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool(options?: any): AxiosPromise<SchoolRead> {
            return localVarFp.getSchool(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学校のjoin_codeを取得。その学校の教員のみ閲覧可能。
         * @summary Get a join code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolJoinCode(options?: any): AxiosPromise<SchoolJoinCode> {
            return localVarFp.getSchoolJoinCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 学校の参加コードを再発行する
         * @summary Reset School Join Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSchoolJoinCode(options?: any): AxiosPromise<void> {
            return localVarFp.resetSchoolJoinCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学校の一部更新(学校は変えられない)。その学校の教員のみ更新可能。
         * @summary Update a School
         * @param {SchoolUpdate} [schoolUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchool(schoolUpdate?: SchoolUpdate, options?: any): AxiosPromise<SchoolRead> {
            return localVarFp.updateSchool(schoolUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 学校の参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
         * @summary Validate School Join Code
         * @param {JoinCodeCheck} joinCodeCheck 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSchoolJoinCode(joinCodeCheck: JoinCodeCheck, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.validateSchoolJoinCode(joinCodeCheck, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchoolsApi - object-oriented interface
 * @export
 * @class SchoolsApi
 * @extends {BaseAPI}
 */
export class SchoolsApi extends BaseAPI {
    /**
     * 指定した学校の情報の取得。その学校の教員のみ閲覧可能。
     * @summary Get my School
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public getSchool(options?: any) {
        return SchoolsApiFp(this.configuration).getSchool(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学校のjoin_codeを取得。その学校の教員のみ閲覧可能。
     * @summary Get a join code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public getSchoolJoinCode(options?: any) {
        return SchoolsApiFp(this.configuration).getSchoolJoinCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校の参加コードを再発行する
     * @summary Reset School Join Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public resetSchoolJoinCode(options?: any) {
        return SchoolsApiFp(this.configuration).resetSchoolJoinCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学校の一部更新(学校は変えられない)。その学校の教員のみ更新可能。
     * @summary Update a School
     * @param {SchoolUpdate} [schoolUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public updateSchool(schoolUpdate?: SchoolUpdate, options?: any) {
        return SchoolsApiFp(this.configuration).updateSchool(schoolUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校の参加コードの確認。ログやRefererにjoin_codeを残したくないので、POSTを使う。
     * @summary Validate School Join Code
     * @param {JoinCodeCheck} joinCodeCheck 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public validateSchoolJoinCode(joinCodeCheck: JoinCodeCheck, options?: any) {
        return SchoolsApiFp(this.configuration).validateSchoolJoinCode(joinCodeCheck, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudentsApi - axios parameter creator
 * @export
 */
export const StudentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クラスを変える時
         * @summary Student Change Class
         * @param {InlineObject26} [inlineObject26] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeClassroom: async (inlineObject26?: InlineObject26, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/students/me/change_classroom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject26, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教師専用。児童生徒のクラスの訂正
         * @param {string} studentId 
         * @param {string} classroomId 
         * @param {InlineObject7} [inlineObject7] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctStudentClassroom: async (studentId: string, classroomId: string, inlineObject7?: InlineObject7, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('correctStudentClassroom', 'studentId', studentId)
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('correctStudentClassroom', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students_for_privileged/{student_id}/classroom_correct`
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)))
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生徒の登録
         * @summary Create a Student
         * @param {StudentCreate} studentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudent: async (studentCreate: StudentCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentCreate' is not null or undefined
            assertParamExists('createStudent', 'studentCreate', studentCreate)
            const localVarPath = `/api/v1/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * patron join codeを送って、そのクラスの児童一覧を得る
         * @summary Get Classroom Students By Patron Join Codes
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomStudentsByPatronJoinCode: async (body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patron_join_codes/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ある授業のクラスごとの児童の取得
         * @summary Course Students Get
         * @param {string} courseId 
         * @param {Array<InlineObject>} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseStudents: async (courseId: string, inlineObject?: Array<InlineObject>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseStudents', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/students`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意の生徒情報を取得
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudent: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('getStudent', 'studentId', studentId)
            const localVarPath = `/api/v1/students/{student_id}`
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが属している授業の生徒を取得
         * @summary Get Students in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsInMyCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者確認リクエストを生徒に出す。
         * @summary Patron Apply To Student
         * @param {boolean} [onlyCheck] trueの場合は生徒情報を得るだけ
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPatron: async (onlyCheck?: boolean, inlineObject3?: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patron_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyCheck !== undefined) {
                localVarQueryParameter['only_check'] = onlyCheck;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 同じ学校、同じ年度のクラスに現在のクラスを変更する(特別支援学級よう)
         * @summary Student Change Class
         * @param {InlineObject27} [inlineObject27] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchCurrentClassroom: async (inlineObject27?: InlineObject27, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/students/me/current_classrooms_switch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject27, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生徒のRole更新
         * @summary Update a Student
         * @param {string} studentId 
         * @param {string} [firstName] 名前（漢字）
         * @param {string} [firstNameKana] なまえ（かな）
         * @param {string} [lastName] 苗字（漢字）
         * @param {string} [lastNameKana] みょうじ（なまえ）
         * @param {number} [numInClass] 出席番号
         * @param {any} [profileImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudent: async (studentId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, numInClass?: number, profileImage?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('updateStudent', 'studentId', studentId)
            const localVarPath = `/api/v1/students/{student_id}`
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (firstName !== undefined) { 
                localVarFormParams.set('first_name', firstName as any);
            }
    
            if (firstNameKana !== undefined) { 
                localVarFormParams.set('first_name_kana', firstNameKana as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.set('last_name', lastName as any);
            }
    
            if (lastNameKana !== undefined) { 
                localVarFormParams.set('last_name_kana', lastNameKana as any);
            }
    
            if (numInClass !== undefined) { 
                localVarFormParams.set('num_in_class', numInClass as any);
            }
    
            if (profileImage !== undefined) { 
                localVarFormParams.set('profile_image', profileImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教師専用。児童のメールアドレスなどの変更
         * @param {string} studentId 
         * @param {string} classroomId 
         * @param {InlineObject6} [inlineObject6] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentForPrivileged: async (studentId: string, classroomId: string, inlineObject6?: InlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('updateStudentForPrivileged', 'studentId', studentId)
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('updateStudentForPrivileged', 'classroomId', classroomId)
            const localVarPath = `/api/v1/classrooms/{classroom_id}/students_for_privileged/{student_id}`
                .replace(`{${"student_id"}}`, encodeURIComponent(String(studentId)))
                .replace(`{${"classroom_id"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者コードが有効かどうか確かめる
         * @summary Validate Patron Code
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePatronCode: async (inlineObject4?: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/students/patron_codes/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentsApi - functional programming interface
 * @export
 */
export const StudentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentsApiAxiosParamCreator(configuration)
    return {
        /**
         * クラスを変える時
         * @summary Student Change Class
         * @param {InlineObject26} [inlineObject26] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeClassroom(inlineObject26?: InlineObject26, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomSimpleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeClassroom(inlineObject26, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教師専用。児童生徒のクラスの訂正
         * @param {string} studentId 
         * @param {string} classroomId 
         * @param {InlineObject7} [inlineObject7] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctStudentClassroom(studentId: string, classroomId: string, inlineObject7?: InlineObject7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctStudentClassroom(studentId, classroomId, inlineObject7, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 生徒の登録
         * @summary Create a Student
         * @param {StudentCreate} studentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudent(studentCreate: StudentCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStudent(studentCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * patron join codeを送って、そのクラスの児童一覧を得る
         * @summary Get Classroom Students By Patron Join Codes
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroomStudentsByPatronJoinCode(body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassroomStudentRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroomStudentsByPatronJoinCode(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ある授業のクラスごとの児童の取得
         * @summary Course Students Get
         * @param {string} courseId 
         * @param {Array<InlineObject>} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseStudents(courseId: string, inlineObject?: Array<InlineObject>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseStudents(courseId, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意の生徒情報を取得
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudent(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudent(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーが属している授業の生徒を取得
         * @summary Get Students in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsInMyCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentUserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsInMyCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者確認リクエストを生徒に出す。
         * @summary Patron Apply To Student
         * @param {boolean} [onlyCheck] trueの場合は生徒情報を得るだけ
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPatron(onlyCheck?: boolean, inlineObject3?: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPatron(onlyCheck, inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 同じ学校、同じ年度のクラスに現在のクラスを変更する(特別支援学級よう)
         * @summary Student Change Class
         * @param {InlineObject27} [inlineObject27] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchCurrentClassroom(inlineObject27?: InlineObject27, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeClassroomSimpleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchCurrentClassroom(inlineObject27, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 生徒のRole更新
         * @summary Update a Student
         * @param {string} studentId 
         * @param {string} [firstName] 名前（漢字）
         * @param {string} [firstNameKana] なまえ（かな）
         * @param {string} [lastName] 苗字（漢字）
         * @param {string} [lastNameKana] みょうじ（なまえ）
         * @param {number} [numInClass] 出席番号
         * @param {any} [profileImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudent(studentId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, numInClass?: number, profileImage?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudent(studentId, firstName, firstNameKana, lastName, lastNameKana, numInClass, profileImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教師専用。児童のメールアドレスなどの変更
         * @param {string} studentId 
         * @param {string} classroomId 
         * @param {InlineObject6} [inlineObject6] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudentForPrivileged(studentId: string, classroomId: string, inlineObject6?: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudentForPrivileged(studentId, classroomId, inlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者コードが有効かどうか確かめる
         * @summary Validate Patron Code
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePatronCode(inlineObject4?: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePatronCode(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudentsApi - factory interface
 * @export
 */
export const StudentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentsApiFp(configuration)
    return {
        /**
         * クラスを変える時
         * @summary Student Change Class
         * @param {InlineObject26} [inlineObject26] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeClassroom(inlineObject26?: InlineObject26, options?: any): AxiosPromise<HomeClassroomSimpleRead> {
            return localVarFp.changeClassroom(inlineObject26, options).then((request) => request(axios, basePath));
        },
        /**
         * 教師専用。児童生徒のクラスの訂正
         * @param {string} studentId 
         * @param {string} classroomId 
         * @param {InlineObject7} [inlineObject7] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctStudentClassroom(studentId: string, classroomId: string, inlineObject7?: InlineObject7, options?: any): AxiosPromise<void> {
            return localVarFp.correctStudentClassroom(studentId, classroomId, inlineObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * 生徒の登録
         * @summary Create a Student
         * @param {StudentCreate} studentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudent(studentCreate: StudentCreate, options?: any): AxiosPromise<InlineResponse2013> {
            return localVarFp.createStudent(studentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * patron join codeを送って、そのクラスの児童一覧を得る
         * @summary Get Classroom Students By Patron Join Codes
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroomStudentsByPatronJoinCode(body?: object, options?: any): AxiosPromise<Array<ClassroomStudentRead>> {
            return localVarFp.getClassroomStudentsByPatronJoinCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * ある授業のクラスごとの児童の取得
         * @summary Course Students Get
         * @param {string} courseId 
         * @param {Array<InlineObject>} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseStudents(courseId: string, inlineObject?: Array<InlineObject>, options?: any): AxiosPromise<void> {
            return localVarFp.getCourseStudents(courseId, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意の生徒情報を取得
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudent(studentId: string, options?: any): AxiosPromise<StudentUserRead> {
            return localVarFp.getStudent(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが属している授業の生徒を取得
         * @summary Get Students in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsInMyCourses(options?: any): AxiosPromise<Array<StudentUserRead>> {
            return localVarFp.getStudentsInMyCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者確認リクエストを生徒に出す。
         * @summary Patron Apply To Student
         * @param {boolean} [onlyCheck] trueの場合は生徒情報を得るだけ
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPatron(onlyCheck?: boolean, inlineObject3?: InlineObject3, options?: any): AxiosPromise<StudentUserRead> {
            return localVarFp.requestPatron(onlyCheck, inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 同じ学校、同じ年度のクラスに現在のクラスを変更する(特別支援学級よう)
         * @summary Student Change Class
         * @param {InlineObject27} [inlineObject27] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchCurrentClassroom(inlineObject27?: InlineObject27, options?: any): AxiosPromise<HomeClassroomSimpleRead> {
            return localVarFp.switchCurrentClassroom(inlineObject27, options).then((request) => request(axios, basePath));
        },
        /**
         * 生徒のRole更新
         * @summary Update a Student
         * @param {string} studentId 
         * @param {string} [firstName] 名前（漢字）
         * @param {string} [firstNameKana] なまえ（かな）
         * @param {string} [lastName] 苗字（漢字）
         * @param {string} [lastNameKana] みょうじ（なまえ）
         * @param {number} [numInClass] 出席番号
         * @param {any} [profileImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudent(studentId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, numInClass?: number, profileImage?: any, options?: any): AxiosPromise<StudentUserRead> {
            return localVarFp.updateStudent(studentId, firstName, firstNameKana, lastName, lastNameKana, numInClass, profileImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 教師専用。児童のメールアドレスなどの変更
         * @param {string} studentId 
         * @param {string} classroomId 
         * @param {InlineObject6} [inlineObject6] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentForPrivileged(studentId: string, classroomId: string, inlineObject6?: InlineObject6, options?: any): AxiosPromise<void> {
            return localVarFp.updateStudentForPrivileged(studentId, classroomId, inlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者コードが有効かどうか確かめる
         * @summary Validate Patron Code
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePatronCode(inlineObject4?: InlineObject4, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.validatePatronCode(inlineObject4, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentsApi - object-oriented interface
 * @export
 * @class StudentsApi
 * @extends {BaseAPI}
 */
export class StudentsApi extends BaseAPI {
    /**
     * クラスを変える時
     * @summary Student Change Class
     * @param {InlineObject26} [inlineObject26] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public changeClassroom(inlineObject26?: InlineObject26, options?: any) {
        return StudentsApiFp(this.configuration).changeClassroom(inlineObject26, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教師専用。児童生徒のクラスの訂正
     * @param {string} studentId 
     * @param {string} classroomId 
     * @param {InlineObject7} [inlineObject7] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public correctStudentClassroom(studentId: string, classroomId: string, inlineObject7?: InlineObject7, options?: any) {
        return StudentsApiFp(this.configuration).correctStudentClassroom(studentId, classroomId, inlineObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生徒の登録
     * @summary Create a Student
     * @param {StudentCreate} studentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public createStudent(studentCreate: StudentCreate, options?: any) {
        return StudentsApiFp(this.configuration).createStudent(studentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * patron join codeを送って、そのクラスの児童一覧を得る
     * @summary Get Classroom Students By Patron Join Codes
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public getClassroomStudentsByPatronJoinCode(body?: object, options?: any) {
        return StudentsApiFp(this.configuration).getClassroomStudentsByPatronJoinCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ある授業のクラスごとの児童の取得
     * @summary Course Students Get
     * @param {string} courseId 
     * @param {Array<InlineObject>} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public getCourseStudents(courseId: string, inlineObject?: Array<InlineObject>, options?: any) {
        return StudentsApiFp(this.configuration).getCourseStudents(courseId, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意の生徒情報を取得
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public getStudent(studentId: string, options?: any) {
        return StudentsApiFp(this.configuration).getStudent(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが属している授業の生徒を取得
     * @summary Get Students in Users Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public getStudentsInMyCourses(options?: any) {
        return StudentsApiFp(this.configuration).getStudentsInMyCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者確認リクエストを生徒に出す。
     * @summary Patron Apply To Student
     * @param {boolean} [onlyCheck] trueの場合は生徒情報を得るだけ
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public requestPatron(onlyCheck?: boolean, inlineObject3?: InlineObject3, options?: any) {
        return StudentsApiFp(this.configuration).requestPatron(onlyCheck, inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 同じ学校、同じ年度のクラスに現在のクラスを変更する(特別支援学級よう)
     * @summary Student Change Class
     * @param {InlineObject27} [inlineObject27] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public switchCurrentClassroom(inlineObject27?: InlineObject27, options?: any) {
        return StudentsApiFp(this.configuration).switchCurrentClassroom(inlineObject27, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生徒のRole更新
     * @summary Update a Student
     * @param {string} studentId 
     * @param {string} [firstName] 名前（漢字）
     * @param {string} [firstNameKana] なまえ（かな）
     * @param {string} [lastName] 苗字（漢字）
     * @param {string} [lastNameKana] みょうじ（なまえ）
     * @param {number} [numInClass] 出席番号
     * @param {any} [profileImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public updateStudent(studentId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, numInClass?: number, profileImage?: any, options?: any) {
        return StudentsApiFp(this.configuration).updateStudent(studentId, firstName, firstNameKana, lastName, lastNameKana, numInClass, profileImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教師専用。児童のメールアドレスなどの変更
     * @param {string} studentId 
     * @param {string} classroomId 
     * @param {InlineObject6} [inlineObject6] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public updateStudentForPrivileged(studentId: string, classroomId: string, inlineObject6?: InlineObject6, options?: any) {
        return StudentsApiFp(this.configuration).updateStudentForPrivileged(studentId, classroomId, inlineObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者コードが有効かどうか確かめる
     * @summary Validate Patron Code
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public validatePatronCode(inlineObject4?: InlineObject4, options?: any) {
        return StudentsApiFp(this.configuration).validatePatronCode(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportChatApi - axios parameter creator
 * @export
 */
export const SupportChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSupportChatMessages: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserSupportChatMessages', 'userId', userId)
            const localVarPath = `/api/v1/users/{user_id}/support_chat_messages`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportChatApi - functional programming interface
 * @export
 */
export const SupportChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSupportChatMessages(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportChatMessageListPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSupportChatMessages(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportChatApi - factory interface
 * @export
 */
export const SupportChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSupportChatMessages(userId: string, options?: any): AxiosPromise<SupportChatMessageListPaginated> {
            return localVarFp.getUserSupportChatMessages(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportChatApi - object-oriented interface
 * @export
 * @class SupportChatApi
 * @extends {BaseAPI}
 */
export class SupportChatApi extends BaseAPI {
    /**
     * 
     * @summary Your GET endpoint
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportChatApi
     */
    public getUserSupportChatMessages(userId: string, options?: any) {
        return SupportChatApiFp(this.configuration).getUserSupportChatMessages(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeachersApi - axios parameter creator
 * @export
 */
export const TeachersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 教師が学校を移動する際にPOSTする
         * @summary Teacher Change School
         * @param {InlineObject28} [inlineObject28] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSchool: async (inlineObject28?: InlineObject28, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teachers/me/change_school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject28, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の授業に参加する権限を持った(他の授業にも参加する権限を持つこともできる)外部講師アカウントを作成する
         * @summary Create And Invite Course Guest Teacher
         * @param {string} courseId 
         * @param {InlineObject38} [inlineObject38] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndInviteCourseGuestTeacher: async (courseId: string, inlineObject38?: InlineObject38, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('createAndInviteCourseGuestTeacher', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/guest_teachers`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject38, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教員の登録
         * @summary Create a Teacher
         * @param {TeacherCreate} teacherCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacher: async (teacherCreate: TeacherCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherCreate' is not null or undefined
            assertParamExists('createTeacher', 'teacherCreate', teacherCreate)
            const localVarPath = `/api/v1/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teacherCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 外部向け教師IDから教師を取得
         * @summary Get Teacher by ID for External School
         * @param {string} idForExternalSchool 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherByIdForExternalSchool: async (idForExternalSchool: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'idForExternalSchool' is not null or undefined
            assertParamExists('getTeacherByIdForExternalSchool', 'idForExternalSchool', idForExternalSchool)
            const localVarPath = `/api/v1/teachers/id_for_external_school/{id_for_external_school}`
                .replace(`{${"id_for_external_school"}}`, encodeURIComponent(String(idForExternalSchool)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分の属している授業の教員を取得
         * @summary Get Teachers in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersInUsersCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学校の教師一覧取得。その学校の教員のみ取得可能。
         * @summary Get Teachers of my School
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersOfSchool: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/schools/me/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教師更新
         * @summary Update a Teacher
         * @param {string} teacherId 
         * @param {string} [firstName] 名前（漢字）
         * @param {string} [firstNameKana] なまえ（かな）
         * @param {string} [lastName] 苗字（漢字）
         * @param {string} [lastNameKana] みょうじ（なまえ）
         * @param {any} [profileImage] プロフィール画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacher: async (teacherId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, profileImage?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('updateTeacher', 'teacherId', teacherId)
            const localVarPath = `/api/v1/teachers/{teacher_id}`
                .replace(`{${"teacher_id"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (firstName !== undefined) { 
                localVarFormParams.set('first_name', firstName as any);
            }
    
            if (firstNameKana !== undefined) { 
                localVarFormParams.set('first_name_kana', firstNameKana as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.set('last_name', lastName as any);
            }
    
            if (lastNameKana !== undefined) { 
                localVarFormParams.set('last_name_kana', lastNameKana as any);
            }
    
            if (profileImage !== undefined) { 
                localVarFormParams.set('profile_image', profileImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeachersApi - functional programming interface
 * @export
 */
export const TeachersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeachersApiAxiosParamCreator(configuration)
    return {
        /**
         * 教師が学校を移動する際にPOSTする
         * @summary Teacher Change School
         * @param {InlineObject28} [inlineObject28] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeSchool(inlineObject28?: InlineObject28, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchoolRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeSchool(inlineObject28, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の授業に参加する権限を持った(他の授業にも参加する権限を持つこともできる)外部講師アカウントを作成する
         * @summary Create And Invite Course Guest Teacher
         * @param {string} courseId 
         * @param {InlineObject38} [inlineObject38] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndInviteCourseGuestTeacher(courseId: string, inlineObject38?: InlineObject38, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestTeacherMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndInviteCourseGuestTeacher(courseId, inlineObject38, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教員の登録
         * @summary Create a Teacher
         * @param {TeacherCreate} teacherCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeacher(teacherCreate: TeacherCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeacher(teacherCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 外部向け教師IDから教師を取得
         * @summary Get Teacher by ID for External School
         * @param {string} idForExternalSchool 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherByIdForExternalSchool(idForExternalSchool: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacherByIdForExternalSchool(idForExternalSchool, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分の属している授業の教員を取得
         * @summary Get Teachers in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachersInUsersCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeacherUserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachersInUsersCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学校の教師一覧取得。その学校の教員のみ取得可能。
         * @summary Get Teachers of my School
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachersOfSchool(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachersOfSchool(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教師更新
         * @summary Update a Teacher
         * @param {string} teacherId 
         * @param {string} [firstName] 名前（漢字）
         * @param {string} [firstNameKana] なまえ（かな）
         * @param {string} [lastName] 苗字（漢字）
         * @param {string} [lastNameKana] みょうじ（なまえ）
         * @param {any} [profileImage] プロフィール画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeacher(teacherId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, profileImage?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeacherUserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeacher(teacherId, firstName, firstNameKana, lastName, lastNameKana, profileImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeachersApi - factory interface
 * @export
 */
export const TeachersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeachersApiFp(configuration)
    return {
        /**
         * 教師が学校を移動する際にPOSTする
         * @summary Teacher Change School
         * @param {InlineObject28} [inlineObject28] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSchool(inlineObject28?: InlineObject28, options?: any): AxiosPromise<SchoolRead> {
            return localVarFp.changeSchool(inlineObject28, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の授業に参加する権限を持った(他の授業にも参加する権限を持つこともできる)外部講師アカウントを作成する
         * @summary Create And Invite Course Guest Teacher
         * @param {string} courseId 
         * @param {InlineObject38} [inlineObject38] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndInviteCourseGuestTeacher(courseId: string, inlineObject38?: InlineObject38, options?: any): AxiosPromise<GuestTeacherMeta> {
            return localVarFp.createAndInviteCourseGuestTeacher(courseId, inlineObject38, options).then((request) => request(axios, basePath));
        },
        /**
         * 教員の登録
         * @summary Create a Teacher
         * @param {TeacherCreate} teacherCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacher(teacherCreate: TeacherCreate, options?: any): AxiosPromise<InlineResponse2014> {
            return localVarFp.createTeacher(teacherCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 外部向け教師IDから教師を取得
         * @summary Get Teacher by ID for External School
         * @param {string} idForExternalSchool 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherByIdForExternalSchool(idForExternalSchool: string, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.getTeacherByIdForExternalSchool(idForExternalSchool, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分の属している授業の教員を取得
         * @summary Get Teachers in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersInUsersCourses(options?: any): AxiosPromise<Array<TeacherUserRead>> {
            return localVarFp.getTeachersInUsersCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 学校の教師一覧取得。その学校の教員のみ取得可能。
         * @summary Get Teachers of my School
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersOfSchool(options?: any): AxiosPromise<Array<UserRead>> {
            return localVarFp.getTeachersOfSchool(options).then((request) => request(axios, basePath));
        },
        /**
         * 教師更新
         * @summary Update a Teacher
         * @param {string} teacherId 
         * @param {string} [firstName] 名前（漢字）
         * @param {string} [firstNameKana] なまえ（かな）
         * @param {string} [lastName] 苗字（漢字）
         * @param {string} [lastNameKana] みょうじ（なまえ）
         * @param {any} [profileImage] プロフィール画像
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeacher(teacherId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, profileImage?: any, options?: any): AxiosPromise<TeacherUserRead> {
            return localVarFp.updateTeacher(teacherId, firstName, firstNameKana, lastName, lastNameKana, profileImage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeachersApi - object-oriented interface
 * @export
 * @class TeachersApi
 * @extends {BaseAPI}
 */
export class TeachersApi extends BaseAPI {
    /**
     * 教師が学校を移動する際にPOSTする
     * @summary Teacher Change School
     * @param {InlineObject28} [inlineObject28] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public changeSchool(inlineObject28?: InlineObject28, options?: any) {
        return TeachersApiFp(this.configuration).changeSchool(inlineObject28, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の授業に参加する権限を持った(他の授業にも参加する権限を持つこともできる)外部講師アカウントを作成する
     * @summary Create And Invite Course Guest Teacher
     * @param {string} courseId 
     * @param {InlineObject38} [inlineObject38] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public createAndInviteCourseGuestTeacher(courseId: string, inlineObject38?: InlineObject38, options?: any) {
        return TeachersApiFp(this.configuration).createAndInviteCourseGuestTeacher(courseId, inlineObject38, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教員の登録
     * @summary Create a Teacher
     * @param {TeacherCreate} teacherCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public createTeacher(teacherCreate: TeacherCreate, options?: any) {
        return TeachersApiFp(this.configuration).createTeacher(teacherCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 外部向け教師IDから教師を取得
     * @summary Get Teacher by ID for External School
     * @param {string} idForExternalSchool 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getTeacherByIdForExternalSchool(idForExternalSchool: string, options?: any) {
        return TeachersApiFp(this.configuration).getTeacherByIdForExternalSchool(idForExternalSchool, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分の属している授業の教員を取得
     * @summary Get Teachers in Users Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getTeachersInUsersCourses(options?: any) {
        return TeachersApiFp(this.configuration).getTeachersInUsersCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学校の教師一覧取得。その学校の教員のみ取得可能。
     * @summary Get Teachers of my School
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public getTeachersOfSchool(options?: any) {
        return TeachersApiFp(this.configuration).getTeachersOfSchool(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教師更新
     * @summary Update a Teacher
     * @param {string} teacherId 
     * @param {string} [firstName] 名前（漢字）
     * @param {string} [firstNameKana] なまえ（かな）
     * @param {string} [lastName] 苗字（漢字）
     * @param {string} [lastNameKana] みょうじ（なまえ）
     * @param {any} [profileImage] プロフィール画像
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public updateTeacher(teacherId: string, firstName?: string, firstNameKana?: string, lastName?: string, lastNameKana?: string, profileImage?: any, options?: any) {
        return TeachersApiFp(this.configuration).updateTeacher(teacherId, firstName, firstNameKana, lastName, lastNameKana, profileImage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create a User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreate: UserCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('createUser', 'userCreate', userCreate)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create User By Id Token
         * @param {UserCreateByIdToken} userCreateByIdToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByIdToken: async (userCreateByIdToken: UserCreateByIdToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateByIdToken' is not null or undefined
            assertParamExists('createUserByIdToken', 'userCreateByIdToken', userCreateByIdToken)
            const localVarPath = `/api/v1/users/by_id_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateByIdToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのtypeによって取得データが変わる 以下を取得 教師 => 授業の担当教師、生徒、保護者 生徒 => 授業の担当教師、、生徒、自分の保護者 保護者 => 授業の担当教師、自分の生徒、自分の生徒の保護者 クエリストリングで取得したいroleTypeを指定するが、なしなら全て取得
         * @summary Get Course Members
         * @param {string} courseId 
         * @param {boolean} [teacher] 教師を含めるかどうか
         * @param {boolean} [student] 生徒を含めるかどうか
         * @param {string} [patron] 保護者を含めるかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseMembers: async (courseId: string, teacher?: boolean, student?: boolean, patron?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getCourseMembers', 'courseId', courseId)
            const localVarPath = `/api/v1/courses/{course_id}/members`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teacher !== undefined) {
                localVarQueryParameter['teacher'] = teacher;
            }

            if (student !== undefined) {
                localVarQueryParameter['student'] = student;
            }

            if (patron !== undefined) {
                localVarQueryParameter['patron'] = patron;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが属している授業の生徒を取得
         * @summary Get Students in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsInMyCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分の属している授業の教員を取得
         * @summary Get Teachers in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersInUsersCourses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me/courses/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザのプロフィール情報を取得できるエンドポイント
         * @summary Get a User Detail Data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetail: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserDetail', 'userId', userId)
            const localVarPath = `/api/v1/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * (private)自分自身の情報を返すエンドポイント
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPrivateData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザの更新（パスワード）
         * @summary Update a User
         * @param {string} userId 
         * @param {UserUpdate} [userUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userUpdate?: UserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * (private)自分自身の情報を更新
         * @param {UserPrivateUpdate} [userPrivateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPrivateData: async (userPrivateUpdate?: UserPrivateUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPrivateUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create a User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreate: UserCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create User By Id Token
         * @param {UserCreateByIdToken} userCreateByIdToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserByIdToken(userCreateByIdToken: UserCreateByIdToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserByIdToken(userCreateByIdToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのtypeによって取得データが変わる 以下を取得 教師 => 授業の担当教師、生徒、保護者 生徒 => 授業の担当教師、、生徒、自分の保護者 保護者 => 授業の担当教師、自分の生徒、自分の生徒の保護者 クエリストリングで取得したいroleTypeを指定するが、なしなら全て取得
         * @summary Get Course Members
         * @param {string} courseId 
         * @param {boolean} [teacher] 教師を含めるかどうか
         * @param {boolean} [student] 生徒を含めるかどうか
         * @param {string} [patron] 保護者を含めるかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseMembers(courseId: string, teacher?: boolean, student?: boolean, patron?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseMembers(courseId, teacher, student, patron, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーが属している授業の生徒を取得
         * @summary Get Students in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsInMyCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudentUserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsInMyCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分の属している授業の教員を取得
         * @summary Get Teachers in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachersInUsersCourses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeacherUserRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachersInUsersCourses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザのプロフィール情報を取得できるエンドポイント
         * @summary Get a User Detail Data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetail(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetail(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * (private)自分自身の情報を返すエンドポイント
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPrivateData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrivateRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPrivateData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザの更新（パスワード）
         * @summary Update a User
         * @param {string} userId 
         * @param {UserUpdate} [userUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userUpdate?: UserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * (private)自分自身の情報を更新
         * @param {UserPrivateUpdate} [userPrivateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPrivateData(userPrivateUpdate?: UserPrivateUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrivateRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPrivateData(userPrivateUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create a User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreate: UserCreate, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.createUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
         * @summary Create User By Id Token
         * @param {UserCreateByIdToken} userCreateByIdToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByIdToken(userCreateByIdToken: UserCreateByIdToken, options?: any): AxiosPromise<UserAuthRead> {
            return localVarFp.createUserByIdToken(userCreateByIdToken, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのtypeによって取得データが変わる 以下を取得 教師 => 授業の担当教師、生徒、保護者 生徒 => 授業の担当教師、、生徒、自分の保護者 保護者 => 授業の担当教師、自分の生徒、自分の生徒の保護者 クエリストリングで取得したいroleTypeを指定するが、なしなら全て取得
         * @summary Get Course Members
         * @param {string} courseId 
         * @param {boolean} [teacher] 教師を含めるかどうか
         * @param {boolean} [student] 生徒を含めるかどうか
         * @param {string} [patron] 保護者を含めるかどうか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseMembers(courseId: string, teacher?: boolean, student?: boolean, patron?: string, options?: any): AxiosPromise<Array<UserRead>> {
            return localVarFp.getCourseMembers(courseId, teacher, student, patron, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが属している授業の生徒を取得
         * @summary Get Students in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsInMyCourses(options?: any): AxiosPromise<Array<StudentUserRead>> {
            return localVarFp.getStudentsInMyCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * 自分の属している授業の教員を取得
         * @summary Get Teachers in Users Courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersInUsersCourses(options?: any): AxiosPromise<Array<TeacherUserRead>> {
            return localVarFp.getTeachersInUsersCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザのプロフィール情報を取得できるエンドポイント
         * @summary Get a User Detail Data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetail(userId: string, options?: any): AxiosPromise<UserDetailRead> {
            return localVarFp.getUserDetail(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * (private)自分自身の情報を返すエンドポイント
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPrivateData(options?: any): AxiosPromise<UserPrivateRead> {
            return localVarFp.getUserPrivateData(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザの更新（パスワード）
         * @summary Update a User
         * @param {string} userId 
         * @param {UserUpdate} [userUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userUpdate?: UserUpdate, options?: any): AxiosPromise<UserRead> {
            return localVarFp.updateUser(userId, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * (private)自分自身の情報を更新
         * @param {UserPrivateUpdate} [userPrivateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPrivateData(userPrivateUpdate?: UserPrivateUpdate, options?: any): AxiosPromise<UserPrivateRead> {
            return localVarFp.updateUserPrivateData(userPrivateUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * ユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
     * @summary Create a User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(userCreate: UserCreate, options?: any) {
        return UsersApiFp(this.configuration).createUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ソーシャルアカウントのIDトークンを使ったユーザーの登録。join_codeは登録の認可のためだけに使うため、エージェントの作成はここではしない。role _typeの設定は行う
     * @summary Create User By Id Token
     * @param {UserCreateByIdToken} userCreateByIdToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserByIdToken(userCreateByIdToken: UserCreateByIdToken, options?: any) {
        return UsersApiFp(this.configuration).createUserByIdToken(userCreateByIdToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーのtypeによって取得データが変わる 以下を取得 教師 => 授業の担当教師、生徒、保護者 生徒 => 授業の担当教師、、生徒、自分の保護者 保護者 => 授業の担当教師、自分の生徒、自分の生徒の保護者 クエリストリングで取得したいroleTypeを指定するが、なしなら全て取得
     * @summary Get Course Members
     * @param {string} courseId 
     * @param {boolean} [teacher] 教師を含めるかどうか
     * @param {boolean} [student] 生徒を含めるかどうか
     * @param {string} [patron] 保護者を含めるかどうか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCourseMembers(courseId: string, teacher?: boolean, student?: boolean, patron?: string, options?: any) {
        return UsersApiFp(this.configuration).getCourseMembers(courseId, teacher, student, patron, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが属している授業の生徒を取得
     * @summary Get Students in Users Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getStudentsInMyCourses(options?: any) {
        return UsersApiFp(this.configuration).getStudentsInMyCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分の属している授業の教員を取得
     * @summary Get Teachers in Users Courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getTeachersInUsersCourses(options?: any) {
        return UsersApiFp(this.configuration).getTeachersInUsersCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザのプロフィール情報を取得できるエンドポイント
     * @summary Get a User Detail Data
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserDetail(userId: string, options?: any) {
        return UsersApiFp(this.configuration).getUserDetail(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * (private)自分自身の情報を返すエンドポイント
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserPrivateData(options?: any) {
        return UsersApiFp(this.configuration).getUserPrivateData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザの更新（パスワード）
     * @summary Update a User
     * @param {string} userId 
     * @param {UserUpdate} [userUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, userUpdate?: UserUpdate, options?: any) {
        return UsersApiFp(this.configuration).updateUser(userId, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * (private)自分自身の情報を更新
     * @param {UserPrivateUpdate} [userPrivateUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserPrivateData(userPrivateUpdate?: UserPrivateUpdate, options?: any) {
        return UsersApiFp(this.configuration).updateUserPrivateData(userPrivateUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


